import { configureStore } from "@reduxjs/toolkit";
import { PERSIST as persistActionType, persistStore } from "redux-persist";

import authenticateLegacyAxios from "@/src/stores/middlewares/authenticateLegacyAxios";
import notificationMiddleware from "@/src/stores/middlewares/notificationMiddleware";
import userLogoutMiddleware from "@/src/stores/middlewares/userLogoutMiddleware";
import { persistMiddleware } from "@/src/stores/persist/persistMiddleware";
import createReducerManager from "@/src/stores/reducerManager";
import rootReducer from "@/src/stores/rootReducer";

function createStore(preloadedState?: Record<string, unknown>) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware(getDefaultMiddleware) {
      return [
        ...getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [persistActionType],
            ignoredPaths: ["dynamic.activeNodeDetail.nodeId"],
          },
          immutableCheck: {
            ignoredPaths: ["spaceSectionContentCache"],
          },
        }),
        authenticateLegacyAxios,
        userLogoutMiddleware,
        notificationMiddleware,
        persistMiddleware,
      ];
    },
  });

  return store;
}

export const store = createStore();
export const reducerManager = createReducerManager(store.replaceReducer);
export const persistor = persistStore(store);

export default createStore;
