import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/joinSpaceOptions/state/initialState";

export default createSlice({
  name: "joinSpaceOptions",
  initialState,
  reducers: {
    open: state => {
      state.isOpened = true;
    },
    close: state => {
      state.isOpened = false;
    },
  },
});
