import { useCallback, useEffect } from "react";

import type { Method } from "axios";
import axios from "axios";
import { useSelector } from "react-redux";

import { API_ENDPOINT_TRACKING, API_ENDPOINT_TRACKING_NO_AUTH, APP_VERSION } from "@/src/constants/AppConstant";
import TRACKING_EVENT from "@/src/constants/TrackingEvent";
import { generateHeaders, getDeviceContext } from "@/src/hooks/helpers/apiHelpers";
import { selectors as userSelectors } from "@/src/stores/user";
import { getAPIBaseUrl } from "@/src/utils/APIUtil";
import { captureException } from "@/src/utils/logging/SentryLogging";

export { default as TRACKING_EVENT } from "@/src/constants/TrackingEvent";

export function useGenericTracking() {
  const accessToken = useSelector(userSelectors.accessTokenSelector);
  const trackingFetcher = useBaseGenericTracking(accessToken);
  return trackingFetcher;
}

export const createTrackingFetcher = async (options: {
  accessToken?: string;
  eventName: string;
  trackingSpec: Record<string, unknown>;
}) => {
  const { accessToken, eventName, trackingSpec = {} } = options;
  const tzOffset = new Date().getTimezoneOffset();
  const trackingPath = accessToken ? API_ENDPOINT_TRACKING : API_ENDPOINT_TRACKING_NO_AUTH;
  const payload = {
    timestamp: Date.now(),
    tzOffsetMinutes: tzOffset,
    eventName,
    trackingSpec: { ...trackingSpec, urlPath: window.location.pathname },
  };
  // Not using useBaseFetcher because tracking can be called outside redux.
  return axios({
    url: trackingPath,
    method: "POST" as Method,
    headers: generateHeaders(accessToken),
    data: {
      deviceContext: getDeviceContext(),
      frontendVersion: APP_VERSION,
      ...payload,
    },
    baseURL: getAPIBaseUrl(),
  }).catch(e => {
    captureException(e);
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

function useBaseGenericTracking(accessToken: string | undefined) {
  const trackingFetcher = useCallback(
    (eventName: string, trackingSpec = {}) => createTrackingFetcher({ accessToken, eventName, trackingSpec }),
    [accessToken],
  );

  return trackingFetcher;
}

// Only use this on Static next page when no SPAApp rendered. This is only syntatic sugar for static public page.
// useGenericTracking depands on redux state in our case SPAApp
export function useTrackPageVisitNonAuth() {
  const trackingFetcher = useBaseGenericTracking(undefined);
  useEffect(() => {
    trackingFetcher(TRACKING_EVENT.PAGE_VISIT, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useGenericTracking;
