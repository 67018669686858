import Cookies from "js-cookie";
import type { NextApiRequest } from "next";
import UAParser from "ua-parser-js";
import { v4 as uuid } from "uuid";

import {
  HEADER_IDEMPOTENCY_KEY,
  HEADER_X_CLIENT_ORIGIN,
  HEADER_X_CLIENT_VERSION,
  HEADER_X_SESSION_TOKEN,
} from "@/src/constants/APIConstants";
import * as Constant from "@/src/constants/AppConstant";
import { COOKIE_SESSION_ID } from "@/src/constants/CookieConstants";

interface ErrorSpecValidation {
  key?: string;
  message?: string;
  errorSpec?: ErrorSpec;
}

export interface ErrorSpec {
  message?: string;
  validation?: ErrorSpecValidation[];
}

interface ErrorData {
  errorSpec?: ErrorSpec;
  status?: number;
}

export const mapErrorFromData = (data: ErrorData) => {
  if (data) {
    if (data.errorSpec) {
      if (data.errorSpec.validation) {
        return data.errorSpec.validation.map(error => error.key || error.message || error.errorSpec);
      } else {
        return data.errorSpec;
      }
    } else if (data.status) {
      // Return the error code for now
      return data.status;
    }
  }

  return undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(error: any) {
  const err = mapErrorFromData(error?.response?.data) || error;
  return err?.message || err;
}

export const getDeviceContext = () => {
  const uaParser = new UAParser();

  const browser = uaParser.getBrowser();
  const os = uaParser.getOS();

  let screenWidth = 0;
  let screenHeight = 0;

  if (typeof window !== "undefined") {
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;
  }

  let deviceType;
  if (screenWidth > Constant.BREAKPOINT_DESKTOP) deviceType = Constant.DEVICE_TYPE_DESKTOP;
  else if (screenWidth > Constant.BREAKPOINT_SMALL_TABLET) deviceType = Constant.DEVICE_TYPE_TABLET;
  else deviceType = Constant.DEVICE_TYPE_MOBILE;

  return {
    deviceType: deviceType,
    screenResolution: `${screenWidth} x ${screenHeight}`,
    browserType: `${browser.name} ${browser.version}`,
    osType: `${os.name} ${os.version}`,
  };
};

export const getOriginClient = (req?: NextApiRequest) => {
  if (!req && typeof window !== "undefined") {
    // Handle Preview to use dev api origin by default
    if (window.location.host.includes("preview.sembly.com")) {
      return "dev.sembly.com";
    }
    return window.location.host;
  }
  return req?.headers["host"];
};

export const generateHeaders = (
  accessToken?: string,
  enableIdempotency = false,
  additionalHeader: Record<string, string> = {},
) => {
  let headers = {
    [HEADER_X_CLIENT_VERSION]: Constant.APP_VERSION,
  } as Record<string, string>;
  if (accessToken) {
    headers["authorization"] = `Bearer ${accessToken}`;
  }

  const sessionId = Cookies.get(COOKIE_SESSION_ID);
  if (sessionId) {
    headers[HEADER_X_SESSION_TOKEN] = sessionId;
  }

  const clientOrigin = getOriginClient();
  if (clientOrigin) {
    headers[HEADER_X_CLIENT_ORIGIN] = clientOrigin;
  }

  if (enableIdempotency) {
    headers[HEADER_IDEMPOTENCY_KEY] = uuid();
  }

  if (additionalHeader) {
    headers = { ...headers, ...additionalHeader };
  }
  return headers;
};
