import { createSlice } from "@reduxjs/toolkit";

import type { CleanNodeId } from "@/src/domains/content/types/Node";

type SpaceId = number;

interface UnattendedFeedState {
  oldUpdateSequences: CleanNodeId[];
  newUpdateSequences: CleanNodeId[];
  nextPageToken?: string;
  isNoMoreData: boolean;
  nodeChildrenMap: Record<CleanNodeId, CleanNodeId[]>;
}

type ReducerState = Record<SpaceId, UnattendedFeedState>;

export interface SelectorState {
  spaceUnattendedFeeds: ReducerState;
}

const initialState = {} as ReducerState;

const INITIAL_UNATTENDED_FEED: UnattendedFeedState = {
  oldUpdateSequences: [],
  newUpdateSequences: [],
  isNoMoreData: false,
  nodeChildrenMap: {},
};

interface StoreUnattendedFeedsParam extends Omit<UnattendedFeedState, "isNoMoreData" | "nodeChildrenMap"> {
  spaceId: number;
  isFirstPage: boolean;
}

interface ToggleNoMoreDataParams {
  spaceId: number;
  isNoMoreData: boolean;
}

export const spaceUnattendedFeeds = createSlice({
  name: "spaceUnattendedFeeds",
  initialState,
  reducers: {
    storeUnattendedFeeds: (state, { payload }: { payload: StoreUnattendedFeedsParam }) => {
      const { spaceId, newUpdateSequences, oldUpdateSequences, isFirstPage, nextPageToken } = payload;

      const updatedFeedState = { ...(state[spaceId] ?? INITIAL_UNATTENDED_FEED) };

      if (isFirstPage) {
        updatedFeedState.newUpdateSequences = newUpdateSequences;
        updatedFeedState.oldUpdateSequences = oldUpdateSequences;
      } else {
        updatedFeedState.newUpdateSequences = state[spaceId].newUpdateSequences.concat(newUpdateSequences);
        updatedFeedState.oldUpdateSequences = state[spaceId].oldUpdateSequences.concat(oldUpdateSequences);
      }

      updatedFeedState.nextPageToken = nextPageToken;

      const payloadSequences = [...newUpdateSequences, ...oldUpdateSequences];
      const payloadLength = payloadSequences.length;
      updatedFeedState.isNoMoreData = payloadLength < 1 || !nextPageToken;

      state[spaceId] = updatedFeedState;
    },
    toggleNoMoreData: (state, { payload }: { payload: ToggleNoMoreDataParams }) => {
      const { spaceId, isNoMoreData } = payload;

      const updatedFeedState = { ...(state[spaceId] ?? INITIAL_UNATTENDED_FEED) };
      updatedFeedState.isNoMoreData = isNoMoreData;
      state[spaceId] = updatedFeedState;
    },
  },
});
