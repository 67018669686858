import { createSlice } from "@reduxjs/toolkit";

export interface NumConcurrentActionsValue {
  numActiveActions: number;
  timestamp: number;
}

export type NumConcurrentActionsSliceState = {
  numConcurrentActionsData: Record<string, Record<string, NumConcurrentActionsValue>>;
};

const initialState: NumConcurrentActionsSliceState = {
  numConcurrentActionsData: {},
};

// data is only assumed to be valid for up to 5 secs, afterwards, data is considered obsolete
export const EXPIRY_IN_MS = 5 * 1000;

export const numConcurrentActionsReducer = createSlice({
  name: "numConcurrentActionsSlice",
  initialState,
  reducers: {
    registerAction: (state, { payload: { key, spaceUrl, nodeId } }) => {
      const stateKey = `${key}_${spaceUrl}`;
      if (state.numConcurrentActionsData[stateKey] === undefined) {
        state.numConcurrentActionsData[stateKey] = {};
      }
      const storedValue = state.numConcurrentActionsData[stateKey][nodeId];
      const storedTimestamp = storedValue?.timestamp || 0;
      const currentTimestamp = Date.now();
      const updatedValue = { ...storedValue };
      updatedValue.timestamp = currentTimestamp;
      if (currentTimestamp - storedTimestamp > EXPIRY_IN_MS) {
        updatedValue.numActiveActions = 0;
      }
      updatedValue.numActiveActions = updatedValue.numActiveActions + 1;
      state.numConcurrentActionsData[stateKey][nodeId] = updatedValue;
    },
    deregisterAction: (state, { payload: { key, spaceUrl, nodeId } }) => {
      const stateKey = `${key}_${spaceUrl}`;
      if (state.numConcurrentActionsData[stateKey] === undefined) {
        state.numConcurrentActionsData[stateKey] = {};
      }
      const storedValue = state.numConcurrentActionsData[stateKey][nodeId];
      const storedTimestamp = storedValue?.timestamp || 0;
      const currentTimestamp = Date.now();
      const updatedValue = { ...storedValue };
      updatedValue.timestamp = currentTimestamp;
      if (currentTimestamp - storedTimestamp > EXPIRY_IN_MS) {
        updatedValue.numActiveActions = 0;
      } else {
        updatedValue.numActiveActions = updatedValue.numActiveActions - 1;
      }
      state.numConcurrentActionsData[stateKey][nodeId] = updatedValue;
    },
  },
});
