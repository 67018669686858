import * as yup from "yup";

import * as Constant from "@/src/constants/AppConstant";
import type { User } from "@/src/hooks/types";
import { userReputationSchema } from "@/src/models/user/UserReputation";

const userSchema = yup.object().shape({
  id: yup.number().min(0).integer().default(0),
  name: yup.string().default(""),
  fullName: yup.string().default(""),
  displayName: yup.string().default(""),
  avatar: yup.string().default(""),
  description: yup.string().default(""),
  email: yup.string().default(""),
  reputations: userReputationSchema.default([]),
  showHiddenContentLabels: yup.array().of(yup.string()).default([]),
  privilegeType: yup.number().min(0).integer().default(Constant.USER_PRIVILEGE_TYPE_DEFAULT),
  following: yup.array().default([]),
  followers: yup.array().default([]),
});

const fromObject = ({ name, ...props }: User) => {
  if (props) {
    return userSchema.validateSync({ name: name, ...props }, { abortEarly: true, stripUnknown: true });
  } else {
    return userSchema.validateSync({}, { abortEarly: true, stripUnknown: true });
  }
};

const fromJwtToken = ({
  userId,
  username,
  userPrivilegeType,
  fullName,
  displayName,
  ...props
}: {
  userId: number;
  username: string;
  userPrivilegeType: number;
  fullName: string;
  displayName: string;
  description: string;
  email: string;
  avatar: string;
  reputations: { score: number; reputationType: string }[];
  showHiddenContentLabels: string[];
}) => {
  return fromObject({
    id: userId,
    name: username,
    fullName,
    displayName,
    description: props.description,
    email: props.email,
    avatar: props.avatar,
    reputations: props.reputations,
    showHiddenContentLabels: props.showHiddenContentLabels,
    privilegeType: userPrivilegeType,
  });
};

export const userFactory = Object.freeze({
  fromObject,
  fromJwtToken,
});
