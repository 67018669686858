import type { Activity } from "@/src/domains/liquidity/types/Activity";

interface MyActivityState {
  myActivities: Activity[];
  isFetched: boolean;
}

const MyActivityInitialState: MyActivityState = {
  myActivities: [],
  isFetched: false,
};

export default MyActivityInitialState;
