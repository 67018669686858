import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/serviceWorker/initialState";

export default createSlice({
  name: "serviceWorker",
  initialState,
  reducers: {
    update: (state, { payload: instance }) => {
      return { updated: true, instance };
    },
    reset: () => {
      return initialState;
    },
  },
});
