import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/domains/space/states/initialState";

export default createSlice({
  name: "space",
  initialState,
  reducers: {
    setBrowseSpaceQuery(state, { payload: query }) {
      state.spaces.browse.query = query;
    },
    setBrowseSpaceResult(state, { payload: result }) {
      if (state.spaces.browse.shouldFetch) {
        state.spaces.browse.shouldFetch = false;
      }
      state.spaces.browse.result = result?.contentSpace || [];
      state.spaces.browse.proposed = result?.proposedSpace || [];
    },
    clearBrowseSpace(state) {
      state.spaces.browse = initialState.spaces.browse;
    },
    forceFetch: state => {
      state.fetchStatus.shouldFetchSpaces = true;
    },
    setFetchingSpaces(state, { payload: status = true }) {
      state.fetchStatus.isFetchingSpacesList = status;
    },
    setSpaces(state, { payload }) {
      state.spaces.list = payload?.spaces || [];
      state.spaces.pending = payload?.pendingRequests || [];
      state.spaces.invited = payload?.invitedSpaces || [];
      state.spaces.activeSpaceProposals = payload?.activeSpaceProposals || [];
      state.fetchStatus.shouldFetchMySpaces = false;
      state.fetchStatus.isFetchingSpacesList = false;
      state.onboarding.onboardingSpaces =
        payload?.spaces?.filter(space => space.onboardingProgress?.steps?.length > 0) || [];
    },
    /* Space Onboarding */
    setSpacesOnboardingStep: (state, { payload: onboardingSpace }) => {
      const { spaceId, completedSteps } = onboardingSpace;
      state.onboarding.onboardingSpaces = state.onboarding.onboardingSpaces.map(space => {
        const newSpaceData = { ...space };
        if (space.spaceId === spaceId) {
          newSpaceData.onboardingProgress.completedSteps = completedSteps;
        }
        return newSpaceData;
      });
    },
    fetchSpaceOnboarding: state => {
      state.fetchStatus.isFetchOnboardingProgress = true;
    },
    doneFetchSpaceOnboarding: state => {
      state.fetchStatus.isFetchOnboardingProgress = false;
    },
    /**
     * Space Templates
     */
    setSpaceTemplates(state, { payload: templates }) {
      state.templates.private = templates.private;
      state.templates.community = templates.community;
      state.templates.brandAdministered = templates.brandAdministered;
      state.templates.shouldFetchSpaceTemplates = false;
      state.templates.isLoadingSpaceTemplate = false;
    },
    setFetchingSpaceTemplate(state) {
      state.templates.isLoadingSpaceTemplate = true;
    },
    doneFetchSpaceTemplate(state) {
      state.templates.shouldFetchSpaceTemplates = false;
      state.templates.isLoadingSpaceTemplate = false;
    },
    clear: () => initialState,
  },
});
