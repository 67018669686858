import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nodeComponentId: "",
};

const discussionViewHover = createSlice({
  name: "discussionViewHover",
  initialState,
  reducers: {
    setNode: (state, { payload }: { payload: string }) => {
      state.nodeComponentId = payload;
    },
  },
});

export default discussionViewHover;
