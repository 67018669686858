import { createSlice } from "@reduxjs/toolkit";

import type { CleanNodeId, SpaceActiveDraft } from "@/src/domains/content/types/Node";

type SpaceId = number;

interface DraftAndMyPostsState {
  activeDrafts: SpaceActiveDraft[];
  oldUpdateSequences: CleanNodeId[];
  newUpdateSequences: CleanNodeId[];
  nextDraftPageToken?: string;
  nextPublishedPageToken?: string;
  isNoMoreDataDraft: boolean;
  isNoMoreDataPublished: boolean;
}

type ReducerState = Record<SpaceId, DraftAndMyPostsState>;

export interface SelectorState {
  spaceDraftAndMyPosts: ReducerState;
}

const initialState = {} as ReducerState;

const INITAL_DRAFT_AND_MY_POST: DraftAndMyPostsState = {
  oldUpdateSequences: [],
  newUpdateSequences: [],
  isNoMoreDataPublished: false,
  isNoMoreDataDraft: false,
  activeDrafts: [],
};

interface StoreDraftAndMyPostsParam extends Omit<DraftAndMyPostsState, "isNoMoreDataPublished" | "isNoMoreDataDraft"> {
  spaceId: number;
  isFirstPage: boolean;
}

interface ToggleNoMoreDataParams {
  spaceId: number;
  isNoMoreDataDraft?: boolean;
  isNoMoreDataPublished?: boolean;
}
interface DeleteDraftParams {
  spaceId: number;
  draftId: number;
}

export const spaceDraftAndMyPosts = createSlice({
  name: "spaceDraftAndMyPosts",
  initialState,
  reducers: {
    storeDraftAndMyPosts: (state, { payload }: { payload: StoreDraftAndMyPostsParam }) => {
      const {
        spaceId,
        newUpdateSequences,
        oldUpdateSequences,
        isFirstPage,
        nextDraftPageToken,
        nextPublishedPageToken,
        activeDrafts,
      } = payload;

      const updatedDraftAndMyPostsState = { ...(state[spaceId] ?? INITAL_DRAFT_AND_MY_POST) };

      if (isFirstPage) {
        updatedDraftAndMyPostsState.activeDrafts = activeDrafts;
        updatedDraftAndMyPostsState.newUpdateSequences = newUpdateSequences;
        updatedDraftAndMyPostsState.oldUpdateSequences = oldUpdateSequences;
      } else {
        updatedDraftAndMyPostsState.activeDrafts = state[spaceId]?.activeDrafts?.concat(activeDrafts);
        updatedDraftAndMyPostsState.newUpdateSequences = state[spaceId]?.newUpdateSequences?.concat(newUpdateSequences);
        updatedDraftAndMyPostsState.oldUpdateSequences = state[spaceId]?.oldUpdateSequences?.concat(oldUpdateSequences);
      }

      updatedDraftAndMyPostsState.nextDraftPageToken = nextDraftPageToken;
      updatedDraftAndMyPostsState.nextPublishedPageToken = nextPublishedPageToken;

      state[spaceId] = updatedDraftAndMyPostsState;
    },
    toggleNoMoreData: (state, { payload }: { payload: ToggleNoMoreDataParams }) => {
      const { spaceId, isNoMoreDataDraft, isNoMoreDataPublished } = payload;

      const updatedDraftAndMyPostsState = { ...(state[spaceId] ?? INITAL_DRAFT_AND_MY_POST) };
      if (isNoMoreDataDraft) updatedDraftAndMyPostsState.isNoMoreDataDraft = isNoMoreDataDraft;
      if (isNoMoreDataPublished) updatedDraftAndMyPostsState.isNoMoreDataPublished = isNoMoreDataPublished;
      state[spaceId] = updatedDraftAndMyPostsState;
    },
    resetDraftAndMyPosts: (state, { payload }) => {
      const { spaceId } = payload;
      state[spaceId] = INITAL_DRAFT_AND_MY_POST;
    },
    optimisticDeleteDraft: (state, { payload }: { payload: DeleteDraftParams }) => {
      const { spaceId, draftId } = payload;
      const draftAndMyPostsState = { ...(state[spaceId] ?? INITAL_DRAFT_AND_MY_POST) };
      const updatedDraftAndMyPostsState = draftAndMyPostsState?.activeDrafts?.filter(
        draft => draft.draftId !== draftId,
      );
      state[spaceId].activeDrafts = updatedDraftAndMyPostsState;
    },
  },
});
