import { createSlice } from "@reduxjs/toolkit";

import { MAX_REUSABLE_FORMS } from "@/src/domains/dynamic-form/components/DynamicForm/constants";
import initialState from "@/src/domains/dynamic-form/components/DynamicForm/stores/initialState";

export default createSlice({
  name: "dynamicForm",
  initialState,
  reducers: {
    setFormReusableValues: (state, { payload: { formReusableId, values } }) => {
      if (!Object.prototype.hasOwnProperty.call(state.dynamicFormReusableIdToValuesMap, formReusableId)) {
        if (state.dynamicFormReusableIdQueue.length > MAX_REUSABLE_FORMS - 1) {
          const deletedValue = state.dynamicFormReusableIdQueue.shift();
          delete state.dynamicFormReusableIdToValuesMap[deletedValue];
        }
        state.dynamicFormReusableIdQueue.push(formReusableId);
      }
      state.dynamicFormReusableIdToValuesMap[formReusableId] = values;
    },
  },
});
