import { createSlice } from "@reduxjs/toolkit";

interface LocalInProgressActionValue {
  value: boolean;
  timestamp: number;
}

export type LocalInProgressActionSliceState = {
  localInProgressActionData: Record<string, Record<string, LocalInProgressActionValue>>;
};

const initialState: LocalInProgressActionSliceState = {
  localInProgressActionData: {},
};

export const localInProgressActionReducer = createSlice({
  name: "localInProgressActionSlice",
  initialState,
  reducers: {
    setLocalInProgressAction: (state, { payload: { key, spaceUrl, nodeId } }) => {
      const stateKey = `${key}_${spaceUrl}`;
      const timestamp = Date.now();
      if (state.localInProgressActionData[stateKey] === undefined) {
        state.localInProgressActionData[stateKey] = {};
      }
      state.localInProgressActionData[stateKey][nodeId] = {
        value: true,
        timestamp,
      };
    },
    removeLocalInProgressAction: (state, { payload: { key, spaceUrl, nodeId } }) => {
      const stateKey = `${key}_${spaceUrl}`;
      const timestamp = Date.now();
      if (state.localInProgressActionData[stateKey] === undefined) {
        state.localInProgressActionData[stateKey] = {};
      }
      state.localInProgressActionData[stateKey][nodeId] = {
        value: false,
        timestamp,
      };
    },
  },
});
