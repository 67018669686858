import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { sendSignupTrackingEvent } from "@/src/hooks/useSignupTracking";
import type { SignupDialogSource } from "@/src/stores/signupDialog/initialState";
import initialState, { SIGNUP_DIALOG_SOURCE } from "@/src/stores/signupDialog/initialState";

type PayloadObject = {
  isFromJoinAction?: boolean;
  selectedSpaceUrl?: string;
  source?: SignupDialogSource;
  spaceId?: number;
};

export default createSlice({
  name: "signupDialog",
  initialState,
  reducers: {
    openSignupDialog: (state, action?: PayloadAction<PayloadObject | undefined>) => {
      state.open = true;
      const payload = action?.payload;
      state.isFromJoinAction = Boolean(payload?.isFromJoinAction);
      state.source = payload?.source || SIGNUP_DIALOG_SOURCE.SIGNUP_CTA;

      if (state.source === SIGNUP_DIALOG_SOURCE.SIGNUP_CTA) {
        sendSignupTrackingEvent("OPEN_SIGNUP_FORM");
      } else if (state.source === SIGNUP_DIALOG_SOURCE.JOIN_SPACE && typeof payload?.spaceId === "number") {
        state.spaceId = payload.spaceId;
        sendSignupTrackingEvent("OPEN_SIGNUP_TO_JOIN_SPACE_MODAL", {
          spaceId: payload.spaceId,
        });
      }

      if (payload?.selectedSpaceUrl) {
        state.selectedSpaceUrl = payload.selectedSpaceUrl;
      }
      return state;
    },
    closeSignupDialog: state => {
      if (state.source === "SIGNUP_CTA") {
        sendSignupTrackingEvent("CLOSE_SIGNUP_FORM");
      } else if (state.source === "JOIN_SPACE" && typeof state.spaceId === "number") {
        sendSignupTrackingEvent("CLOSE_SIGNUP_TO_JOIN_SPACE_MODAL", {
          spaceId: state.spaceId,
        });
      }
      state.open = false;
      state.selectedSpaceUrl = undefined;
      state.source = undefined;
      state.spaceId = undefined;
      return state;
    },
  },
});
