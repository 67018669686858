import type { ActionType } from "@/src/domains/types/NodeActionType";

export const ADMIN_TASKS = new Set<ActionType>([
  "DELETE",
  "DELETE_ALL_SAMPLE_POSTS",
  "DELETE_SECTION_GROUP",
  "DELETE_SECTION",
  "EDIT_POST_TYPE",
  "UPDATE_SECTION_TYPE",
  "EDIT_POST_TYPE_CLAIM",
  "EDIT_POST_TYPE_QUESTION",
  "MARK_OBSOLETE",
  "MARK_NOT_OBSOLETE",
  "MARK_DUPLICATE",
  "MARK_NOT_DUPLICATE",
  "ARCHIVE_SECTION_GROUP",
  "UNARCHIVE_SECTION_GROUP",
  "ARCHIVE_SECTION",
  "UNARCHIVE_SECTION",
  "PIN_NODE",
  "UNPIN_NODE",
  "MOVE_FIXED_ORDER_NODE",
  "MOVE_CHILD_NODE",
  "MOVE_PARENT",
  "MOVE_REPLY",
  "DELETE_SECTION_GROUP",
  "DELETE_SECTION",
  "ADD_PARTITION",
  "ADD_PARTITION_ABOVE",
  "ADD_PARTITION_BELOW",
  "ADD_SECTION",
  "ADD_SECTION_ABOVE",
  "ADD_SECTION_BELOW",
  "UPDATE_SECTION",
  "UPDATE_SECTION_TYPE",
  "RENAME_SECTION_GROUP",
  "RENAME_SECTION",
  "ADD_SECTION_DESCRIPTION",
  "EDIT_SECTION_DESCRIPTION",
  "REORDER_SECTION",
  "ARCHIVE_SECTION_GROUP",
  "UNARCHIVE_SECTION_GROUP",
  "ARCHIVE_SECTION",
  "UNARCHIVE_SECTION",
  "CLONE_SECTION",
  "CREATE_BADGE",
  "DELETE_BADGE",
  "EDIT_BADGE",
  "ASSIGN_BADGE",
  "REVOKE_BADGE",
  "EDIT_SPACE",
  "CONVERT_REC_TO_PILOT_SPACE",
  "PROCESS_SPACE_ACT_REQ",
  "PROCESS_PERM_SPACE_ACT_PRPSL",
  "PROCESS_LIM_SPACE_ACT_PRPSL",
  "PROCESS_CHG_SPACE_BRD_ADM",
  "PROCESS_CHG_SPACE_PUBLIC",
  "PROCESS_PUBLISH_OPEN",
  "SUBMIT_PERM_SPACE_ACT_PRPSL",
  "SUBMIT_LIM_SPACE_ACT_PRPSL",
  "SUBMIT_CHG_SPACE_TYPE_PRPSL",
  "SUBMIT_CHG_SPACE_BRD_ADM",
  "SUBMIT_CHG_SPACE_PUBLIC",
  "SUBMIT_PUBLISH_OPEN",
  "PUBLISH_SPACE_TEMPLATE",
  "UNPUBLISH_SPACE_TEMPLATE",
  "UPDATE_SPACE_USER_PRIVILEGE",
  "UPDATE_SPACE_PROGRESS",
  "PUBLISH_BRAND_ADM_SPACE",
  "UNPUBLISH_BRAND_ADM_SPACE",
]);
