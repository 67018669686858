const DEFAULT_PAGE_SIZE = 10;

export default {
  draftId: 0, // @NOTE: 0 = new draft
  draftActionId: null,
  nodeId: null,
  draftsList: {
    drafts: [],
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  },
};
