import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "@/src/domains/space/states/SpaceNavigation/initialState";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    updateLastVisitedSpace(state, { payload: spaceId }) {
      state.lastVisitedSpaceId = spaceId;
    },
    clearLastVisitedSpace(state) {
      state.lastVisitedSpaceId = null;
    },
  },
});
