import { createSlice } from "@reduxjs/toolkit";

import type { CleanNodeId } from "@/src/domains/content/types/Node";
import { merge } from "@/src/utils/general/ArrayUtil";

export interface SpaceSavedContentsMutationData {
  nodeIds?: CleanNodeId[];
  deletedNodeIds?: CleanNodeId[];
}

type SpaceId = number;

interface SpaceSavedContentsState {
  sequences: CleanNodeId[];
  nextPageToken?: string;
  isNoMoreData: boolean;
}

type ReducerState = Record<SpaceId, SpaceSavedContentsState>;

export interface SelectorState {
  spaceSavedContents: ReducerState;
}

const initialState = {} as ReducerState;

const INITIAL_SPACE_SAVED_CONTENTS: SpaceSavedContentsState = {
  sequences: [],
  isNoMoreData: false,
};

interface StoreSpaceSavedContentsParams extends Omit<SpaceSavedContentsState, "isNoMoreData"> {
  spaceId: number;
  isFirstPage: boolean;
}

interface ApplyMutationParams {
  spaceId: number;
  data: SpaceSavedContentsMutationData;
}

interface ToggleNoMoreDataParams {
  spaceId: number;
  isNoMoreData: boolean;
}

export const spaceSavedContents = createSlice({
  name: "spaceSavedContents",
  initialState,
  reducers: {
    storeSpaceSavedContents: (state, { payload }: { payload: StoreSpaceSavedContentsParams }) => {
      const { spaceId, sequences, isFirstPage, nextPageToken } = payload;

      const updatedState = { ...(state[spaceId] ?? INITIAL_SPACE_SAVED_CONTENTS) };

      if (isFirstPage) {
        updatedState.sequences = sequences;
      } else {
        updatedState.sequences = merge(state[spaceId].sequences, sequences);
      }

      updatedState.nextPageToken = nextPageToken;
      updatedState.isNoMoreData = sequences.length < 1 || !nextPageToken;

      state[spaceId] = updatedState;
    },
    applyMutation: (state, { payload }: { payload: ApplyMutationParams }) => {
      const { spaceId, data } = payload;

      const updatedState = state[spaceId];
      if (updatedState) {
        const { nodeIds = [], deletedNodeIds = [] } = data;

        let newSequences = updatedState.sequences;

        if (nodeIds.length > 0) {
          const filteredNodeIds = nodeIds?.filter(p => !updatedState.sequences.includes(p)) || [];
          if (filteredNodeIds.length > 0) {
            newSequences = merge(filteredNodeIds, newSequences);
          }
        }

        if (deletedNodeIds.length > 0) {
          newSequences = newSequences.filter(p => !deletedNodeIds.includes(p));
        }

        updatedState.sequences = newSequences;

        state[spaceId] = updatedState;
      }
    },
    toggleNoMoreData: (state, { payload }: { payload: ToggleNoMoreDataParams }) => {
      const { spaceId, isNoMoreData } = payload;

      const updatedState = { ...(state[spaceId] ?? INITIAL_SPACE_SAVED_CONTENTS) };
      updatedState.isNoMoreData = isNoMoreData;
      state[spaceId] = updatedState;
    },
    resetSpaceSavedContents: (state, { payload }) => {
      const { spaceId } = payload;
      state[spaceId] = INITIAL_SPACE_SAVED_CONTENTS;
    },
  },
});
