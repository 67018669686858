import { createSlice } from "@reduxjs/toolkit";

import type { TemporaryDataType } from "@/src/domains/content/states/Toc/TocInitialState";
import type { TocTreeState } from "@/src/domains/content/states/TocExpandCollapse/TocExpandCollapseInitialState";
import TocExpandCollapseInitialState from "@/src/domains/content/states/TocExpandCollapse/TocExpandCollapseInitialState";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface InitParams {
  spaceId: number;
  data: TocTreeState;
  preserveExisting?: boolean;
}

interface UpdateParams {
  spaceId: number;
  data: TocTreeState;
  preserveExisting?: boolean;
}

interface AddTemporaryDataParams {
  spaceId: number;
  type: TemporaryDataType;
  data: TocTreeState;
}

interface MergeTemporaryDataParams {
  spaceId: number;
}

interface ClearTemporaryDataParams {
  spaceId: number;
}

export const tocExpandCollapseSlice = createSlice({
  name: "tocExpandCollapse",
  initialState: TocExpandCollapseInitialState,
  reducers: {
    init: (state, { payload }: ReducerPayload<InitParams>) => {
      const { spaceId, data, preserveExisting } = payload;

      const spaceState = state[spaceId];
      if (spaceState) {
        const newData: TocTreeState = {};

        for (const key of Object.keys(data)) {
          const sectionId = Number(key);

          if (Object.prototype.hasOwnProperty.call(spaceState.data, sectionId) && preserveExisting) {
            newData[sectionId] = spaceState.data[sectionId];
          } else {
            newData[sectionId] = data[sectionId];
          }
        }

        spaceState.data = newData;
      } else {
        state[spaceId] = { data };
      }
    },
    update: (state, { payload }: ReducerPayload<UpdateParams>) => {
      const { spaceId, data, preserveExisting } = payload;

      const spaceState = state[spaceId];
      if (spaceState) {
        for (const key of Object.keys(data)) {
          const sectionId = Number(key);

          if (!preserveExisting || !Object.prototype.hasOwnProperty.call(spaceState.data, sectionId)) {
            spaceState.data[sectionId] = data[sectionId];
          }
        }
      }
    },
    addTemporaryData: (state, { payload }: ReducerPayload<AddTemporaryDataParams>) => {
      const { spaceId, type, data } = payload;

      const spaceState = state[spaceId];
      if (spaceState) {
        if (spaceState.temporaryData && spaceState.temporaryData.type === type) {
          spaceState.temporaryData.data = { ...spaceState.temporaryData.data, ...data };
        } else {
          spaceState.temporaryData = { type, data };
        }
      }
    },
    mergeTemporaryData: (state, { payload }: ReducerPayload<MergeTemporaryDataParams>) => {
      const { spaceId } = payload;

      const spaceState = state[spaceId];
      if (spaceState && spaceState.temporaryData) {
        spaceState.data = { ...spaceState.data, ...spaceState.temporaryData.data };
      }
    },
    clearTemporaryData: (state, { payload }: ReducerPayload<ClearTemporaryDataParams>) => {
      const { spaceId } = payload;

      const spaceState = state[spaceId];
      if (spaceState) {
        delete spaceState.temporaryData;
      }
    },
    reset: () => {
      return TocExpandCollapseInitialState;
    },
  },
});
