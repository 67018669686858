import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "@/src/stores/rootReducer";

const initialState = {
  hideUnwatchPrompt: false,
};

export const hideUnwatchPromptSelector = ({ unwatchPrompt }: RootState) => unwatchPrompt.hideUnwatchPrompt;

export const unwatchPromptSlice = createSlice({
  name: "unwatchPrompt",
  initialState,
  reducers: {
    disableUnwatchPrompt: state => {
      state.hideUnwatchPrompt = true;
    },
  },
});
