import { createSlice } from "@reduxjs/toolkit";
import get from "lodash.get";
import mergeWith from "lodash.mergewith";

import { MAIN_TEXT_AREA } from "@/src/constants/AppConstant";
import type {
  ActiveDraft,
  DiscussionMapNode,
  NodeObject,
  TextAreaActionDefaultValue,
} from "@/src/domains/content/types/Node";
import {
  generateDraftDataFromDynamicForm,
  getMainContentFromFormValues,
} from "@/src/domains/content/utils/DraftHelpers";
import { generateEndorseMetadata, generateReactionObject } from "@/src/domains/content/utils/TreeHelper";
import type { DynamicFormValues } from "@/src/domains/dynamic-form/types/DynamicForm";
import type { ActionGroupId, ActionType } from "@/src/domains/types/NodeActionType";
import type { UpdateMetadata } from "@/src/domains/update-indicator/types/UpdateIndicatorResponse";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";
import { htmlStripper } from "@/src/utils/general/StringUtil";

const DRAFT_ID_PLACEHOLDER = -999;

type SpaceId = number;
export type NodesInSpace = Record<string, NodeObject>;
export type State = Record<SpaceId, NodesInSpace>;

export interface SelectorState {
  spaceNodes: State;
}

const initialState = {} as State;

interface StoreNodeParams {
  spaceId: number;
  nodes: NodesInSpace | Record<string, DiscussionMapNode>;
  isDiscussionMapNodes?: boolean;
  preserveSomeExistingFields?: boolean;
  overwrite?: boolean;
}

interface DeleteNodeParams {
  spaceId: number;
  nodeIds: string[];
}

interface DeleteNodesInSpaceParams {
  spaceId: number;
}

interface SetUpdateMetadataParams {
  spaceId: number;
  nodeId: string;
  updateMetadata: UpdateMetadata;
}

interface RemoveUpdateMetadataParams {
  spaceId: number;
  nodeId: string;
}

interface BoostNodeRankingParams {
  spaceId: number;
  nodeId: string;
}

interface UpdateNodeDraftData {
  spaceId: number;
  nodeId: string;
  draftId?: number;
  dynamicFormValues: DynamicFormValues["values"];
  actionGroupId: ActionGroupId;
  actionType: ActionType;
  draftListTitle?: string;
}

interface RemoveDraftEntries {
  spaceId: number;
  nodeId: string;
  draftIds: number[];
}

// If the node is "520760.CLOSE" it marked as legacy node, it will not stored to redux because the data is available on the "childeInfo"
function isLegacyNode(inputStr: string) {
  const regex = /\.[a-zA-Z]+/;
  return regex.test(inputStr);
}
export const spaceNodes = createSlice({
  name: "spaceNodes",
  initialState,
  reducers: {
    storeNodes: (
      state,
      {
        payload: { spaceId, nodes, isDiscussionMapNodes = false, preserveSomeExistingFields, overwrite },
      }: { payload: StoreNodeParams },
    ) => {
      if (!state[spaceId]) {
        state[spaceId] = {};
      }

      const nodesArray = Object.values(nodes);
      for (let i = 0; i < nodesArray.length; i++) {
        const node = nodesArray[i];

        const { nodeComponentId } = node;
        const isLegacyChildNode = isLegacyNode(nodeComponentId);

        if (isLegacyChildNode) {
          continue;
        }
        if (node.isDeleted) {
          delete state[spaceId][nodeComponentId];
          continue;
        }

        const existingNode = state[spaceId][nodeComponentId];
        let updatedNode = { ...node };

        if (!isDiscussionMapNodes) {
          // compare lastMutationVersion and lastUpdatedTime
          const existingLastUpdatedTimestamp = existingNode?.lastUpdatedTimestamp || 0;
          const addedNodeLastUpdatedTimestamp = node.lastUpdatedTimestamp || 0;
          const existingLastMutationVersion = existingNode?.lastMutationVersion || 0;
          const addedNodeLastMutationVersion = node.lastMutationVersion || 0;
          if (
            existingLastMutationVersion >= addedNodeLastMutationVersion &&
            existingLastUpdatedTimestamp >= addedNodeLastUpdatedTimestamp
          ) {
            continue;
          }
        } else {
          // Discussion Map API possibly return deeply nested nodes
          // We need to store those, but only store properties that are related to feed
          //do not store properties on discussion map node that does not overlap with space node (text, nodeId, nodeComponentId, poD, poDR)
          const sampleDiscussionMapNodeObject: Partial<DiscussionMapNode> = {
            discussionMapParentId: "",
            discussionMapStructureType: "",
            discussionMapRankingScore: 0,
            childIdsByStructureType: {},
          };
          const discussionMapKeysArray = Object.keys(sampleDiscussionMapNodeObject);
          const discussionMapNodeObjectFieldsToRemove: Partial<DiscussionMapNode> = {};
          for (let i = 0; i < discussionMapKeysArray.length; i++) {
            const key = discussionMapKeysArray[i];
            discussionMapNodeObjectFieldsToRemove[key as keyof DiscussionMapNode] = undefined;
          }

          const appliedIsInitiallyExpandedState = existingNode
            ? get(existingNode, "contentDisplay.isInitiallyExpanded")
            : false;
          const initialExpandObjectTree = { contentDisplay: { isInitiallyExpanded: appliedIsInitiallyExpandedState } };

          // prevent cached existing node to be overwritten with incomplete discussion map node
          if (!overwrite) {
            updatedNode = mergeWith(
              existingNode,
              node,
              discussionMapNodeObjectFieldsToRemove,
              initialExpandObjectTree,
              (objValue: unknown, srcValue: unknown) => {
                if (Array.isArray(objValue)) {
                  return srcValue;
                }
              },
            );
          }
        }

        // preventing parent ids to be overwritten to "0" by getContent
        if (node.refParentId === "0" && Boolean(existingNode?.refParentId)) {
          updatedNode.refParentId = existingNode.refParentId;
        }
        if (node.parentId === "0" && Boolean(existingNode?.parentId)) {
          updatedNode.parentId = existingNode.parentId;
        }

        // still need to preserve isInitiallyExpand because node object from form response didn't have this field
        const isInitiallyExpanded = existingNode ? get(existingNode, "contentDisplay.isInitiallyExpanded") : undefined;
        const initialExpandObjectTree = { contentDisplay: { isInitiallyExpanded } };

        // there are cases when nodes are obtained from BE with `excludeNonEmbeddedChildren` flag (e.g. Discussion View pubsub)
        // these nodes doesn't have neccessary fields by design, but still needed
        // if there are any other special fields, please add it here
        let preservedFields = {};
        if (preserveSomeExistingFields && existingNode) {
          preservedFields = {
            contentMetadata: existingNode.contentMetadata,
            childrenInfo: existingNode.childrenInfo,
            contentDisplay: { parentInfo: existingNode.contentDisplay?.parentInfo },
          };
        }

        const parentInfoData = {
          contentDisplay: {
            parentInfo: updatedNode?.contentDisplay?.parentInfo || existingNode?.contentDisplay?.parentInfo,
          },
        };

        if (!overwrite) {
          updatedNode = mergeWith(
            updatedNode,
            initialExpandObjectTree,
            preservedFields,
            parentInfoData,
            (objValue: unknown, srcValue: unknown) => {
              if (Array.isArray(objValue)) {
                return srcValue;
              }
            },
          );
        }
        state[spaceId][nodeComponentId] = updatedNode;
      }
    },
    deleteNodes: (state, { payload: { spaceId, nodeIds } }: { payload: DeleteNodeParams }) => {
      for (let i = 0; i < nodeIds.length; i++) {
        const deletedNodeId = nodeIds[i];
        delete state[spaceId][deletedNodeId];
      }
    },
    deleteNodesInSpace: (state, { payload }: { payload: DeleteNodesInSpaceParams }) => {
      const { spaceId } = payload;
      delete state[spaceId];
    },
    updateReactionMetadata: (state, { payload: { spaceId, nodeId, emoji, toAdd, userId, username } }) => {
      let updatedNode = state[spaceId][nodeId];
      if (!updatedNode) return;
      const updatedReactionMetadata = generateReactionObject({
        reactionMetadata: updatedNode.reactionMetadata,
        emoji,
        toAdd,
        userId,
        username,
      });
      const updatedClientTimestamp = new Date().getTime() * 1000000;
      updatedNode = {
        ...updatedNode,
        lastUpdatedTimestamp: updatedClientTimestamp,
        reactionMetadata: { ...updatedReactionMetadata },
      };
      state[spaceId] = {
        ...state[spaceId],
        [nodeId]: updatedNode,
      };
    },
    setReactionMetadata: (
      state,
      { payload: { spaceId, nodeId, reactionMetadata, lastMutationVersion, lastUpdatedTimestamp } },
    ) => {
      let updatedNode = state[spaceId][nodeId];
      if (!updatedNode) return;
      const existingMutationVersion = updatedNode?.lastMutationVersion || 0;
      const existingUpdateTimestamp = updatedNode?.lastUpdatedTimestamp || 0;
      const isUpdateNewer =
        lastMutationVersion > existingMutationVersion && lastUpdatedTimestamp > existingUpdateTimestamp;

      if (isUpdateNewer) {
        updatedNode = {
          ...updatedNode,
          lastUpdatedTimestamp,
          lastMutationVersion,
          reactionMetadata: { ...reactionMetadata },
        };
        state[spaceId] = {
          ...state[spaceId],
          [nodeId]: updatedNode,
        };
      }
    },
    updateEndorseMetadata: (state, { payload }) => {
      const {
        spaceId,
        nodeId,
        addEndorse,
        userId,
        username,
        userPhoto,
        itsOptimistic,
        endorsementMetadata,
        isAnonymous,
      } = payload;
      let updatedNode = state[spaceId][nodeId];
      let endorsementMetadataUpdate;
      if (itsOptimistic) {
        // Generate endorse metadata if it is an optimistic update
        endorsementMetadataUpdate = generateEndorseMetadata({
          endorserMetadata: updatedNode.contentPost?.endorsementMetadata,
          addEndorse,
          userId,
          username,
          userPhoto,
          isAnonymous,
        });
      } else {
        endorsementMetadataUpdate = endorsementMetadata;
      }

      const updatedContentPost = {
        ...updatedNode.contentPost,
        endorsementMetadata: { ...endorsementMetadataUpdate },
      } as NodeObject["contentPost"];

      updatedNode = {
        ...updatedNode,
        contentPost: updatedContentPost,
      };
      state[spaceId] = {
        ...state[spaceId],
        [nodeId]: updatedNode,
      };
    },
    setUpdateMetadata: (state, { payload }: ReducerPayload<SetUpdateMetadataParams>) => {
      const { spaceId, nodeId, updateMetadata } = payload;

      const nodes = state[spaceId];
      if (nodes) {
        const node = nodes[nodeId];
        if (node) {
          const updatedContentPost = {
            ...node.contentPost,
            updateMetadataTextComponent: updateMetadata,
          } as NodeObject["contentPost"];

          const updatedNode = {
            ...node,
            contentPost: updatedContentPost,
          };

          state[spaceId] = {
            ...state[spaceId],
            [nodeId]: updatedNode,
          };
        }
      }
    },
    removeUpdateMetadata: (state, { payload }: ReducerPayload<RemoveUpdateMetadataParams>) => {
      const { spaceId, nodeId } = payload;

      const nodes = state[spaceId];
      if (nodes) {
        const node = nodes[nodeId];
        if (node) {
          const updatedContentPost = {
            ...node.contentPost,
            updateMetadataTextComponent: undefined,
          } as NodeObject["contentPost"];

          const updatedNode = {
            ...node,
            contentPost: updatedContentPost,
          };

          state[spaceId] = {
            ...state[spaceId],
            [nodeId]: updatedNode,
          };
        }
      }
    },
    removeDraftEntries: (state, { payload }: ReducerPayload<RemoveDraftEntries>) => {
      const { spaceId, nodeId, draftIds: removedDraftIds } = payload;
      const node = state[spaceId]?.[nodeId];
      const activeDrafts = node?.draftChildren?.activeDrafts || [];
      if (node?.draftChildren && activeDrafts) {
        const updatedActiveDraft: ActiveDraft[] = [];
        const removedActiveDraft: ActiveDraft[] = [];
        activeDrafts.forEach(draft => {
          if (removedDraftIds.includes(draft.draftId)) {
            removedActiveDraft.push(draft);
          } else {
            updatedActiveDraft.push(draft);
          }
        });

        // Emptying default value related to the removed draft
        if (node.actionGroups) {
          const removedActionTypes = removedActiveDraft.map(draft => draft.responseId);
          node.actionGroups.forEach(actionGroup => {
            actionGroup.actions.forEach(action => {
              const isActionTypeMatch = removedActionTypes.includes(action.actionType);
              if (isActionTypeMatch) {
                const mainTextAreaField = action.defaultValues?.[MAIN_TEXT_AREA];
                // There are 2 kinds of object structure related to main text area. We handle both.
                if (mainTextAreaField && Object.prototype.hasOwnProperty.call(mainTextAreaField, "textAreaValue")) {
                  (action.defaultValues![MAIN_TEXT_AREA] as TextAreaActionDefaultValue).textAreaValue = {};
                } else {
                  action.defaultValues![MAIN_TEXT_AREA] = {};
                }
              }
            });
          });
        }

        // Remove active draft entry from client node object
        if (updatedActiveDraft.length > 0) {
          node.draftChildren.activeDrafts = updatedActiveDraft;
        } else {
          node.draftChildren = undefined;
        }
        state[spaceId][nodeId] = node;
      }
    },
    /** Used when opening discussion view with highlight to prevent highlighted node to be hidden */
    boostNodeRanking: (state, { payload }: ReducerPayload<BoostNodeRankingParams>) => {
      const { spaceId, nodeId } = payload;
      const node = state[spaceId]?.[nodeId];
      if (node) {
        state[spaceId][nodeId].rankingScore = 0;
      }
    },
    updateNodeDraftData: (state, { payload }: ReducerPayload<UpdateNodeDraftData>) => {
      const { spaceId, nodeId, dynamicFormValues, actionGroupId, actionType, draftId, draftListTitle } = payload;
      // updating action groups default value so that whenever the form is opened
      // either from original form or floating draft, it will have consistent value
      if (state[spaceId][nodeId]?.actionGroups) {
        const updatedNode = state[spaceId][nodeId];
        const updatedActionGroups = updatedNode.actionGroups ? [...updatedNode.actionGroups] : [];
        for (let i = 0; i < updatedActionGroups.length; i++) {
          const actionGroup = updatedActionGroups[i];
          if (actionGroupId !== actionGroup.groupId) continue;
          for (let x = 0; x < actionGroup.actions.length; x++) {
            const action = actionGroup.actions[x];
            if (action.actionType !== actionType) continue;
            const defaultValueObject = generateDraftDataFromDynamicForm(dynamicFormValues);
            updatedActionGroups[i].actions[x].defaultValues = defaultValueObject;
            // update node object default value data
            state[spaceId][nodeId].actionGroups = updatedActionGroups;
            break;
          }
        }
      }

      // add placeholder active draft children before receiving real data from BE
      const mainTextContent = getMainContentFromFormValues(dynamicFormValues);
      if (mainTextContent && state[spaceId][nodeId] && !state[spaceId][nodeId]?.draftChildren?.activeDrafts) {
        if (!state[spaceId][nodeId].draftChildren) {
          state[spaceId][nodeId].draftChildren = {
            title: draftListTitle || "",
            activeDrafts: [],
          };
        }

        let updatedActiveDrafts = [...(state[spaceId][nodeId].draftChildren?.activeDrafts || [])];
        if (draftId) {
          const draftIndex = updatedActiveDrafts.findIndex(draft => draft.draftId === draftId);
          updatedActiveDrafts[draftIndex] = {
            text: htmlStripper(mainTextContent),
            customForm: JSON.stringify({ response: dynamicFormValues }),
            draftId,
            nodeId,
            responseId: actionType,
            lastUpdatedAt: new Date().getTime(),
          };
        } else {
          updatedActiveDrafts = [
            ...(state[spaceId][nodeId].draftChildren?.activeDrafts || []),
            {
              text: htmlStripper(mainTextContent),
              customForm: JSON.stringify({ response: dynamicFormValues }),
              draftId: DRAFT_ID_PLACEHOLDER,
              nodeId: nodeId,
              responseId: actionType,
              lastUpdatedAt: new Date().getTime(),
            },
          ];
        }

        state[spaceId][nodeId].draftChildren!.activeDrafts = updatedActiveDrafts;
      }
    },
  },
});
