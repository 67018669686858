import { createSlice } from "@reduxjs/toolkit";

import type { CleanNodeId } from "@/src/domains/content/types/Node";

interface MyPostsState {
  postsOrder: { id: CleanNodeId; spaceUrl: string }[];
  isNoMoreData: boolean;
  nextPageToken?: string;
}

type ReducerState = MyPostsState;

export interface SelectorState {
  myPosts: ReducerState;
}

const initialState = {
  postsOrder: [],
  isNoMoreData: false,
} as ReducerState;

interface StoreMyPostsParam extends Omit<MyPostsState, "isNoMoreData"> {
  isFirstPage: boolean;
  isNoMoreData?: boolean;
}

interface ToggleNoMoreDataParams {
  isNoMoreData?: boolean;
}

export const myPosts = createSlice({
  name: "myPosts",
  initialState,
  reducers: {
    storePosts: (state, { payload }: { payload: StoreMyPostsParam }) => {
      const { isFirstPage, nextPageToken, postsOrder, isNoMoreData } = payload;

      const updatedMyPostsState = { ...(state ?? initialState) };

      if (isFirstPage) {
        updatedMyPostsState.postsOrder = postsOrder;
      } else {
        updatedMyPostsState.postsOrder = state?.postsOrder?.concat(postsOrder);
      }
      if (isNoMoreData) {
        updatedMyPostsState.isNoMoreData = isNoMoreData;
      }
      updatedMyPostsState.nextPageToken = nextPageToken;
      return updatedMyPostsState;
    },
    toggleNoMoreData: (state, { payload }: { payload: ToggleNoMoreDataParams }) => {
      const { isNoMoreData } = payload;

      const updatedMyPostsState = { ...(state ?? initialState) };

      if (isNoMoreData) {
        updatedMyPostsState.isNoMoreData = isNoMoreData;
      }

      return updatedMyPostsState;
    },
    resetMyPosts: state => {
      state = initialState;
      return state;
    },
  },
});
