import PropTypes from "prop-types";
import * as yup from "yup";

export const userReputationSchema = yup.array().of(
  yup.object().shape({
    score: yup.number().default(0),
    reputationType: yup.string().required(),
  }),
);

export const UserReputationPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    score: PropTypes.number.isRequired,
    reputationType: PropTypes.string.isRequired,
  }),
);
