import { createSlice } from "@reduxjs/toolkit";

import type { VirtualizedActivity } from "@/src/domains/liquidity/components/MyActivityList/states/MyActivitiesInitialState";
import MyActivitiesInitialState from "@/src/domains/liquidity/components/MyActivityList/states/MyActivitiesInitialState";
import type { Activity } from "@/src/domains/liquidity/types/Activity";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface MyActivitiesResult {
  sequences: VirtualizedActivity[];
  activities: Record<number, Activity>;
}

function parseMyActivities(data: Activity[]): MyActivitiesResult {
  const sequences: VirtualizedActivity[] = [];
  const activities: Record<number, Activity> = {};

  for (const activity of data) {
    const { actionId, createdAt, state } = activity;
    sequences.push({ actionId, createdAt, state });
    activities[actionId] = activity;
  }

  return {
    sequences: sequences,
    activities: activities,
  };
}

interface StoreParams {
  data: Activity[];
  prevPageToken?: string;
  nextPageToken?: string;
}

interface PrependParams {
  data: Activity[];
  prevPageToken?: string;
}

interface AppendParams {
  data: Activity[];
  nextPageToken?: string;
}

export const myActivitiesSlice = createSlice({
  name: "myActivities",
  initialState: MyActivitiesInitialState,
  reducers: {
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { data, prevPageToken, nextPageToken } = payload;

      const { sequences, activities } = parseMyActivities(data);

      state.sequences = sequences;
      state.activities = activities;
      state.prevPageToken = prevPageToken;
      state.nextPageToken = nextPageToken;
      state.startReached = !prevPageToken;
      state.endReached = !nextPageToken;
    },
    prepend: (state, { payload }: ReducerPayload<PrependParams>) => {
      const { data, prevPageToken } = payload;

      const { sequences, activities } = parseMyActivities(data);

      state.sequences = [...sequences, ...state.sequences];
      state.activities = { ...state.activities, ...activities };
      state.prevPageToken = prevPageToken;
      state.startReached = !prevPageToken;
    },
    append: (state, { payload }: ReducerPayload<AppendParams>) => {
      const { data, nextPageToken } = payload;

      const { sequences, activities } = parseMyActivities(data);

      state.sequences = [...state.sequences, ...sequences];
      state.activities = { ...state.activities, ...activities };
      state.nextPageToken = nextPageToken;
      state.endReached = !nextPageToken;
    },
    reset: () => {
      return MyActivitiesInitialState;
    },
  },
});
