import type { NodeChildrenInfoGroup, StanceKey } from "@/src/domains/content/types/Node";
import type { SPACE_ACCESS_TYPE } from "@/src/domains/space/components/SpaceListSidebar/types/SpaceSetting";
import type { ValueOf } from "@/src/utils/helpers/TypeUtil";

export const USER_NOTIFICATION_STATUS = {
  NEW: "NEW",
  UNREAD: "UNREAD",
  READ: "READ",
  HIDE: "HIDE",
} as const;

export type UserNotificationStatus = ValueOf<typeof USER_NOTIFICATION_STATUS>;

export const USER_NOTIFICATION_ICON_TYPE = {
  USER: "USER",
  SPACE: "SPACE",
  BADGE: "BADGE",
  ANONYMOUS_USER: "ANONYMOUS_USER",
  UNKNOWN_USER: "UNKNOWN_USER",
  SENSITIVE: "SENSITIVE",
  UNKNOWN: "UNKNOWN",
} as const;

export type UserNotificationIconType = ValueOf<typeof USER_NOTIFICATION_ICON_TYPE>;

export const ACTION_TYPE = {
  NEW_CONTENT: "NEW CONTENT",
  NEW_COMMENT: "NEW COMMENT",
  NEW_CONTEXT: "NEW CONTEXT",
  NEW_PRO_ARGUMENT: "NEW PRO ARGUMENT",
  NEW_CON_ARGUMENT: "NEW CON ARGUMENT",
  NEW_ANSWER: "NEW ANSWER",
  NEW_REFINEMENT: "NEW REFINEMENT",
  NEW_DEFINITION: "NEW DEFINITION",
  NEW_VOTE: "NEW VOTE",
  EDIT_CONTENT: "EDIT CONTENT",
  SPACE_CREATE_REJECT: "SPACE CREATE REJECT",
  SPACE_INVITE: "SPACE INVITE",
  NEW_MEMBER_JOIN: "SPACE JOIN NOTIFY ADMIN",
  ASSIGN_BADGE_APPROVED: "ASSIGN BADGE APPROVED",
  USER_CONNECTION: "USER CONNECTION",
} as const;

export type ActionType = ValueOf<typeof ACTION_TYPE>;

export const PARAM_LINK_TYPE = {
  POST: "POST",
  NODE: "NODE",
  SPACE: "SPACE",
  SPACE_USER: "SPACE_USER",
  SPACE_ACTIVITY: "SPACE_ACTIVITY",
  SECTION: "SECTION",
  SPACE_LIST: "SPACE_LIST",
  SPACE_PROPOSAL: "SPACE_PROPOSAL",
  SPACE_JOIN_REQUEST: "SPACE_JOIN_REQUEST",
  SPACE_WITH_ADMIN_INVITATION: "SPACE_WITH_ADMIN_INVITATION",
  SPACE_WITH_PIONEER_MODAL: "SPACE_WITH_PIONEER_MODAL",
  SPACE_ADMIN_NOTE: "SPACE_ADMIN_NOTE",
  SPACE_WITH_LAUNCH_MODAL: "SPACE_WITH_LAUNCH_MODAL",
  SPACE_WITH_PUBLISH_MODAL: "SPACE_WITH_PUBLISH_MODAL",
  MY_ACTIVITY: "MY_ACTIVITY",
  DELETED: "DELETED",
  DELETED_POST: "DELETED_POST",
  DELETED_SECTION: "DELETED_SECTION",
  POST_ACTIVITY: "POST_ACTIVITY",
  SPACE_ACCESS_TYPE: "SPACE_ACCESS_TYPE",
  SPACE_PROFILE: "SPACE_PROFILE",
} as const;

export type ParamLinkType = ValueOf<typeof PARAM_LINK_TYPE>;

export interface ActionParams {
  linkType: ParamLinkType;
  nodeId?: string;
  rootId?: string;
  rootTitle?: string;
  spaceUrl?: string;
  spaceProposalId?: string;
  actionId?: string;
  userId?: string;
  newAccessType?: SPACE_ACCESS_TYPE;
  prevAccessType?: SPACE_ACCESS_TYPE;
}

export interface ActionSpec {
  actionType: ActionType;
  param: string;
}

export const USER_NOTIFICATION_CONTEXT_TYPE = {
  NODE: "NODE",
  SECTION: "SECTION",
  DELETED_NODE: "DELETED_NODE",
} as const;

export type UserNotificationContextType = ValueOf<typeof USER_NOTIFICATION_CONTEXT_TYPE>;

export interface BaseContext {}

export interface NodeContext extends BaseContext {
  id: string;
  text: string;
  contentGroupId: NodeChildrenInfoGroup;
  stanceKey: StanceKey;
  authorId: string;
  createdAt: number;
  children?: NodeContext[];
}

export interface SectionContext extends BaseContext {
  id: string;
  authorId: string;
  name: string;
  presetId: string;
}

interface UserNotificationContext {
  type: UserNotificationContextType;
  data: BaseContext;
}

export interface UserNotificationModel {
  id: string;
  title: string;
  label: string;
  icon: {
    type: UserNotificationIconType;
    url: string;
    name: string;
  };
  status: UserNotificationStatus;
  timestamp: number;
  actionSpec: ActionSpec;
  context?: UserNotificationContext;
}

export interface GetUserNotificationResponse {
  results: UserNotificationModel[];
  version: string;
  nextPageToken?: string;
  status: number;
}

export interface UpdateUserNotificationResponse {
  status: number;
}
