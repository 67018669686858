import { createSlice } from "@reduxjs/toolkit";

type CleanNodeId = string;

export interface NodeUpdateMetadata {
  lastMutationVersion: number;
  isRefreshUpdate?: boolean;
  isMoveUpdate?: boolean;
  isInsertUpdate?: boolean;
}

type State = Record<CleanNodeId, NodeUpdateMetadata>;

export interface SelectorState {
  discussionViewUpdateMetadata: State;
}

export interface UpsertNodeUpdateMetadataParams {
  nodeComponentId: string;
  updateMetadata: NodeUpdateMetadata;
}

const initialState = {} as State;

export const discussionViewUpdateMetadata = createSlice({
  name: "discussionViewUpdateMetadata",
  initialState,
  reducers: {
    upsertNodeUpdateMetadata: (
      state,
      { payload: { nodeComponentId, updateMetadata } }: { payload: UpsertNodeUpdateMetadataParams },
    ) => {
      const storedNodeAdditionalData = state[nodeComponentId];
      if (storedNodeAdditionalData) {
        if (updateMetadata.lastMutationVersion > storedNodeAdditionalData.lastMutationVersion) {
          state[nodeComponentId] = { ...updateMetadata } as NodeUpdateMetadata;
        } else if (updateMetadata.lastMutationVersion === storedNodeAdditionalData.lastMutationVersion) {
          state[nodeComponentId] = {
            ...state[nodeComponentId],
            ...updateMetadata,
          } as NodeUpdateMetadata;
        }
      } else {
        state[nodeComponentId] = { ...updateMetadata } as NodeUpdateMetadata;
      }
    },
  },
});
