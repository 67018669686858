import type { Middleware } from "@reduxjs/toolkit";

import { isActionTypeToPersist } from "@/src/stores/persist/dbUtils";

export const persistMiddleware: Middleware = () => next => async action => {
  const nextState = next(action);

  if (isActionTypeToPersist(action.type)) {
    document.dispatchEvent(new CustomEvent("WRITE_SYNC"));
  }

  return nextState;
};
