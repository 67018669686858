import { createSlice } from "@reduxjs/toolkit";

import type { SpaceDockUpdateEvent } from "@/src/domains/space/components/SpaceListSidebar/states/SpaceDockEvent";
import { SpaceDockUpdateEventType } from "@/src/domains/space/components/SpaceListSidebar/states/SpaceDockEvent";
import type { Space } from "@/src/domains/space/components/SpaceListSidebar/types/Space";
import type { RootState } from "@/src/stores/rootReducer";

interface SpaceDockState {
  spaces: Space[];
  lastSeenVersion?: number;
  queue: SpaceDockUpdateEvent[];
  isDragging: boolean;
}

interface UpdateSpacePayload {
  afterSpaceId: number;
  spaceId: number;
  /**
   * Pass the space if it's a pinned space
   **/
  space?: Space;
}

const initialState: SpaceDockState = {
  spaces: [],
  lastSeenVersion: undefined,
  queue: [],
  isDragging: false,
};

export const spaceDockSelector = ({ spaceDock }: RootState) => spaceDock.spaces || [];

export const isSpaceDockDraggingSelector = ({ spaceDock }: RootState) => spaceDock.isDragging;

export const spaceDockBySpaceIdSelector =
  (spaceId?: number) =>
  ({ spaceDock }: RootState): Space | undefined => {
    if (!spaceId) {
      return undefined;
    }
    return spaceDock.spaces.find(space => space.spaceId === spaceId);
  };

export const spaceDockVersionSelector = ({ spaceDock }: RootState) => spaceDock.lastSeenVersion;

export const getAllSpaceDockSpaceIds = ({ spaceDock }: RootState) => spaceDock.spaces.map(space => space.spaceId);

export const isExistOnSpaceDock =
  (spaceId?: number) =>
  ({ spaceDock }: RootState) => {
    if (!spaceId) {
      return false;
    }
    return spaceDock.spaces.some(space => space.spaceId === spaceId);
  };

export const spaceDockQueueSize = ({ spaceDock }: RootState) => spaceDock.queue.length;

export const spaceDockQueuePeek = ({ spaceDock }: RootState) => spaceDock.queue[0];

export const spaceDockSlice = createSlice({
  name: "spaceDock",
  initialState,
  reducers: {
    setIsDragging: (state, { payload }: { payload: boolean }) => {
      state.isDragging = payload;
    },
    storeSpaces: (state, { payload: { spaces, lastSeenVersion } = {} }) => {
      state.lastSeenVersion = lastSeenVersion;
      state.spaces = spaces || [];
    },
    updateSpace: (state, { payload }: { payload: UpdateSpacePayload }) => {
      const { afterSpaceId = 0, spaceId, space } = payload;
      const spaceToUpdate = state.spaces.find(_space => _space.spaceId === spaceId);
      const nextSpaces = state.spaces.filter(_space => _space.spaceId !== spaceId);
      const dragIndex = nextSpaces.findIndex(_space => _space.spaceId === afterSpaceId) + 1;

      if (!spaceToUpdate && typeof space !== "undefined") {
        nextSpaces.splice(dragIndex, 0, space);
        state.spaces = nextSpaces;
        // If its a pinned space, we add the space instead of moving it
        state.queue.push({
          type: SpaceDockUpdateEventType.ADD,
          spaceId,
          afterSpaceId,
        });
        return state;
      }

      // If the source space is not found, do nothing
      if (!spaceToUpdate) {
        return;
      }

      // Insert the space at the new index
      nextSpaces.splice(dragIndex, 0, spaceToUpdate);

      state.spaces = nextSpaces;

      if (dragIndex === 0) {
        state.queue.push({
          type: SpaceDockUpdateEventType.MOVE,
          spaceId,
          afterSpaceId: 0,
        });
        return state;
      }

      state.queue.push({
        type: SpaceDockUpdateEventType.MOVE,
        spaceId,
        afterSpaceId,
      });

      return state;
    },
    removeSpace: (state, { payload: spaceId }) => {
      const index = state.spaces.findIndex(s => s.spaceId === spaceId);
      if (index !== -1) {
        state.spaces.splice(index, 1);
        state.queue.push({
          type: SpaceDockUpdateEventType.REMOVE,
          spaceId,
        });
      }
    },
    addSpace: (state, { payload: space }) => {
      // Check if the space is already in the dock
      if (!state.spaces.some(s => s.spaceId === space.spaceId)) {
        // Add the space to most top of the dock
        state.spaces.unshift(space);
        state.queue.push({
          type: SpaceDockUpdateEventType.ADD,
          spaceId: space.spaceId,
          afterSpaceId: 0,
        });
      }
    },
    updateVersion: (state, { payload: lastSeenVersion }) => {
      const stateLastSeenVersion = state.lastSeenVersion;
      if ((stateLastSeenVersion || 0) < lastSeenVersion) {
        state.lastSeenVersion = lastSeenVersion;
      }
    },
    clear() {
      return initialState;
    },
    dequeue: state => {
      state.queue.shift();
    },
  },
});
