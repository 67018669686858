import { sectionStates } from "@/src/domains/content/states/SectionStates/SectionStatesReducer";
import { spaceNodeIds } from "@/src/domains/content/states/SpaceNodeIds/SpaceNodeIdsReducer";
import { spaceNodes } from "@/src/domains/content/states/SpaceNodes/SpaceNodesReducer";
import { tocSlice } from "@/src/domains/content/states/Toc/TocSlice";
import { tocExpandCollapseSlice } from "@/src/domains/content/states/TocExpandCollapse/TocExpandCollapseSlice";
import { tocHighlightSlice } from "@/src/domains/content/states/TocHighlight/TocHighlightSlice";
import { tocSettingsSlice } from "@/src/domains/content/states/TocSettings/TocSettingsSlice";
import spaceDataSlice from "@/src/stores/space/spaceReducer";

const sliceToPersist = {
  toc: tocSlice,
  tocSettings: tocSettingsSlice,
  tocHighlight: tocHighlightSlice,
  tocExpandCollapse: tocExpandCollapseSlice,
  sectionStates: sectionStates,
  spaceNodes: spaceNodes,
  spaceNodeIds: spaceNodeIds,
  spaceData: spaceDataSlice,
};

export default sliceToPersist;
