interface TocScrollState {
  offset: number;
}

interface TocSpaceScrollState {
  [spaceId: number]: TocScrollState;
}

const TocScrollInitialState: TocSpaceScrollState = {};

export default TocScrollInitialState;
