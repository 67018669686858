import { useCallback, useEffect } from "react";

import type { CoachmarkFinishedTrackingSpec, CoachmarkSeenTrackingSpec } from "@/src/constants/TrackingEvent";
import { SIGNUP_WITHOUT_INVITES_TRACKING_EVENT } from "@/src/constants/TrackingEvent";
import useGenericTracking from "@/src/hooks/internal-tracking/useGenericTracking";

export default function useSignupTracking() {
  const trackingFetcher = useGenericTracking();

  /**
   * Triggered when Open "Sign up to join <space>" modal
   * @param {number} spaceId
   */
  const handleTrackOpenSignupToJoinSpaceModal = useCallback(
    (spaceId: number) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_SIGNUP_TO_JOIN_SPACE_MODAL, {
        spaceId,
      });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when Close "Sign up to join <space>" modal
   * @param {number} spaceId
   */
  const handleTrackCloseSignupToJoinSpaceModal = useCallback(
    (spaceId: number) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_SIGNUP_TO_JOIN_SPACE_MODAL, {
        spaceId,
      });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when Click on "Sign up with email" CTA in "Sign up to join <space>" modal
   * @param {number} spaceId
   */
  const handleTrackClickSignupWithEmailCta = useCallback(
    (spaceId: number) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_SIGNUP_WITH_EMAIL_CTA, {
        spaceId,
      });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when Click on "Continue with Google" CTA in "Sign up to join <space>" modal or "Sign up" modal
   * @param {number} spaceId
   */
  const handleTrackClickContinueWithGoogleCta = useCallback(
    (spaceId?: number) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_CONTINUE_WITH_GOOGLE_CTA, {
        spaceId,
      });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when Open sign up modal
   */
  const handleTrackOpenSignupForm = useCallback(() => {
    trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_SIGNUP_FORM);
  }, [trackingFetcher]);

  /**
   * Triggered when Close sign up modal
   */
  const handleTrackCloseSignupForm = useCallback(() => {
    trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_SIGNUP_FORM);
  }, [trackingFetcher]);

  /**
   * Triggered when Submit sign up modal
   */
  const handleTrackSubmitSignupModal = useCallback(() => {
    trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.SUBMIT_SIGNUP_FORM);
  }, [trackingFetcher]);

  /**
   * Triggered when Open email verification modal after sign up
   * @param {number} userId
   */
  const handleTrackOpenEmailVerificationModal = useCallback(
    (userId: number) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_EMAIL_VERIFICATION_MODAL, {
        userId,
      });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when Close email verification modal after sign up
   * @param {number} userId
   */
  const handleTrackCloseEmailVerificationModal = useCallback(
    (userId: number) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_EMAIL_VERIFICATION_MODAL, {
        userId,
      });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when Click "Verify email" CTA in email verification modal after sign up
   * @param {number} userId
   */
  const handleTrackClickVerifyEmail = useCallback(
    (userId: number) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_VERIFY_EMAIL_IN_VERIFICATION_EMAIL, {
        userId,
      });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when user logout
   */
  const handleTrackLogout = useCallback(() => {
    trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CHANGE_EMAIL_WHEN_VERIFYING_EMAIL);
  }, [trackingFetcher]);

  /**
   * Triggered when Profile Verification modal is opened
   */
  const handleTrackOpenNameSelectionModal = useCallback(() => {
    trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_NAME_SELECTION_MODAL);
  }, [trackingFetcher]);

  /**
   * Triggered when Profile Verification modal is closed
   */
  const handleTrackCloseNameSelectionModal = useCallback(() => {
    trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_NAME_SELECTION_MODAL);
  }, [trackingFetcher]);

  /**
   * Triggered when user click one of the suggested display name chips
   */
  const handleTrackClickSuggestedDisplayNameChip = useCallback(() => {
    trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_SUGGESTED_DISPLAY_NAME_CHIP);
  }, [trackingFetcher]);

  /**
   * Triggered when open onboarding card
   */
  const handleTrackOpenOnboardingCard = useCallback(
    (card_name: string) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_ONBOARDING_CARD, { card_name });
    },
    [trackingFetcher],
  );

  /**
   * Triggered when user view a coachmark
   */
  const handleTrackCoachmarkSeen = useCallback(
    (trackingSpec: CoachmarkSeenTrackingSpec) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.COACHMARK_SEEN, trackingSpec);
    },
    [trackingFetcher],
  );

  /**
   * Triggered once the coachmark tour done
   */
  const handleTrackCoachmarkFinished = useCallback(
    (trackingSpec: CoachmarkFinishedTrackingSpec) => {
      trackingFetcher(SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.COACHMARK_FINISHED, trackingSpec);
    },
    [trackingFetcher],
  );

  /**
   * The reason we use document event instead of calling trackingFetcher directly is because
   * we want to not block user from doing other actions while tracking is being sent.
   * and to avoid tracking being cancelled when user do other actions and the component is unmounted
   */
  const handleTrackingDocumentEvent = useCallback(
    (event: CustomEvent) => {
      const { type, payload } = event.detail;
      switch (type) {
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_SIGNUP_TO_JOIN_SPACE_MODAL:
          handleTrackOpenSignupToJoinSpaceModal(payload.spaceId);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_SIGNUP_TO_JOIN_SPACE_MODAL:
          handleTrackCloseSignupToJoinSpaceModal(payload.spaceId);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_SIGNUP_WITH_EMAIL_CTA:
          handleTrackClickSignupWithEmailCta(payload.spaceId);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_CONTINUE_WITH_GOOGLE_CTA:
          handleTrackClickContinueWithGoogleCta(payload.spaceId);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_SIGNUP_FORM:
          handleTrackOpenSignupForm();
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_SIGNUP_FORM:
          handleTrackCloseSignupForm();
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.SUBMIT_SIGNUP_FORM:
          handleTrackSubmitSignupModal();
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_EMAIL_VERIFICATION_MODAL:
          handleTrackOpenEmailVerificationModal(payload.userId);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_EMAIL_VERIFICATION_MODAL:
          handleTrackCloseEmailVerificationModal(payload.userId);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_VERIFY_EMAIL_IN_VERIFICATION_EMAIL:
          handleTrackClickVerifyEmail(payload.userId);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CHANGE_EMAIL_WHEN_VERIFYING_EMAIL:
          handleTrackLogout();
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_NAME_SELECTION_MODAL:
          handleTrackOpenNameSelectionModal();
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLOSE_NAME_SELECTION_MODAL:
          handleTrackCloseNameSelectionModal();
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.CLICK_SUGGESTED_DISPLAY_NAME_CHIP:
          handleTrackClickSuggestedDisplayNameChip();
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.OPEN_ONBOARDING_CARD:
          handleTrackOpenOnboardingCard(payload.card_name);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.COACHMARK_SEEN:
          handleTrackCoachmarkSeen(payload);
          break;
        case SIGNUP_WITHOUT_INVITES_TRACKING_EVENT.COACHMARK_FINISHED:
          handleTrackCoachmarkFinished(payload);
          break;
        default:
        // no-default
      }
    },
    [
      handleTrackOpenSignupToJoinSpaceModal,
      handleTrackCloseSignupToJoinSpaceModal,
      handleTrackClickSignupWithEmailCta,
      handleTrackClickContinueWithGoogleCta,
      handleTrackOpenSignupForm,
      handleTrackCloseSignupForm,
      handleTrackSubmitSignupModal,
      handleTrackOpenEmailVerificationModal,
      handleTrackCloseEmailVerificationModal,
      handleTrackClickVerifyEmail,
      handleTrackLogout,
      handleTrackOpenNameSelectionModal,
      handleTrackCloseNameSelectionModal,
      handleTrackClickSuggestedDisplayNameChip,
      handleTrackOpenOnboardingCard,
      handleTrackCoachmarkSeen,
      handleTrackCoachmarkFinished,
    ],
  );

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("SIGNUP_WITHOUT_INVITES_TRACKING_EVENT", handleTrackingDocumentEvent as EventListener);
      return () => {
        document.removeEventListener(
          "SIGNUP_WITHOUT_INVITES_TRACKING_EVENT",
          handleTrackingDocumentEvent as EventListener,
        );
      };
    }
  }, [handleTrackingDocumentEvent]);
}

type TrackingEventPayload =
  | {
      userId?: number;
      spaceId?: number;
      entryPoint?: string;
    }
  | {
      card_name: string;
    }
  | CoachmarkSeenTrackingSpec
  | CoachmarkFinishedTrackingSpec;

export function sendSignupTrackingEvent(
  type: keyof typeof SIGNUP_WITHOUT_INVITES_TRACKING_EVENT,
  payload?: TrackingEventPayload,
) {
  if (typeof document !== "undefined") {
    document.dispatchEvent(new CustomEvent("SIGNUP_WITHOUT_INVITES_TRACKING_EVENT", { detail: { type, payload } }));
  }
}
