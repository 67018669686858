/*
 state: {
   // spaceId = 1
   1: {
     // sectionId = 1
     1: true,

     // sectionId = 2
     2: false,
   },
 }
*/
import type { TemporaryDataType } from "@/src/domains/content/states/Toc/TocInitialState";

export interface TocTreeState {
  [sectionId: number]: boolean;
}

export interface SpaceTemporaryState {
  type: TemporaryDataType;
  data: TocTreeState;
}

export interface SpaceState {
  data: TocTreeState;
  temporaryData?: SpaceTemporaryState;
}

export interface TocExpandCollapseState {
  [spaceId: number]: SpaceState;
}

const TocExpandCollapseInitialState: TocExpandCollapseState = {};

export default TocExpandCollapseInitialState;
