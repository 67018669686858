import { createSlice } from "@reduxjs/toolkit";

import { OPEN_VOTE_DETAIL_TASK_DIALOG, OPEN_VOTE_LIST_TASK_DIALOG } from "@/src/constants/AppConstant";
import type { NodeObject } from "@/src/domains/content/types/Node";
import type { ConsensusTask } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import initialState from "@/src/stores/openVote/initialState";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface PayloadStoreReviewTask {
  nodeId: string;
  reviewTasks: ConsensusTask[];
  spaceId: string;
  node: NodeObject;
}

interface PayloadSwipeTask {
  direction: string;
}

interface PayloadClearReviewTask {
  taskId: number;
  nodeId: string;
  spaceId: string;
}

const openVoteSlice = createSlice({
  name: "openVote",
  initialState,
  reducers: {
    openDialog: (state, { payload: { nodeId, spaceCanonicalId } }) => {
      state.dialog.isOpen = true;
      state.dialog.nodeId = nodeId;
      state.dialog.spaceCanonicalId = spaceCanonicalId;
      state.dialog.step = OPEN_VOTE_LIST_TASK_DIALOG;
      state.detailTask.taskIndex = 0;
      state.detailTask.overviewTaskIndex = 0;
    },
    closeDialog: state => {
      state.dialog.isOpen = false;
      state.dialog.nodeId = "";
      state.dialog.spaceCanonicalId = "";
    },
    storeReviewTask: (
      state,
      { payload: { nodeId, spaceId, reviewTasks, node } }: ReducerPayload<PayloadStoreReviewTask>,
    ) => {
      // @ts-ignore
      state.reviewTasks = {
        [spaceId]: {
          ...state.reviewTasks?.[spaceId],
          [nodeId]: { reviewTasks: [...reviewTasks], node },
        },
      };
    },
    backToOverview: state => {
      state.dialog.step = OPEN_VOTE_LIST_TASK_DIALOG;
      state.detailTask.taskIndex = 0;
      state.detailTask.overviewTaskIndex = 0;
    },
    goToListDetailTask: (state, { payload: { taskIndex } }: ReducerPayload<{ taskIndex: number }>) => {
      state.dialog.step = OPEN_VOTE_DETAIL_TASK_DIALOG;
      state.detailTask.taskIndex = taskIndex;
    },
    handleSwipeTask: (state, { payload: { direction } }: ReducerPayload<PayloadSwipeTask>) => {
      if (direction === "RIGHT") {
        state.detailTask.taskIndex = state.detailTask.taskIndex + 1;
      } else {
        state.detailTask.taskIndex = state.detailTask.taskIndex - 1;
      }
    },
    setDetailTaskIndex: (state, { payload: { taskIndex } }: ReducerPayload<{ taskIndex: number }>) => {
      state.detailTask.taskIndex = taskIndex;
    },
    setOverviewTaskIndex: (
      state,
      { payload: { overviewTaskIndex } }: ReducerPayload<{ overviewTaskIndex: number }>,
    ) => {
      state.detailTask.overviewTaskIndex = overviewTaskIndex;
    },
    clearReviewTask: (state, { payload: { taskId, nodeId, spaceId } }: ReducerPayload<PayloadClearReviewTask>) => {
      if (state.reviewTasks?.[spaceId]?.[nodeId]?.reviewTasks) {
        const indexToRemove = state.reviewTasks[spaceId][nodeId].reviewTasks?.findIndex(task => task.id === taskId);
        state.reviewTasks = {
          [spaceId]: {
            ...state.reviewTasks?.[spaceId],
            [nodeId]: {
              reviewTasks: [...state.reviewTasks[spaceId][nodeId].reviewTasks].filter(task => task.id !== taskId),
              node: state.reviewTasks[spaceId][nodeId].node,
            },
          },
        };
        state.detailTask.taskIndex =
          indexToRemove < state.detailTask.taskIndex ? state.detailTask.taskIndex - 1 : state.detailTask.taskIndex;
      }
    },
  },
});

export default openVoteSlice;
