import { createSlice } from "@reduxjs/toolkit";

import type { TocNodeProps } from "@/src/domains/content/components/Toc/props/TocApiProps";
import type {
  TemporarySectionData,
  TocInSpace,
  TocSectionLookup,
} from "@/src/domains/content/states/Toc/TocInitialState";
import TocInitialState from "@/src/domains/content/states/Toc/TocInitialState";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface StoreParams {
  spaceId: number;
  data: TocInSpace;
}

export interface TocMutationData {
  sections?: TocSectionLookup;
  firstSectionId?: number;
  firstLoungeId?: number;
  deletedSections?: number[];
}

interface ApplyMutationParams {
  spaceId: number;
  data: TocMutationData;
}

interface OptimisticAddSectionParams {
  spaceId: number;
  actionId: string;
  parentId: number;
  afterId: number;
  node: TocNodeProps;
}

interface OptimisticUpdateSectionParams {
  spaceId: number;
  actionId: string;
  sectionId: number;
  node: Partial<TocNodeProps>;
}

interface OptimisticDeleteSectionParams {
  spaceId: number;
  actionId: string;
  sectionId: number;
}

interface OptimisticArchiveSectionParams {
  spaceId: number;
  actionId: string;
  sectionId: number;
}

interface OptimisticUnarchiveSectionParams {
  spaceId: number;
  actionId: string;
  sectionId: number;
}

interface OptimisticReorderSectionParams {
  spaceId: number;
  actionId: string;
  sectionId: number;
  parentId: number;
  afterId: number;
}

interface RemoveOptimisticActionParams {
  spaceId: number;
  actionId: string;
}

interface SetTemporarySectionParams {
  spaceId: number;
  data: TemporarySectionData;
}

interface UpdateTemporarySectionParams {
  spaceId: number;
  data: Partial<TemporarySectionData>;
}

interface ResetTemporarySectionParams {
  spaceId: number;
}

export const tocSlice = createSlice({
  name: "toc",
  initialState: TocInitialState,
  reducers: {
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { spaceId, data } = payload;
      const { sections, firstSectionId, firstLoungeId } = data;

      state[spaceId] = {
        sections,
        firstSectionId,
        firstLoungeId,
      };
    },
    applyMutation: (state, { payload }: ReducerPayload<ApplyMutationParams>) => {
      const { spaceId, data } = payload;

      const spaceState = state[spaceId];
      if (spaceState) {
        const { sections, firstSectionId, firstLoungeId, deletedSections } = data;

        const updatedSections = { ...spaceState.sections };
        if (sections) {
          for (const key of Object.keys(sections)) {
            const sectionId = Number(key);
            const currentSection = updatedSections[sectionId];
            const updatedSection = sections[sectionId];

            if (
              !currentSection ||
              updatedSection.lastMutationVersion > currentSection.lastMutationVersion ||
              updatedSection.lastUpdatedTimestamp > currentSection.lastUpdatedTimestamp
            ) {
              updatedSections[sectionId] = updatedSection;
            }
          }
        }

        if (deletedSections) {
          for (const deletedSection of deletedSections) {
            delete updatedSections[deletedSection];
          }
        }

        const updatedFirstSectionId = firstSectionId ?? spaceState.firstSectionId;
        const updatedFirstLoungeId = firstLoungeId ?? spaceState.firstLoungeId;

        state[spaceId] = {
          sections: updatedSections,
          firstSectionId: updatedFirstSectionId,
          firstLoungeId: updatedFirstLoungeId,
          optimisticActions: spaceState.optimisticActions,
          temporarySection: spaceState.temporarySection,
        };
      }
    },
    optimisticAddSection: (state, { payload }: ReducerPayload<OptimisticAddSectionParams>) => {
      const { spaceId, actionId, parentId, afterId, node } = payload;

      const spaceState = state[spaceId];
      if (spaceState) {
        if (!spaceState.optimisticActions) {
          spaceState.optimisticActions = [];
        }

        spaceState.optimisticActions.push({
          id: actionId,
          type: "ADD",
          parentId,
          afterId,
          node,
        });
      }
    },
    optimisticUpdateSection: (state, { payload }: ReducerPayload<OptimisticUpdateSectionParams>) => {
      const { spaceId, actionId, sectionId, node } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        if (!spaceState.optimisticActions) {
          spaceState.optimisticActions = [];
        }

        spaceState.optimisticActions.push({
          id: actionId,
          type: "UPDATE",
          sectionId,
          node,
        });
      }
    },
    optimisticDeleteSection: (state, { payload }: ReducerPayload<OptimisticDeleteSectionParams>) => {
      const { spaceId, actionId, sectionId } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        if (!spaceState.optimisticActions) {
          spaceState.optimisticActions = [];
        }

        spaceState.optimisticActions.push({
          id: actionId,
          type: "DELETE",
          sectionId,
        });
      }
    },
    optimisticReorderSection: (state, { payload }: ReducerPayload<OptimisticReorderSectionParams>) => {
      const { spaceId, actionId, sectionId, parentId, afterId } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        if (!spaceState.optimisticActions) {
          spaceState.optimisticActions = [];
        }

        spaceState.optimisticActions.push({
          id: actionId,
          type: "REORDER",
          sectionId,
          parentId,
          afterId,
        });
      }
    },
    optimisticArchiveSection: (state, { payload }: ReducerPayload<OptimisticArchiveSectionParams>) => {
      const { spaceId, actionId, sectionId } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        if (!spaceState.optimisticActions) {
          spaceState.optimisticActions = [];
        }

        spaceState.optimisticActions.push({
          id: actionId,
          type: "ARCHIVE",
          sectionId,
        });
      }
    },
    optimisticUnarchiveSection: (state, { payload }: ReducerPayload<OptimisticUnarchiveSectionParams>) => {
      const { spaceId, actionId, sectionId } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        if (!spaceState.optimisticActions) {
          spaceState.optimisticActions = [];
        }

        spaceState.optimisticActions.push({
          id: actionId,
          type: "UNARCHIVE",
          sectionId,
        });
      }
    },
    removeOptimisticAction: (state, { payload }: ReducerPayload<RemoveOptimisticActionParams>) => {
      const { actionId, spaceId } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        if (!spaceState.optimisticActions) {
          spaceState.optimisticActions = [];
        }

        const index = spaceState.optimisticActions.findIndex(p => p.id === actionId);
        if (index >= 0) {
          spaceState.optimisticActions.splice(index, 1);
        }
      }
    },
    setTemporarySection: (state, { payload }: ReducerPayload<SetTemporarySectionParams>) => {
      const { spaceId, data } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        spaceState.temporarySection = data;
      }
    },
    updateTemporarySection: (state, { payload }: ReducerPayload<UpdateTemporarySectionParams>) => {
      const { spaceId, data } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        const temporarySection = spaceState.temporarySection;
        if (temporarySection) {
          spaceState.temporarySection = {
            ...temporarySection,
            ...data,
          };
        }
      }
    },
    resetTemporarySection: (state, { payload }: ReducerPayload<ResetTemporarySectionParams>) => {
      const { spaceId } = payload;
      const spaceState = state[spaceId];
      if (spaceState) {
        spaceState.temporarySection = undefined;
      }
    },
    reset: () => {
      return TocInitialState;
    },
  },
});
