import type { ValueOf } from "@/src/utils/helpers/TypeUtil";

export const VIRTUALIZED_USER_NOTIFICATION_ITEM_TYPE = {
  DEFAULT: "DEFAULT",
  USER_CONNECTION: "USER_CONNECTION",
} as const;

export type VirtualizedUserNotificationItemType = ValueOf<typeof VIRTUALIZED_USER_NOTIFICATION_ITEM_TYPE>;

export interface VirtualizedUserNotificationItem {
  id: string;
  type: VirtualizedUserNotificationItemType;
}
