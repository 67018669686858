export default {
  mySpaces: [],
  templates: {
    private: [],
    community: [],
    brandAdministered: [],
    isLoadingSpaceTemplate: false,
    shouldFetchSpaceTemplates: true,
  },
  spaces: {
    browse: {
      query: "",
      result: [],
      proposed: [],
      shouldFetch: true,
    },
    list: [],
    pending: [],
    invited: [],
    activeSpaceProposals: [],
  },
  fetchStatus: {
    shouldFetchSpaces: true,
    isFetchingSpaces: false,
    // V2
    shouldFetchMySpaces: true,
    isFetchingSpacesList: false,
    isFetchOnboardingProgress: false,
  },
  onboarding: {
    onboardingSpaces: [],
  },
};
