import qs from "qs";

function parseTemplateString(template: string, params: Record<string, string | number | undefined> = {}) {
  return Object.entries(params).reduce(
    (string, [key, value]) => string.replace(new RegExp(`:${key}`, "g"), value?.toString() || ""),
    template,
  );
}

export default parseTemplateString;

export function parseTemplateStringWithQueryString(data: {
  template: string;
  params?: Record<string, string | undefined>;
  queryString?: Record<string, string>;
}) {
  const newUrl = parseTemplateString(data.template, data.params);

  const queryString = qs.stringify(data.queryString);
  if (!queryString) return newUrl;
  // Check if newUrl already contains a query string
  const separator = newUrl.includes("?") ? "&" : "?";

  return `${newUrl}${separator}${queryString}`;
}
