import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import type { PersistedState } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

import { chatDataSlice } from "@/src/domains/content/components/Chat/states/ChatDataReducer";
import { chatDialogSlice } from "@/src/domains/content/components/Chat/states/ChatDialogReducer";
import { actionDisplays } from "@/src/domains/content/states/ActionDisplays/ActionDisplaysReducers";
import { spaceAuthoredFeeds } from "@/src/domains/content/states/AuthoredFeed/AuthoredFeedReducer";
import { discussionMapNodesSlice } from "@/src/domains/content/states/DiscussionMapNodes/DiscussionMapNodesReducer";
import discussionViewHover from "@/src/domains/content/states/DiscussionViewHover/DiscussionViewHoverReducer";
import { discussionViewUpdateMetadata } from "@/src/domains/content/states/DiscussionViewUpdateMetadata/DiscussionViewUpdateMetadataReducer";
import { drafts } from "@/src/domains/content/states/Draft/DraftReducer";
import { spaceDraftAndMyPosts } from "@/src/domains/content/states/DraftAndMyPosts/DraftAndMyPostsReducer";
import { draftsInSpace } from "@/src/domains/content/states/DraftInSpace/DraftInSpaceReducer";
import { floatingDraftSpace } from "@/src/domains/content/states/FloatingDraft/FloatingDraftReducer";
import { localInProgressActionReducer } from "@/src/domains/content/states/LocalInProgressAction/LocalInProgressActionReducer";
import { myPosts } from "@/src/domains/content/states/MyPosts/MyPostsReducer";
import { numConcurrentActionsReducer } from "@/src/domains/content/states/NumConcurrentActions/NumConcurrentActionsReducer";
import { previewPostSlice } from "@/src/domains/content/states/PreviewPost/PreviewPostSlice";
import { spaceRecentFeeds } from "@/src/domains/content/states/RecentFeed/RecentFeedReducer";
import { sectionStates } from "@/src/domains/content/states/SectionStates/SectionStatesReducer";
import { spaceDiscussionCountSlice } from "@/src/domains/content/states/SpaceDiscussionCount/SpaceDiscussionCountSlice";
import { spaceLoungeHighlightSlice } from "@/src/domains/content/states/SpaceLoungeHighlight/SpaceLoungeHighlightSlice";
import { spaceNodeIds } from "@/src/domains/content/states/SpaceNodeIds/SpaceNodeIdsReducer";
import { discussionMapRelations } from "@/src/domains/content/states/SpaceNodeRelations/DiscussionMapRelationsReducer";
import { discussionViewChildrenInfo } from "@/src/domains/content/states/SpaceNodeRelations/DiscussionViewChildrenInfoReducer";
import { spaceNodes } from "@/src/domains/content/states/SpaceNodes/SpaceNodesReducer";
import { spaceSavedContents } from "@/src/domains/content/states/SpaceSavedContents/SpaceSavedContentsReducer";
import { tocSlice } from "@/src/domains/content/states/Toc/TocSlice";
import TocConfigInitialState from "@/src/domains/content/states/TocConfig/TocConfigInitialState";
import { tocConfigSlice } from "@/src/domains/content/states/TocConfig/TocConfigSlice";
import { tocExpandCollapseSlice } from "@/src/domains/content/states/TocExpandCollapse/TocExpandCollapseSlice";
import { tocHighlightSlice } from "@/src/domains/content/states/TocHighlight/TocHighlightSlice";
import { tocScrollSlice } from "@/src/domains/content/states/TocScroll/TocScrollSlice";
import { tocSettingsSlice } from "@/src/domains/content/states/TocSettings/TocSettingsSlice";
import { spaceUnattendedFeeds } from "@/src/domains/content/states/UnattendedFeed/UnattendedFeedReducer";
import dynamicFormSlice from "@/src/domains/dynamic-form/components/DynamicForm/stores/slice";
import { myActivitiesSlice } from "@/src/domains/liquidity/components/MyActivityList/states/MyActivitiesSlice";
import { myActivitySlice } from "@/src/domains/liquidity/components/MyActivityList/states/MyActivitySlice";
import { myReviewedTasksSlice } from "@/src/domains/liquidity/components/MyReviewedTaskList/states/MyReviewedTasksSlice";
import { userNotificationSlice } from "@/src/domains/liquidity/components/UserNotification/states/UserNotificationSlice";
import { pendingTaskSlice } from "@/src/domains/liquidity/states/PendingTask/PendingTaskReducer";
import { spaceCompletedActivitiesSlice } from "@/src/domains/liquidity/states/SpaceCompletedActivities/SpaceCompletedActivitiesSlice";
import { spaceReviewTasksSlice } from "@/src/domains/liquidity/states/SpaceReviewTasks/SpaceReviewTasksSlice";
import joinSpaceSlice from "@/src/domains/space/components/JoinSpaceDialog/state/slice";
import { spaceDockSlice } from "@/src/domains/space/components/SpaceListSidebar/states/SpaceDockReducer";
import { navigationSlice } from "@/src/domains/space/states/SpaceNavigation/slice";
import spaceSlice from "@/src/domains/space/states/slice";
import UpdateIndicatorInitialState from "@/src/domains/update-indicator/states/UpdateIndicatorInitialState";
import updateIndicatorSlice from "@/src/domains/update-indicator/states/UpdateIndicatorSlice";
import inviteSlice from "@/src/domains/user/components/Invite/state/slice";
import previewBannerSlice from "@/src/domains/user/components/PreviewBanner/state/slice";
import userSettingsSlice from "@/src/domains/user/components/UserSettings/state/userSettingsSlice";
import { unwatchPromptSlice } from "@/src/pages/SpaceLandingPage/components/WatchSpace/states/UnwatchPromptReducer";
import coachmark from "@/src/stores/coachmark/slice";
import debugConfigSlice from "@/src/stores/debugConfig/debugConfigReducer";
import dialogsSlice from "@/src/stores/dialogs/slice";
import draftSlice from "@/src/stores/draft/slice";
import joinSpaceOptionsSlice from "@/src/stores/joinSpaceOptions/state/slice";
import newNodeDialogSlice from "@/src/stores/newNodeDialog/slice";
import { notificationsSlice } from "@/src/stores/notifications/NotificationsSlice";
import openVoteSlice from "@/src/stores/openVote/slice";
import presenceSlice from "@/src/stores/presence/presenceReducer";
import serviceWorkerSlice from "@/src/stores/serviceWorker/slice";
import signupDialogSlice from "@/src/stores/signupDialog/slice";
import spacePendingMetadataSlice from "@/src/stores/space/pendingMetadataReducer";
import spaceDataSlice from "@/src/stores/space/spaceReducer";
import spaceInformationBannerSlice from "@/src/stores/spaceInformationBanner/slice";
import { tutorialCardSlice } from "@/src/stores/tutorialCard/slice";
import userSlice from "@/src/stores/user/slice";
import userDataSlice from "@/src/stores/userData/slice";
import votableConsensusTaskSlice from "@/src/stores/votableConsensusTask/slice";
import zendeskSlice from "@/src/stores/zendesk/slice";

const defaultPersistConfig = {
  storage,
};

const coachmarkPersistConfig = {
  ...defaultPersistConfig,
  key: "coachmark",
};

const invitePersistConfig = {
  ...defaultPersistConfig,
  key: "invite",
  storage: storageSession,
  whitelist: ["invitationToken", "confirmationToken", "emailPrefill"],
};

const zendeskPersistConfig = {
  ...defaultPersistConfig,
  key: "zendesk",
  blacklist: ["isZendeskScriptLoaded"],
};

const spaceInformationBannerPersistConfig = {
  ...defaultPersistConfig,
  key: "spaceInformationBanner-v2",
};

const notificationsPersistConfig = {
  ...defaultPersistConfig,
  key: "notifications",
};

const userNotificationPersistConfig = {
  ...defaultPersistConfig,
  key: "userNotification",
};

const myActivityPersistConfig = {
  ...defaultPersistConfig,
  key: "myActivity",
};

const navigationPersistConfig = {
  ...defaultPersistConfig,
  key: "navigation",
  whitelist: ["lastVisitedSpaceId"],
};

const spaceListPersistConfig = {
  ...defaultPersistConfig,
  key: "spaceList",
  whitelist: ["spaces"],
};

const updateIndicatorPersistConfig = {
  ...defaultPersistConfig,
  key: "updateIndicator",
  migrate: (state: PersistedState) => {
    return Promise.resolve({
      ...UpdateIndicatorInitialState,
      ...state,
    } as PersistedState);
  },
};

const userPersistConfig = {
  ...defaultPersistConfig,
  key: "user",
};

// Do not purge this state on logout since we want to keep the user's preferences persistent across sessions
const userSettingsPersistConfig = {
  ...defaultPersistConfig,
  key: "userSettings",
};

const spaceDockPersistConfig = {
  ...defaultPersistConfig,
  key: "spaceDock",
  timeout: 0,
  whitelist: ["spaces", "lastSeenVersion"],
};

const unwatchPromptPersistConfig = {
  ...defaultPersistConfig,
  key: "unwatchPrompt",
};

const previewPostPersistConfig = {
  ...defaultPersistConfig,
  key: "previewPost",
  whitelist: ["previewPostConfig"],
};

const tocConfigPersistConfig = {
  ...defaultPersistConfig,
  key: "tocConfig",
  migrate: (state: PersistedState) => {
    return Promise.resolve({
      ...TocConfigInitialState,
      ...state,
    } as PersistedState);
  },
};

const debugConfigPersistConfig = {
  ...defaultPersistConfig,
  key: "debugConfig",
};

export const persistReducers = {
  notifications: persistReducer(notificationsPersistConfig, notificationsSlice.reducer),
  userNotification: persistReducer(userNotificationPersistConfig, userNotificationSlice.reducer),
  myActivity: persistReducer(myActivityPersistConfig, myActivitySlice.reducer),
  user: persistReducer(userPersistConfig, userSlice.reducer),
  userSettings: persistReducer(userSettingsPersistConfig, userSettingsSlice.reducer),
  space: persistReducer(spaceListPersistConfig, spaceSlice.reducer),
  navigation: persistReducer(navigationPersistConfig, navigationSlice.reducer),
  coachmark: persistReducer(coachmarkPersistConfig, coachmark.reducer),
  invite: persistReducer(invitePersistConfig, inviteSlice.reducer),
  zendesk: persistReducer(zendeskPersistConfig, zendeskSlice.reducer),
  spaceInformationBanner: persistReducer(spaceInformationBannerPersistConfig, spaceInformationBannerSlice.reducer),
  updateIndicator: persistReducer(updateIndicatorPersistConfig, updateIndicatorSlice.reducer),
  spaceDock: persistReducer(spaceDockPersistConfig, spaceDockSlice.reducer),
  unwatchPrompt: persistReducer(unwatchPromptPersistConfig, unwatchPromptSlice.reducer),
  previewPost: persistReducer(previewPostPersistConfig, previewPostSlice.reducer),
  spaceData: spaceDataSlice.reducer,
  tocConfig: persistReducer(tocConfigPersistConfig, tocConfigSlice.reducer),
  debugConfig: persistReducer(debugConfigPersistConfig, debugConfigSlice.reducer),
};

export const reducers = {
  userData: userDataSlice.reducer,
  serviceWorker: serviceWorkerSlice.reducer,
  draft: draftSlice.reducer,
  newNodeDialog: newNodeDialogSlice.reducer,
  previewBanner: previewBannerSlice.reducer,
  joinSpace: joinSpaceSlice.reducer,
  joinSpaceOptions: joinSpaceOptionsSlice.reducer,
  spaceRecentFeeds: spaceRecentFeeds.reducer,
  spaceUnattendedFeeds: spaceUnattendedFeeds.reducer,
  spaceAuthoredFeeds: spaceAuthoredFeeds.reducer,
  spaceSavedContents: spaceSavedContents.reducer,

  spaceDiscussionCount: spaceDiscussionCountSlice.reducer,
  discussionMapRelations: discussionMapRelations.reducer,
  discussionViewChildrenInfo: discussionViewChildrenInfo.reducer,
  dynamicForm: dynamicFormSlice.reducer,
  dialogs: dialogsSlice.reducer,
  toc: tocSlice.reducer,
  tocHighlight: tocHighlightSlice.reducer,
  tocExpandCollapse: tocExpandCollapseSlice.reducer,
  tocSettings: tocSettingsSlice.reducer,
  tocScroll: tocScrollSlice.reducer,
  spaceLoungeHighlight: spaceLoungeHighlightSlice.reducer,
  localInProgressActionSlice: localInProgressActionReducer.reducer,
  numConcurrentActionsSlice: numConcurrentActionsReducer.reducer,
  chatData: chatDataSlice.reducer,
  chatDialog: chatDialogSlice.reducer,
  discussionViewUpdateMetadata: discussionViewUpdateMetadata.reducer,
  signupDialog: signupDialogSlice.reducer,
  openVote: openVoteSlice.reducer,
  tutorialCard: tutorialCardSlice.reducer,
  presence: presenceSlice.reducer,
  spaceDraftAndMyPosts: spaceDraftAndMyPosts.reducer,
  draftsInSpace: draftsInSpace.reducer,

  voteableConsensusTask: votableConsensusTaskSlice.reducer,
  floatingDraftSpace: floatingDraftSpace.reducer,
  // Temporary reducer to be removed after https://github.com/hiveos-team/hiveos-frontend/pull/5845 merged
  spacePendingMetadata: spacePendingMetadataSlice.reducer,
  myActivities: myActivitiesSlice.reducer,
  myReviewedTasks: myReviewedTasksSlice.reducer,
  drafts: drafts.reducer,
  myPosts: myPosts.reducer,
  spaceReviewTasks: spaceReviewTasksSlice.reducer,
  spaceCompletedActivities: spaceCompletedActivitiesSlice.reducer,
  sectionStates: sectionStates.reducer,
  spaceNodes: spaceNodes.reducer,
  actionDisplays: actionDisplays.reducer,
  spaceNodeIds: spaceNodeIds.reducer,
  discussionMapNodes: discussionMapNodesSlice.reducer,
  pendingTask: pendingTaskSlice.reducer,
  discussionViewHover: discussionViewHover.reducer,
  ...persistReducers,
};

const appReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof appReducer>;

export default appReducer;
