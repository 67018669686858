import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/coachmark/initialState";

export default createSlice({
  name: "coachmark",
  initialState,
  reducers: {
    showDiscourseCoachmark: state => {
      state.showDiscourseCoachMark = true;
      return state;
    },
    disabledDiscourseCoachmark: state => {
      state.showDiscourseCoachMark = false;
      return state;
    },
    showSpaceListCoachmark(state) {
      state.spaceList = true;
    },
    disableSpaceListCoachmark(state) {
      state.spaceList = false;
    },
    showBrowseSpaceCoachmark(state) {
      state.browseSpace = true;
    },
    disableBrowseSpaceCoachmark(state) {
      state.browseSpace = false;
    },
    showCreateSpaceCoachmark(state) {
      state.createSpace = true;
    },
    disablCreateSpaceCoachmark(state) {
      state.createSpace = false;
    },
    setUserCoachmark(state, { payload }) {
      state.userCoachmark = payload.coachmarks;
    },
    updateUserCoachmark(state, { payload }) {
      state.userCoachmark = { ...state.userCoachmark, ...payload.coachmarks };
    },
    resetUserCoachmark(state) {
      state.userCoachmark = {};
    },
    showNoteFromAdminCoachmark(state) {
      state.noteFromAdmin = true;
    },
    disableNoteFromAdminCoachmark(state) {
      state.noteFromAdmin = false;
    },
    showSpaceActionLogNoteCoachmark(state) {
      state.spaceActionLogNote = true;
    },
    hideSpaceActionLogNoteCoachmark(state) {
      state.spaceActionLogNote = false;
    },
  },
});
