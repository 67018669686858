import type { NodeObject } from "@/src/domains/content/types/Node";
import type { ConsensusTask } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";

interface OpenVoteState {
  dialog: {
    isOpen: boolean;
    nodeId: string;
    spaceCanonicalId: string;
    step: string;
  };
  detailTask: {
    taskIndex: number;
    overviewTaskIndex: number;
  };
  reviewTasks?: {
    [spaceId: string]: {
      [nodeId: string]: { node: NodeObject; reviewTasks: ConsensusTask[] };
    };
  };
}

const initialState: OpenVoteState = {
  dialog: {
    isOpen: false,
    nodeId: "",
    spaceCanonicalId: "",
    step: "LIST",
  },
  detailTask: {
    taskIndex: 0,
    overviewTaskIndex: 0,
  },
  reviewTasks: {},
};

export default initialState;
