import type { SectionType } from "@/src/components/sembly-ui/feature/Toc/@types/SectionType";
import type { TocNodeProps } from "@/src/domains/content/components/Toc/props/TocApiProps";

export type TemporaryDataType = "DEFAULT" | "TOC" | "TOC_PREVIEW";

export interface TemporarySectionData {
  type: TemporaryDataType;
  sectionId: number;
  sectionName: string;
  sectionType: SectionType;
  parentId: number;
  afterId: number;
}

export interface TocSectionData {
  data: TocNodeProps;
  parentId: number;
  prevId: number;
  nextId: number;
  firstSubsectionId: number;
  lastMutationVersion: number;
  lastUpdatedTimestamp: number;
}

export interface TocSectionLookup {
  [sectionId: number]: TocSectionData;
}

interface BaseTocOptimisticAction {
  id: string;
}

export interface TocOptimisticAddAction extends BaseTocOptimisticAction {
  type: "ADD";
  parentId: number;
  afterId: number;
  node: TocNodeProps;
}

export interface TocOptimisticUpdateAction extends BaseTocOptimisticAction {
  type: "UPDATE";
  sectionId: number;
  node: Partial<TocNodeProps>;
}

export interface TocOptimisticDeleteAction extends BaseTocOptimisticAction {
  type: "DELETE";
  sectionId: number;
}

export interface TocOptimisticReorderAction extends BaseTocOptimisticAction {
  type: "REORDER";
  sectionId: number;
  parentId: number;
  afterId: number;
}

export interface TocOptimisticArchiveAction extends BaseTocOptimisticAction {
  type: "ARCHIVE";
  sectionId: number;
}

export interface TocOptimisticUnarchiveAction extends BaseTocOptimisticAction {
  type: "UNARCHIVE";
  sectionId: number;
}

export type TocOptimisticAction =
  | TocOptimisticAddAction
  | TocOptimisticUpdateAction
  | TocOptimisticDeleteAction
  | TocOptimisticReorderAction
  | TocOptimisticArchiveAction
  | TocOptimisticUnarchiveAction;

export interface TocInSpace {
  sections: TocSectionLookup;
  firstSectionId: number;
  firstLoungeId: number;
  optimisticActions?: TocOptimisticAction[];
  temporarySection?: TemporarySectionData;
}

export interface TocState {
  [spaceId: number]: TocInSpace;
}

const TocInitialState: TocState = {};

export default TocInitialState;
