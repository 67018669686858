import type { ConsensusTask, TaskStatus } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import type { TimestampInNano } from "@/src/utils/general/TimeUtil";

export interface CompactReviewedTask {
  taskId: number;
  submittedAt: TimestampInNano;
  state: TaskStatus;
}

interface MyReviewedTasksState {
  sequences: CompactReviewedTask[];
  tasks: Record<number, ConsensusTask>;
  nextPageToken?: string;
  endReached: boolean;
}

const MyReviewedTasksInitialState: MyReviewedTasksState = {
  sequences: [],
  tasks: {},
  endReached: false,
};

export default MyReviewedTasksInitialState;
