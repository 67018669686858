import { createSlice } from "@reduxjs/toolkit";

import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

type PaneName = "MAIN" | "INIT_CHAT" | "CHANNEL";

type State = {
  isOpen: boolean;
  activePaneName: PaneName;
  activeChannelId?: string;
};

const initialState: State = {
  isOpen: false,
  activePaneName: "MAIN",
  activeChannelId: undefined,
};

export const chatDialogSlice = createSlice({
  name: "chatDialog",
  initialState: initialState,
  reducers: {
    openDialog: (state: State) => {
      state.isOpen = true;
    },
    closeDialog: (state: State) => {
      state.isOpen = false;
    },
    toggleDialog: (state: State) => {
      state.isOpen = !state.isOpen;
    },
    navigateToPane: (state: State, { payload }: ReducerPayload<{ paneName: PaneName; channelId?: string }>) => {
      state.activePaneName = payload.paneName;
      state.isOpen = true;
      switch (payload.paneName) {
        case "CHANNEL": {
          if (payload.channelId) {
            state.activeChannelId = payload.channelId;
          } else {
            state.activeChannelId = undefined;
          }
          break;
        }
        case "MAIN": {
          state.isOpen = true;
          state.activeChannelId = undefined;
          break;
        }
        default:
          state.activeChannelId = undefined;
          break;
      }
    },
  },
});
