import { createSlice } from "@reduxjs/toolkit";

import type { DiscussionCountInSpace } from "@/src/domains/content/states/SpaceDiscussionCount/SpaceDiscussionCountInitialState";
import SpaceDiscussionCountInitialState from "@/src/domains/content/states/SpaceDiscussionCount/SpaceDiscussionCountInitialState";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface StoreParams {
  spaceId: number;
  data: DiscussionCountInSpace;
}

export const spaceDiscussionCountSlice = createSlice({
  name: "spaceDiscussionCount",
  initialState: SpaceDiscussionCountInitialState,
  reducers: {
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { spaceId, data } = payload;
      state[spaceId] = data;
    },
  },
});
