export default {
  invitationToken: {
    type: null,
    value: null,
    hash: null,
    spaceId: null,
    spaceUrl: null,
  },
  confirmationToken: null,
  emailPrefill: null,
};
