import { createSlice } from "@reduxjs/toolkit";

import type {
  ChatChannel,
  ChatChannelMembership,
  ChatMessage,
  ChatUserProfile,
} from "@/src/domains/content/components/Chat/types/ReducerModel";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

type State = {
  channels: {
    [key in string]: ChatChannel;
  };
  channelOrder: string[];
  currentUser: ChatUserProfile;
  channelMemberships: {
    [key in string]: ChatChannelMembership;
  };
};

const initialState: State = {
  channels: {},
  channelOrder: [],
  currentUser: {
    userId: 0,
    name: "",
    connectionStatus: "CONNECTED",
  },
  channelMemberships: {},
};

export const chatDataSlice = createSlice({
  name: "chatData",
  initialState: initialState,
  reducers: {
    populateChannels: (
      state: State,
      {
        payload,
      }: ReducerPayload<{
        channels: { [key in string]: ChatChannel };
        channelOrder: string[];
        channelMemberships: { [key in string]: ChatChannelMembership };
      }>,
    ) => {
      state.channels = payload.channels;
      state.channelOrder = payload.channelOrder;
      state.channelMemberships = payload.channelMemberships;
    },
    setCurrentUser: (state: State, { payload }: ReducerPayload<{ user: ChatUserProfile }>) => {
      state.currentUser = payload.user;
    },
    batchUpsertChannels: (
      state: State,
      {
        payload,
      }: ReducerPayload<{
        channels: ChatChannel[];
        channelOrder?: string[];
        channelMemberships?: ChatChannelMembership[];
      }>,
    ) => {
      payload.channels.forEach(channel => {
        const channelId = channel.info.channelId;
        state.channels[channelId] = {
          ...state.channels[channelId],
          ...channel,
        };
      });
      if (payload.channelOrder) {
        const channelOrder = payload.channelOrder;
        const oldChannelOrder = state.channelOrder.filter(channelId => !channelOrder.includes(channelId));
        state.channelOrder = [...payload.channelOrder, ...oldChannelOrder];
      }
      if (payload.channelMemberships) {
        payload.channelMemberships.forEach(channelMembership => {
          state.channelMemberships[channelMembership.channelId] = {
            ...state.channelMemberships[channelMembership.channelId],
            ...channelMembership,
          };
        });
      }
    },
    initChannel: (state: State, { payload }: ReducerPayload<{ channel: ChatChannel }>) => {
      state.channels[payload.channel.info.channelId] = payload.channel;
      state.channelOrder.unshift(payload.channel.info.channelId);
      state.channelMemberships[payload.channel.info.channelId] = { channelId: payload.channel.info.channelId };
    },
    insertPendingMessage: (
      state: State,
      { payload }: ReducerPayload<{ channelId: string; pendingMessage?: string; pendingAttachments?: File[] }>,
    ) => {
      const pendingMessageId = String(new Date().getTime() * 1e3);
      state.channels[payload.channelId].pendingMessages.data[pendingMessageId] = {
        text: payload.pendingMessage,
        attachments: payload.pendingAttachments,
      };
      state.channels[payload.channelId].pendingMessages.pendingMessageOrder.push(pendingMessageId);
    },
    deletePendingMessage: (
      state: State,
      { payload }: ReducerPayload<{ channelId: string; pendingMessageId: string }>,
    ) => {
      const pendingMessages = state.channels[payload.channelId].pendingMessages;
      pendingMessages.pendingMessageOrder = pendingMessages.pendingMessageOrder.filter(
        pendingMessageId => pendingMessageId !== payload.pendingMessageId,
      );
      delete state.channels[payload.channelId].pendingMessages.data[payload.pendingMessageId];
    },
    insertMessage: (state: State, { payload }: ReducerPayload<{ channelId: string; message: ChatMessage }>) => {
      state.channels[payload.channelId].messages.data[payload.message.messageId] = payload.message;
      state.channels[payload.channelId].info.lastMessage = payload.message;
      state.channelMemberships[payload.channelId].lastReadMessageId = payload.message.messageId;
      state.channels[payload.channelId].messages.messageOrder.push(payload.message.messageId);
      const channelOrderIndex = state.channelOrder.findIndex(channelId => channelId === payload.channelId);
      state.channelOrder = [
        payload.channelId,
        ...state.channelOrder.slice(0, channelOrderIndex),
        ...state.channelOrder.slice(channelOrderIndex + 1),
      ];
    },
    softDeleteChannel: (state: State, { payload }: ReducerPayload<{ channelId: string }>) => {
      const channelIndex = state.channelOrder.findIndex(existingChannelId => existingChannelId === payload.channelId);
      state.channelOrder = [
        ...state.channelOrder.slice(0, channelIndex),
        ...state.channelOrder.slice(channelIndex + 1),
      ];
    },
    updateLastReadMessageId: (state: State, { payload }: ReducerPayload<{ channelId: string; messageId: string }>) => {
      state.channelMemberships[payload.channelId].lastReadMessageId = payload.messageId;
    },
    updateDraft: (state: State, { payload }: ReducerPayload<{ channelId: string; messageDraft: string }>) => {
      state.channels[payload.channelId].draft.text = payload.messageDraft;
    },
  },
});
