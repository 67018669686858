export const SPACE_DOCK_UPDATE_EVENT = "spaceDockUpdate";

export enum SpaceDockUpdateEventType {
  ADD = "add",
  REMOVE = "remove",
  MOVE = "move",
  UPDATE = "update",
}

export interface SpaceDockUpdateEvent {
  type: SpaceDockUpdateEventType;
  spaceId: number;
  afterSpaceId?: number;
}

export const moveSpaceBySpaceId = (spaceId: number, afterSpaceId: number) => {
  const event = new CustomEvent(SPACE_DOCK_UPDATE_EVENT, {
    detail: { spaceId, afterSpaceId, type: SpaceDockUpdateEventType.MOVE },
  });
  document.dispatchEvent(event);
};

export const removeSpaceBySpaceId = (spaceId: number) => {
  const event = new CustomEvent(SPACE_DOCK_UPDATE_EVENT, {
    detail: { spaceId, type: SpaceDockUpdateEventType.REMOVE },
  });
  document.dispatchEvent(event);
};

export const addSpaceBySpaceId = (spaceId: number, afterSpaceId?: number) => {
  const event = new CustomEvent(SPACE_DOCK_UPDATE_EVENT, {
    detail: { spaceId, afterSpaceId, type: SpaceDockUpdateEventType.ADD },
  });
  document.dispatchEvent(event);
};

export const updateSpaceDock = () => {
  const event = new CustomEvent(SPACE_DOCK_UPDATE_EVENT, {
    detail: { type: SpaceDockUpdateEventType.UPDATE },
  });
  document.dispatchEvent(event);
};
