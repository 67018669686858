export enum SpaceLoungeHighlightTarget {
  DEFAULT = "DEFAULT",
  NODE = "NODE",
}

interface SpaceLoungeHighlightSpaceState {
  sectionId: number;
  target: SpaceLoungeHighlightTarget;
}

interface SpaceLoungeHighlightState {
  [spaceId: number]: SpaceLoungeHighlightSpaceState;
}

const SpaceLoungeHighlightInitialState: SpaceLoungeHighlightState = {};

export default SpaceLoungeHighlightInitialState;
