import { sanitize } from "dompurify";

import * as Constant from "@/src/constants/AppConstant";
import { HIGHLIGHT_QUERY_STRING } from "@/src/constants/ContentConstants";
import type {
  ActionParams,
  ActionType,
  ParamLinkType,
  UserNotificationModel,
} from "@/src/domains/liquidity/components/UserNotification/types/UserNotificationApiTypes";
import {
  ACTION_TYPE,
  PARAM_LINK_TYPE,
} from "@/src/domains/liquidity/components/UserNotification/types/UserNotificationApiTypes";
import type { VirtualizedUserNotificationItemType } from "@/src/domains/liquidity/components/UserNotification/types/VirtualizedUserNotificationTypes";
import { VIRTUALIZED_USER_NOTIFICATION_ITEM_TYPE } from "@/src/domains/liquidity/components/UserNotification/types/VirtualizedUserNotificationTypes";
import type { ConnectionStatus } from "@/src/domains/user/components/UserProfile/types/UserProfile";
import { SPACE_ACTION_TYPE } from "@/src/pages/SpaceActionLogPage/constant";
import type { ClientError } from "@/src/utils/general/CustomError";
import { htmlDecode } from "@/src/utils/general/StringUtil";
import { generateSlug } from "@/src/utils/helpers/UrlHelper";
import parseTemplateString, { parseTemplateStringWithQueryString } from "@/src/utils/helpers/parseTemplateString";
import { captureException } from "@/src/utils/logging/SentryLogging";

const ROUTE_MAP: { [key in ParamLinkType]?: string } = {
  [PARAM_LINK_TYPE.POST]: Constant.ROUTER_PATH_SPACE_NODE_WITH_HIGHLIGHT,
  [PARAM_LINK_TYPE.NODE]: Constant.ROUTER_PATH_SPACE_NODE_WITH_HIGHLIGHT,
  [PARAM_LINK_TYPE.SPACE]: Constant.ROUTER_PATH_SPACE_WITH_PRESENTATION,
  [PARAM_LINK_TYPE.SPACE_ACCESS_TYPE]: Constant.ROUTER_PATH_SPACE_WITH_PRESENTATION,
  [PARAM_LINK_TYPE.SECTION]: Constant.ROUTER_PATH_SPACE_WITH_HIGHLIGHTED_NODE,
  [PARAM_LINK_TYPE.SPACE_LIST]: Constant.ROUTER_PATH_SPACES_LIST,
  [PARAM_LINK_TYPE.SPACE_PROPOSAL]: Constant.ROUTER_PATH_SPACE_ACTION_LOGS,
  [PARAM_LINK_TYPE.SPACE_JOIN_REQUEST]: Constant.ROUTER_PATH_SPACE_ACTION_LOGS,
  [PARAM_LINK_TYPE.SPACE_WITH_ADMIN_INVITATION]: Constant.ROUTER_PATH_SPACE_WITH_PRESENTATION,
  [PARAM_LINK_TYPE.SPACE_WITH_PIONEER_MODAL]: Constant.ROUTER_PATH_SPACE_WITH_PRESENTATION,
  [PARAM_LINK_TYPE.SPACE_ADMIN_NOTE]: Constant.ROUTER_PATH_SPACE_ACTIVITY,
  [PARAM_LINK_TYPE.SPACE_WITH_LAUNCH_MODAL]: Constant.ROUTER_PATH_SPACE_WITH_PRESENTATION,
  [PARAM_LINK_TYPE.SPACE_WITH_PUBLISH_MODAL]: Constant.ROUTER_PATH_SPACE_WITH_PRESENTATION,
  [PARAM_LINK_TYPE.SPACE_USER]: Constant.ROUTER_PATH_SPACE_WITH_PRESENTATION,
  [PARAM_LINK_TYPE.MY_ACTIVITY]: Constant.ROUTER_PATH_MY_ACTIVITY,
  [PARAM_LINK_TYPE.SPACE_ACTIVITY]: Constant.ROUTER_PATH_SPACE_ACTIVITY,
  [PARAM_LINK_TYPE.POST_ACTIVITY]: Constant.ROUTER_PATH_SPACE_NODE_WITH_HIGHLIGHT,
};

const DIALOG_LINK_QUERY_MAP: Record<string, string> = {
  [PARAM_LINK_TYPE.SPACE_WITH_ADMIN_INVITATION]: Constant.DIALOGS.ADMIN_INVITATION,
  [PARAM_LINK_TYPE.SPACE_WITH_PIONEER_MODAL]: Constant.DIALOGS.FULL_PIONEER_MINTED,
};

export function generateUserNotificationUrl(
  actionType: ActionType | null,
  actionParams: ActionParams,
  presentation = Constant.SPACE_PRESENTATION.ALL,
  withHighlightUserId = true,
) {
  switch (actionType) {
    case ACTION_TYPE.SPACE_CREATE_REJECT:
      // if space rejected it should redirect to pending spaces page
      // return Constant.ROUTER_PATH_PENDING_SPACES_LIST;
      return undefined;

    case ACTION_TYPE.SPACE_INVITE:
      return parseTemplateString(Constant.ROUTER_PATH_SPACE_INVITES, {
        spaceId: actionParams.spaceUrl,
      });
  }

  const url = ROUTE_MAP[actionParams.linkType];

  if (!url) return undefined;

  switch (actionParams.linkType) {
    case PARAM_LINK_TYPE.NODE:
    case PARAM_LINK_TYPE.POST:
    case PARAM_LINK_TYPE.POST_ACTIVITY:
      return parseTemplateString(url, {
        spaceId: actionParams.spaceUrl,
        nodeId: actionParams.rootId,
        slug: generateSlug(actionParams.rootTitle),
        highlightId: actionParams.nodeId,
        presentation,
      });
    case PARAM_LINK_TYPE.SPACE:
    case PARAM_LINK_TYPE.SPACE_WITH_LAUNCH_MODAL:
    case PARAM_LINK_TYPE.SPACE_ADMIN_NOTE:
    case PARAM_LINK_TYPE.SPACE_ACCESS_TYPE:
    case PARAM_LINK_TYPE.SPACE_USER:
    case PARAM_LINK_TYPE.SPACE_WITH_PUBLISH_MODAL: {
      const params = {
        spaceId: actionParams.spaceUrl,
        presentation: Constant.SPACE_PRESENTATION.ALL,
      };

      // Notification related to user
      if (
        actionType &&
        ([ACTION_TYPE.NEW_MEMBER_JOIN, ACTION_TYPE.ASSIGN_BADGE_APPROVED] as ActionType[]).includes(actionType) &&
        actionParams.userId &&
        withHighlightUserId
      ) {
        return parseTemplateStringWithQueryString({
          template: url,
          params: params,
          queryString: {
            [Constant.QUERY_STRING_PARAMETER_KEY.USERID]: actionParams.userId,
          },
        });
      }

      return parseTemplateString(url, params);
    }
    case PARAM_LINK_TYPE.SPACE_PROFILE: {
      const params = {
        spaceId: actionParams.spaceUrl,
        presentation: Constant.SPACE_PRESENTATION.ALL,
      };

      return parseTemplateStringWithQueryString({
        template: url,
        params: params,
        queryString: {
          [Constant.QUERY_STRING_PARAMETER_KEY.SPACE_PROFILE]: "1",
        },
      });
    }
    case PARAM_LINK_TYPE.SPACE_ACTIVITY: {
      const params = {
        spaceId: actionParams.spaceUrl,
        presentation: Constant.SPACE_PRESENTATION.ALL,
      };
      return parseTemplateStringWithQueryString({
        template: url,
        params: params,
        queryString: {
          [HIGHLIGHT_QUERY_STRING]: actionParams.actionId || "",
        },
      });
    }
    case PARAM_LINK_TYPE.SECTION: {
      return parseTemplateString(url, { spaceId: actionParams.spaceUrl, highlightId: actionParams.nodeId });
    }
    // resubmission flow for space proposal
    case PARAM_LINK_TYPE.SPACE_PROPOSAL: {
      return parseTemplateString(url, {
        actionId: actionParams.spaceProposalId,
        actionType: SPACE_ACTION_TYPE.SPACE_PROPOSAL,
      });
    }
    // resubmission flow for membership request
    case PARAM_LINK_TYPE.SPACE_JOIN_REQUEST: {
      return parseTemplateString(url, {
        actionId: actionParams.actionId,
        actionType: SPACE_ACTION_TYPE.REQUEST_TO_JOIN,
      });
    }
    // Notification that involve dialog popup after opening the space
    case PARAM_LINK_TYPE.SPACE_WITH_ADMIN_INVITATION:
    case PARAM_LINK_TYPE.SPACE_WITH_PIONEER_MODAL: {
      return parseTemplateStringWithQueryString({
        template: url,
        params: {
          spaceId: actionParams.spaceUrl,
          presentation: Constant.SPACE_PRESENTATION.ALL,
        },
        queryString: {
          [Constant.QUERY_STRING_PARAMETER_KEY.DIALOG]: DIALOG_LINK_QUERY_MAP[actionParams.linkType],
        },
      });
    }
    case PARAM_LINK_TYPE.MY_ACTIVITY: {
      return parseTemplateStringWithQueryString({
        template: url,
        queryString: {
          [Constant.QUERY_STRING_PARAMETER_KEY.ACTION_ID]: actionParams.actionId || "",
        },
      });
    }
  }
}

export function setBrowserTabNotificationEnabled(enabled: boolean) {
  const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (favicon) {
    favicon.href = `${enabled ? Constant.APP_NOTIFICATION_ICON_PATH : Constant.APP_DEFAULT_ICON_PATH}?v=2`;
  }
}

export interface EncodedData {
  quotedText?: string;
  linkType: string;
  event: ConnectionStatus;
  relatedUserId: number; // id-nya user B
}

export function parseConnectionNotificationObject(text: string) {
  try {
    const data = htmlDecode(sanitize(text));
    return JSON.parse(data) as EncodedData;
  } catch (e) {
    captureException(e as ClientError);
    return {
      quotedText: "",
      linkType: "",
      event: "",
      relatedUserId: 0,
    };
  }
}

export function getVirtualizedUserNotificationType(
  userNotification: UserNotificationModel,
): VirtualizedUserNotificationItemType {
  if (userNotification.actionSpec.actionType === ACTION_TYPE.USER_CONNECTION) {
    return VIRTUALIZED_USER_NOTIFICATION_ITEM_TYPE.USER_CONNECTION;
  }

  return VIRTUALIZED_USER_NOTIFICATION_ITEM_TYPE.DEFAULT;
}
