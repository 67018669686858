import type { IssueNudge } from "@/src/domains/content/types/ContentAPITypes";
import type { StanceKey } from "@/src/domains/content/types/Node";
import type { MultimediaSpec, SourceSpec } from "@/src/domains/dynamic-form/types/FormValues";
import type { DraftConfig } from "@/src/pages/SpaceLandingPage/utils/FormActionUtils";

export type PreviewPostButtonState = "SUBMIT" | "PREVIEW";

interface PreviewValue {
  value: string;
  multimediaSpec: MultimediaSpec[];
  sourceSpec: SourceSpec[];
}

export interface TemporaryValueValues {
  [key: string]: {
    data: { posts?: PreviewValue[]; topic?: PreviewValue; value?: string; title?: string };
    type: string;
  };
}
export interface TemporaryValue {
  isDirty: boolean;
  isValid: boolean;
  values: TemporaryValueValues;
}

export interface PreviewPostDetail {
  type?: string;
  value?: PreviewValue;
  posts?: { typeValue: string; value: PreviewValue }[];
  topic?: PreviewValue;
  // Temporary value for submitting the form
  temporaryValue?: TemporaryValue;
  temporaryConfig?: DraftConfig;
  formConfig?: string;
  responseId?: string;
  textDiff?: string;
  titleDiff?: string;
}

export interface PreviewPostData {
  [nodeId: string]: PreviewPostDetail;
}

export interface PreviewPostConfig {
  buttonState: PreviewPostButtonState;
}

export interface PreviewPostResult {
  [nodeId: string]: {
    suggestedPostType: string;
    postTypeOptions: PostTypeOption[];
    issueTagOptions: IssueTagOption[];
    isFetching: boolean;
    nudges: PreviewPostNudge[];
    issues?: IssueNudge[];
    textDiff?: string;
    titleDiff?: string;
    stanceKey?: StanceKey;
  };
}

export interface PreviewPostState {
  previewPost: PreviewPostData;
  previewPostConfig: PreviewPostConfig;
  previewPostResult: PreviewPostResult;
}

export interface PostTypeOption {
  optionId: string;
  optionDisplayText: string;
  optionTheme: string;
  defaultStanceKey?: StanceKey;
  stanceKeys?: StanceKey[];
  optionDescription?: string;
  description?: string;
}

export interface IssueTagOption {
  optionId: string;
  optionDisplayText: string;
  optionDescription?: string;
}

export interface PreviewPostNudge {
  items: string[];
  title: string;
  type: string;
  text: string;
}

const PreviewPostInitialState: PreviewPostState = {
  previewPost: {},
  previewPostConfig: {
    buttonState: "PREVIEW",
  },
  previewPostResult: {},
};

export default PreviewPostInitialState;
