import { useCallback } from "react";

import Cookies from "js-cookie";

import { ROUTER_PATH_LOGIN, ZE_CLOSE_ACTION, ZE_LOGOUT_ACTION, ZE_WEB_WIDGET } from "@/src/constants/AppConstant";
import { COOKIE_SESSION_ID } from "@/src/constants/CookieConstants";
import { sliceName as userSettingSliceName } from "@/src/domains/user/components/UserSettings/state/userSettingsSlice";
import * as PubSubService from "@/src/hooks/pubsub/PubSubService";
import { persistor } from "@/src/stores/createStore";
import { removeUserCookies } from "@/src/stores/user/slice";
import { getPersistLocalStorageDataFromList, setPersistLocalStorageDataFromList } from "@/src/utils/redux/storeUtil";

// List of persist that will not be removed during logout / purge
const storeToPreserve = [userSettingSliceName];

export const useLogout = () => {
  return useCallback((options?: { preservePage?: boolean; redirectDestination?: string }) => {
    PubSubService.disconnect();

    if (window.zE) {
      // close and logout zendesk widget
      window.zE(ZE_WEB_WIDGET, ZE_CLOSE_ACTION);
      window.zE(ZE_WEB_WIDGET, ZE_LOGOUT_ACTION);
    }

    // persistor.purge will remove all persist data from localStorage, use this to preserve some data
    const storeToRestoreAfterPurge = getPersistLocalStorageDataFromList(storeToPreserve);

    persistor
      .purge()
      .then(() => {
        setPersistLocalStorageDataFromList(storeToRestoreAfterPurge);
        removeUserCookies();
        // After logout remove cookie for session id for re-generating new session id
        Cookies.remove(COOKIE_SESSION_ID);
        if (!options?.preservePage) {
          // To reset the history state and not include window.document.referrer
          // to avoid redirecting user to last visited URL after logout
          window.history.replaceState({}, "", options?.redirectDestination || ROUTER_PATH_LOGIN);
          // Reload to home page so exit SPA app
          window.location.href = options?.redirectDestination || ROUTER_PATH_LOGIN;
        }
        window.location.reload();
      })
      .catch(e => {});
  }, []);
};

export default useLogout;
