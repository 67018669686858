import { createSlice } from "@reduxjs/toolkit";

import type { NotificationsState } from "@/src/stores/notifications/NotificationsInitialState";
import NotificationsInitialState from "@/src/stores/notifications/NotificationsInitialState";

interface Payload<T> {
  payload: T;
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: NotificationsInitialState,
  reducers: {
    updateNotifications: (state, { payload }: Payload<NotificationsState>) => {
      state.numOfActiveAction = payload.numOfActiveAction || 0;
      state.numOfActiveTask = payload.numOfActiveTask || 0;
      state.numOfActiveUserNotification = payload.numOfActiveUserNotification || 0;
      state.latestUserNotificationUpdatedAt = payload.latestUserNotificationUpdatedAt || 0;
    },
    addActiveActionNotification: () => {
      // @NOTE: Enable when BE is ready
      // state.numOfActiveAction++;
    },
    decreaseTaskCount: state => {
      if (state.numOfActiveTask > 0) {
        state.numOfActiveTask -= 1;
      }
    },
    clearActiveUserNotifications: state => {
      state.numOfActiveUserNotification = 0;
    },
  },
});
