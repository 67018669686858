import type { AnyAction, Reducer, ReducersMapObject, Slice } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

import { reducers as staticReducers } from "@/src/stores/rootReducer";

type ReplaceReducer<S> = (nextReducer: Reducer<S, AnyAction>) => void;

/**
 * Creates a reducer manager that can be used to add and remove reducers.
 * Reference:
 * https://redux.js.org/usage/code-splitting#using-a-reducer-manager (with some modifications to support RTK)
 * @param replaceReducer replaceReducer from store.replaceReducer
 */
export default function createReducerManager<S>(replaceReducer: ReplaceReducer<S>) {
  let reducers: ReducersMapObject = staticReducers;
  let dynamicReducers: ReducersMapObject = {};

  const addReducer = <S>(slice: Slice<S>) => {
    if (Object.prototype.hasOwnProperty.call(dynamicReducers, slice.name)) {
      throw new Error(`Reducer with the name '${slice.name}' already exists. Please use other reducer name`);
    }
    dynamicReducers = {
      ...dynamicReducers,
      [slice.name]: slice.reducer,
    };

    reducers = {
      ...reducers,
      dynamic: combineReducers(dynamicReducers),
    };

    replaceReducer(combineReducers(reducers));
  };

  return {
    getReducers: () => reducers,
    add: addReducer,
  };
}

export type ReducerManager = ReturnType<typeof createReducerManager>;
