import { createSlice } from "@reduxjs/toolkit";

import type { NodeState, SpaceUpdates } from "@/src/domains/update-indicator/states/UpdateIndicatorInitialState";
import UpdateIndicatorInitialState from "@/src/domains/update-indicator/states/UpdateIndicatorInitialState";
import type { UpdateIndicatorLocation } from "@/src/domains/update-indicator/types/UpdateIndicatorResponse";
import { merge } from "@/src/utils/general/ArrayUtil";

interface Payload<T> {
  payload: T;
}

interface StoreUpdateParams {
  updates: SpaceUpdates;
}

export interface AddToUpdateListParams {
  spaceId: number;
  updates: {
    nodeId: number;
    sectionId: number;
    locations: UpdateIndicatorLocation[];
    isImportant: boolean;
    unreadNodes?: number[];
  }[];
}

export interface RemoveFromUpdateListParams {
  spaceId: number;
  updates: {
    nodeId: number;
    sectionId: number;
    unreadNodes?: number[];
  }[];
}

export interface AddLocationToUpdateListParams {
  spaceId: number;
  updates: {
    nodeId: number;
    locations: UpdateIndicatorLocation[];
  }[];
}

export interface RemoveLocationFromUpdateListParams {
  spaceId: number;
  updates: {
    nodeId: number;
    locations: UpdateIndicatorLocation[];
  }[];
}

const UpdateIndicatorSlice = createSlice({
  name: "updateIndicator",
  initialState: UpdateIndicatorInitialState,
  reducers: {
    storeUpdates: (state, { payload }: Payload<StoreUpdateParams>) => {
      const { updates } = payload;

      Object.entries(updates).forEach((value: [string, NodeState[]]) => {
        const [id, nodes] = value;
        state.updates[Number(id)] = nodes;
      });
    },
    addToUpdateList: (state, { payload }: Payload<AddToUpdateListParams>) => {
      const { spaceId, updates } = payload;

      const nodes = state.updates[spaceId];
      if (nodes) {
        for (const update of updates) {
          const { nodeId, sectionId, locations, isImportant, unreadNodes } = update;

          let newUnreadNodes: number[];
          if (unreadNodes && unreadNodes.length > 0) {
            newUnreadNodes = unreadNodes;
          } else {
            newUnreadNodes = [nodeId];
          }

          const node = nodes.find(p => p.nodeId === nodeId);
          if (node) {
            node.sectionId = sectionId;
            node.locations = merge(node.locations, locations);
            node.isImportant = isImportant;
            node.unreadNodes = merge(node.unreadNodes, newUnreadNodes);
          } else {
            nodes.push({
              nodeId,
              sectionId,
              locations,
              isImportant,
              unreadNodes: newUnreadNodes,
            });
          }
        }

        state.updates[spaceId] = nodes;
      }
    },
    removeFromUpdateList: (state, { payload }: Payload<RemoveFromUpdateListParams>) => {
      const { spaceId, updates } = payload;

      const nodes = state.updates[spaceId];
      if (nodes) {
        for (const update of updates) {
          const { nodeId, unreadNodes } = update;

          const node = nodes.find(p => p.nodeId === nodeId);
          if (node) {
            let newUnreadNodes: number[];
            if (unreadNodes && unreadNodes.length > 0) {
              newUnreadNodes = node.unreadNodes.filter(p => !unreadNodes.includes(p));
            } else {
              newUnreadNodes = [];
            }

            if (newUnreadNodes.length > 0) {
              node.unreadNodes = newUnreadNodes;
            } else {
              const index = nodes.indexOf(node);
              nodes.splice(index, 1);
            }
          }
        }

        state.updates[spaceId] = nodes;
      }
    },
    addLocationToUpdateList: (state, { payload }: Payload<AddLocationToUpdateListParams>) => {
      const { spaceId, updates } = payload;

      const nodes = state.updates[spaceId];
      if (nodes) {
        for (const update of updates) {
          const { nodeId, locations } = update;

          const node = nodes.find(p => p.nodeId === nodeId);
          if (node) {
            node.locations = merge(node.locations, locations);
          }
        }

        state.updates[spaceId] = nodes;
      }
    },
    removeLocationFromUpdateList: (state, { payload }: Payload<RemoveLocationFromUpdateListParams>) => {
      const { spaceId, updates } = payload;

      const nodes = state.updates[spaceId];
      if (nodes) {
        for (const update of updates) {
          const { nodeId, locations } = update;

          const node = nodes.find(p => p.nodeId === nodeId);
          if (node) {
            node.locations = node.locations.filter(p => !locations.includes(p));
          }
        }

        state.updates[spaceId] = nodes;
      }
    },
  },
});

export default UpdateIndicatorSlice;
