import { createSlice } from "@reduxjs/toolkit";

import type { CompactReviewedTask } from "@/src/domains/liquidity/components/MyReviewedTaskList/states/MyReviewedTasksInitialState";
import MyReviewedTasksInitialState from "@/src/domains/liquidity/components/MyReviewedTaskList/states/MyReviewedTasksInitialState";
import type { ConsensusTask } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface MyReviewedTasksResult {
  sequences: CompactReviewedTask[];
  tasks: Record<number, ConsensusTask>;
}

function parseMyReviewedTasks(data: ConsensusTask[]): MyReviewedTasksResult {
  const sequences: CompactReviewedTask[] = [];
  const tasks: Record<number, ConsensusTask> = {};

  for (const task of data) {
    const { id, submittedAt, state } = task;
    sequences.push({ taskId: id, submittedAt, state });
    tasks[id] = task;
  }

  return {
    sequences,
    tasks,
  };
}

interface StoreParams {
  data: ConsensusTask[];
  nextPageToken?: string;
}

interface AppendParams {
  data: ConsensusTask[];
  nextPageToken?: string;
}

export const myReviewedTasksSlice = createSlice({
  name: "myReviewedTasks",
  initialState: MyReviewedTasksInitialState,
  reducers: {
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { data, nextPageToken } = payload;

      const { sequences, tasks } = parseMyReviewedTasks(data);

      state.sequences = sequences;
      state.tasks = tasks;
      state.nextPageToken = nextPageToken;
      state.endReached = !nextPageToken;
    },
    append: (state, { payload }: ReducerPayload<AppendParams>) => {
      const { data, nextPageToken } = payload;

      const { sequences, tasks } = parseMyReviewedTasks(data);

      state.sequences = [...state.sequences, ...sequences];
      state.tasks = { ...state.tasks, ...tasks };
      state.nextPageToken = nextPageToken;
      state.endReached = !nextPageToken;
    },
    reset: () => {
      return MyReviewedTasksInitialState;
    },
  },
});
