import { createSlice } from "@reduxjs/toolkit";

import tutorial from "@/src/translations/en/tutorial.json";

// The type of topic should match the tutorial.json file if not it will cause error when do the i18n mapping
export type topic = keyof typeof tutorial.topics;

export const TUTORIAL_CARD_TOPIC: Record<topic, topic> = Object.keys(tutorial.topics).reduce(
  (prev, key) => {
    prev[key as topic] = key as topic;
    return prev;
  },
  {} as Record<topic, topic>,
);

interface State {
  isOpened: boolean;
  topic: null | topic;
}

interface Payload {
  topic: topic;
}

const REDUCER_NAME = "tutorialCard";

const InitialState: State = {
  isOpened: false,
  topic: null,
};

export const tutorialCardSlice = createSlice({
  name: REDUCER_NAME,
  initialState: InitialState,
  reducers: {
    open: (state, { payload }: { payload: Payload }) => {
      state.isOpened = true;
      state.topic = payload.topic;
    },
    close: state => {
      state.isOpened = false;
      state.topic = null;
    },
  },
});
