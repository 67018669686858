import { createSlice } from "@reduxjs/toolkit";

import UserNotificationInitialState from "@/src/domains/liquidity/components/UserNotification/states/UserNotificationInitialState";
import type { UserNotificationModel } from "@/src/domains/liquidity/components/UserNotification/types/UserNotificationApiTypes";
import { USER_NOTIFICATION_STATUS } from "@/src/domains/liquidity/components/UserNotification/types/UserNotificationApiTypes";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface StoreParams {
  userNotifications: UserNotificationModel[];
  currentVersion?: string;
  nextPageToken?: string;
  clearExisting?: boolean;
}

interface MarkAsReadParams {
  id?: string;
}

export const userNotificationSlice = createSlice({
  name: "userNotification",
  initialState: UserNotificationInitialState,
  reducers: {
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { userNotifications, currentVersion, nextPageToken, clearExisting } = payload;

      let updatedUserNotifications = userNotifications;

      if (!clearExisting) {
        updatedUserNotifications = [...state.userNotifications];

        for (const userNotification of userNotifications) {
          const index = updatedUserNotifications.findIndex(p => p.id === userNotification.id);

          if (index >= 0) {
            updatedUserNotifications[index] = userNotification;
          } else {
            updatedUserNotifications.push(userNotification);
          }
        }
      }

      updatedUserNotifications = updatedUserNotifications.sort((a, b) => b.timestamp - a.timestamp);

      let updatedNextPageToken = state.nextPageToken;
      if (nextPageToken) {
        updatedNextPageToken = nextPageToken;
      }

      return {
        ...state,
        userNotifications: updatedUserNotifications,
        currentVersion,
        nextPageToken: updatedNextPageToken,
      };
    },
    setEndReached: state => {
      return {
        ...state,
        endReached: true,
      };
    },
    markAllAsNotNew: state => {
      for (let i = 0; i < state.userNotifications.length; i++) {
        const currentUserNotification = state.userNotifications[i];
        if (currentUserNotification.status === USER_NOTIFICATION_STATUS.NEW) {
          state.userNotifications[i] = { ...currentUserNotification, status: USER_NOTIFICATION_STATUS.UNREAD };
        }
      }
    },
    markAsRead: (state, { payload }: ReducerPayload<MarkAsReadParams>) => {
      const { id } = payload;

      if (id) {
        const index = state.userNotifications.findIndex(p => p.id === id);
        if (index >= 0) {
          state.userNotifications[index] = { ...state.userNotifications[index], status: USER_NOTIFICATION_STATUS.READ };
        }
      } else {
        for (let i = 0; i < state.userNotifications.length; i++) {
          state.userNotifications[i] = { ...state.userNotifications[i], status: USER_NOTIFICATION_STATUS.READ };
        }
      }
    },
    reset: () => {
      return UserNotificationInitialState;
    },
  },
});
