import { createSlice } from "@reduxjs/toolkit";

import TocScrollInitialState from "@/src/domains/content/states/TocScroll/TocScrollInitialState";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface SetParams {
  spaceId: number;
  offset: number;
}

export const tocScrollSlice = createSlice({
  name: "tocScroll",
  initialState: TocScrollInitialState,
  reducers: {
    set: (state, { payload }: ReducerPayload<SetParams>) => {
      const { spaceId, offset } = payload;
      state[spaceId] = { offset };
    },
    reset: () => {
      return TocScrollInitialState;
    },
  },
});
