import { createSlice } from "@reduxjs/toolkit";

import type { ActionGroupId, ActionType } from "@/src/domains/types/NodeActionType";

const MAX_FLOATING_DRAFT_COUNT = 2;

export type FloatingDraftEntry = {
  nodeId: string;
  rootNodeId?: string;
  spaceId: number;
  spaceUrl: string;
  isOpen: boolean;
  actionGroupId: ActionGroupId;
  actionType: ActionType;
  /** Store the anchor position for handling add new post between wiki nodes */
  wikiAnchorNodeId?: string;
};

type ReducerState = FloatingDraftEntry[];

export interface SelectorState {
  floatingDraftSpace: ReducerState;
}

const initialState = [] as ReducerState;

interface AddFloatingDraftParams extends Omit<FloatingDraftEntry, "isOpen"> {
  wikiAnchorNodeId?: string;
}
interface RemoveFloatingDraftParams extends Pick<FloatingDraftEntry, "nodeId"> {}
interface TogglePopupFloatingDraftParams extends Pick<FloatingDraftEntry, "nodeId" | "isOpen"> {}

export const floatingDraftSpace = createSlice({
  name: "floatingDraftSpace",
  initialState,
  reducers: {
    addFloatingDraft: (state, { payload }: { payload: AddFloatingDraftParams }) => {
      const isQueued = state.find(entry => entry.nodeId === payload.nodeId);
      if (isQueued) return state;

      const newFloatingDraftEntry: FloatingDraftEntry = {
        ...payload,
        isOpen: false,
      };

      const updatedState = [...state];
      updatedState.push(newFloatingDraftEntry);
      if (updatedState.length > MAX_FLOATING_DRAFT_COUNT) {
        return updatedState.slice(-MAX_FLOATING_DRAFT_COUNT);
      } else {
        return updatedState;
      }
    },
    removeFloatingDraftEntry: (state, { payload }: { payload: RemoveFloatingDraftParams }) => {
      const { nodeId } = payload;
      return state.filter(entry => entry.nodeId !== nodeId);
    },
    toggleDraftPopup: (state, { payload }: { payload: TogglePopupFloatingDraftParams }) => {
      const { nodeId, isOpen } = payload;
      const index = state.findIndex(entry => entry.nodeId === nodeId);
      if (index >= 0) {
        state[index].isOpen = isOpen;
      }
    },
    closeAllDraftPopup: state => {
      for (let i = 0; i < state.length; i++) {
        state[i].isOpen = false;
      }
    },
  },
});
