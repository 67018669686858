import { createSlice } from "@reduxjs/toolkit";

import { OPEN_VOTE_LIST_TASK_DIALOG } from "@/src/constants/AppConstant";
import type { SpaceCanonicalId } from "@/src/domains/space/models/types";
import type { TaskType } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import { TASK_TYPE } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

type State = {
  myTask: {
    isOpen: boolean;
    spaceCanonicalId: SpaceCanonicalId;
    taskType: TaskType;
    taskStatus: string; // TODO: refactor data slice to include taskStatus
  };
  myTaskReview: {
    isOpen: boolean;
    nodeId: string;
    spaceCanonicalId: SpaceCanonicalId;
    step: string;
  };
  fullPioneerMinted: boolean;
  adminInvitation: boolean;
  votableConsensusTask: boolean;
};

const initialState: State = {
  myTask: {
    isOpen: false,
    spaceCanonicalId: "",
    taskType: TASK_TYPE.NORMAL,
    taskStatus: "", // TODO: refactor data slice to include taskStatus
  },
  myTaskReview: {
    isOpen: false,
    nodeId: "",
    spaceCanonicalId: "",
    step: OPEN_VOTE_LIST_TASK_DIALOG,
  },
  fullPioneerMinted: false,
  adminInvitation: false,
  votableConsensusTask: false,
};

const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    openMyTask(state) {
      state.myTask.isOpen = true;
    },
    closeMyTask(state) {
      state.myTask.isOpen = false;
    },
    updateMyTaskState(
      state,
      { payload }: ReducerPayload<{ spaceCanonicalId: SpaceCanonicalId; taskType: TaskType; taskStatus: string }>,
    ) {
      state.myTask.spaceCanonicalId = payload.spaceCanonicalId;
      state.myTask.taskType = payload.taskType;
      state.myTask.taskStatus = payload.taskStatus;
    },
    openFullPioneerMintedModal(state) {
      state.fullPioneerMinted = true;
    },
    closeFullPioneerMintedModal(state) {
      state.fullPioneerMinted = false;
    },
    openAdminInvitationModal(state) {
      state.adminInvitation = true;
    },
    closeAdminInvitationModal(state) {
      state.adminInvitation = false;
    },
  },
});

export default dialogsSlice;
