import { createSlice } from "@reduxjs/toolkit";

import { merge } from "@/src/utils/general/ArrayUtil";

type CleanNodeId = string;
type SpaceId = number;
type OrderType = "NEXT_NODE_ID" | "PREVIOUS_NODE_ID";

export type SectionNavigationTag = "sectionNavigation" | "tocReorderedNavigation";

export interface SectionNavigationParams {
  spaceUrl: string;
  spaceId: number;
  sectionId: number;
  isPartition?: boolean;
  isInstant?: boolean;
  tag?: SectionNavigationTag;
  withHighlight?: boolean;
  isSpaceLounge?: boolean;
}

export interface SectionState {
  nodeId: string;
  hasInitialFetch: boolean;
  isLoading: boolean;
  children: Array<string>;
  nextPageToken: string | undefined;
  hasMorePages: boolean;
  isPartition: boolean;
}

export type SectionStateInSpace = Record<string, SectionState>;

export type State = {
  sectionStatesInSpace: Record<SpaceId, SectionStateInSpace>;
  pendingSectionNavigation: SectionNavigationParams | null;
};

export interface SelectorState {
  sectionStates: State;
}

const initialState = { sectionStatesInSpace: {}, pendingSectionNavigation: null } as State;

export interface ToggleSectionStateLoadingParams {
  spaceId: number;
  sectionId: CleanNodeId;
  isLoading: boolean;
}

export interface StoreSectionStatesParams {
  spaceId: number;
  sectionStates: SectionStateInSpace;
}

export interface UpdateSectionStatesParams {
  spaceId: number;
  sectionStates?: Record<string, Partial<SectionState>>;
}

export interface MoveNodeBetweenSectionsParams {
  spaceId: number;
  nodeId: string;
  oldParentId: string;
  newParentId: string;
}

export interface ApplySectionMutationParams {
  spaceId: number;
  initialSectionStates?: Record<string, SectionState>;
  sectionStates?: Record<string, Partial<SectionState>>;
}

export type SetPendingSectionNavigationParams = SectionNavigationParams | null;

export interface DeleteSectionStateParams {
  spaceId: number;
  sectionIds: CleanNodeId[];
}

export interface UpdateNextPageTokenParams {
  spaceId: number;
  sectionId: CleanNodeId;
  nextPageToken?: string;
}

export interface DeleteSectionChildParams {
  spaceId: number;
  sectionId: CleanNodeId;
  nodeIds: string[];
}

export interface AddChildrenParams {
  spaceId: number;
  sectionId: CleanNodeId;
  nodeIds: CleanNodeId[];
}

export interface AddChildrenInMiddleParams {
  spaceId: number;
  sectionId: CleanNodeId;
  nodeIds: CleanNodeId[];
  /** nodeId of neighboring sibling to place the node */
  anchorId: CleanNodeId | null;
  addOrderType: OrderType;
}

export interface ReorderChildParams {
  spaceId: number;
  movedNodeId: CleanNodeId;
  /** nodeId of neighboring sibling to place the node */
  anchorId: CleanNodeId;
  sourceParentId: CleanNodeId;
  targetParentId: CleanNodeId;
  reorderType: OrderType;
}

export interface PubsubReorderChildParams {
  spaceId: number;
  movedNodeId: CleanNodeId;
  sectionId: CleanNodeId;
  prevNodeId: CleanNodeId;
  nextNodeId: CleanNodeId;
}

interface Payload<T> {
  payload: T;
}

export const sectionStates = createSlice({
  name: "sectionStates",
  initialState,
  reducers: {
    storeSectionStates: (state, { payload: { spaceId, sectionStates } }: Payload<StoreSectionStatesParams>) => {
      if (!state.sectionStatesInSpace[spaceId]) {
        state.sectionStatesInSpace[spaceId] = {};
      }
      state.sectionStatesInSpace[spaceId] = { ...state.sectionStatesInSpace[spaceId], ...sectionStates };
    },
    updateSectionStates: (state, { payload: { spaceId, sectionStates } }: Payload<UpdateSectionStatesParams>) => {
      if (sectionStates && state.sectionStatesInSpace[spaceId] && Object.keys(sectionStates).length > 0) {
        Object.entries(sectionStates).forEach(([sectionId, sectionState]) => {
          const existingSectionState = state.sectionStatesInSpace[spaceId][sectionId];
          state.sectionStatesInSpace[spaceId][sectionId] = { ...existingSectionState, ...sectionState };
        });
      }
    },
    moveNodeBetweenSections: (state, { payload }: Payload<MoveNodeBetweenSectionsParams>) => {
      const { spaceId, nodeId, oldParentId, newParentId } = payload;
      if (state.sectionStatesInSpace[spaceId]) {
        const oldParentSection = state.sectionStatesInSpace[spaceId][oldParentId];
        if (oldParentSection) {
          const oldSectionStateChildren = oldParentSection.children || [];
          const updatedOldChildren = oldSectionStateChildren.filter(childId => childId !== nodeId);
          state.sectionStatesInSpace[spaceId][oldParentId].children = updatedOldChildren;
        }

        const targetParent = state.sectionStatesInSpace[spaceId][newParentId];
        if (targetParent) {
          const newSectionStateChildren = targetParent.children || [];
          const updatedNewChildren = newSectionStateChildren.concat(nodeId);
          state.sectionStatesInSpace[spaceId][newParentId].children = updatedNewChildren;
        }
      }
    },
    /** Mirrorring previous action to update data after section mutation */
    applySectionMutation: (state, { payload }: Payload<ApplySectionMutationParams>) => {
      const { spaceId, initialSectionStates, sectionStates } = payload;

      const existingSectionStates = state.sectionStatesInSpace[spaceId];
      const updatedSectionStates = { ...initialSectionStates, ...existingSectionStates };
      if (sectionStates) {
        for (const [nodeId, state] of Object.entries(sectionStates)) {
          updatedSectionStates[nodeId] = { ...updatedSectionStates[nodeId], ...state };
        }
      }

      state.sectionStatesInSpace[spaceId] = updatedSectionStates;
    },
    setPendingSectionNavigation: (state, { payload }: Payload<SetPendingSectionNavigationParams>) => {
      if (!payload) {
        state.pendingSectionNavigation = null;
        return;
      }
      state.pendingSectionNavigation = payload;
    },
    deleteSectionStates: (state, { payload: { spaceId, sectionIds } }: Payload<DeleteSectionStateParams>) => {
      if (!state.sectionStatesInSpace[spaceId]) {
        state.sectionStatesInSpace[spaceId] = {};
      }

      for (let i = 0; i < sectionIds.length; i++) {
        const sectionId = sectionIds[i];
        delete state.sectionStatesInSpace[spaceId][sectionId];
      }
    },
    updateNextPageToken: (state, { payload }: Payload<UpdateNextPageTokenParams>) => {
      const { spaceId, sectionId, nextPageToken } = payload;
      if (state.sectionStatesInSpace[spaceId]?.[sectionId]) {
        state.sectionStatesInSpace[spaceId][sectionId].nextPageToken = nextPageToken;
        state.sectionStatesInSpace[spaceId][sectionId].hasMorePages = Boolean(nextPageToken);
      }
    },
    toggleSectionLoading: (state, { payload }: Payload<ToggleSectionStateLoadingParams>) => {
      const { spaceId, sectionId, isLoading } = payload;
      if (state.sectionStatesInSpace[spaceId] && state.sectionStatesInSpace[spaceId][sectionId]) {
        state.sectionStatesInSpace[spaceId][sectionId].isLoading = isLoading;
      }
    },
    deleteSectionChild: (state, { payload: { spaceId, sectionId, nodeIds } }: Payload<DeleteSectionChildParams>) => {
      if (state.sectionStatesInSpace[spaceId][sectionId]) {
        for (let i = 0; i < nodeIds.length; i++) {
          const deletedNodeId = nodeIds[i];
          const currentChildren = state.sectionStatesInSpace[spaceId][sectionId].children || [];
          state.sectionStatesInSpace[spaceId][sectionId].children = currentChildren.filter(
            existingId => deletedNodeId !== existingId,
          );
        }
      }
    },
    addSectionNextPageChildren: (state, { payload: { spaceId, sectionId, nodeIds } }: Payload<AddChildrenParams>) => {
      if (state.sectionStatesInSpace[spaceId]?.[sectionId]) {
        const currentChildren = state.sectionStatesInSpace[spaceId][sectionId].children || [];
        state.sectionStatesInSpace[spaceId][sectionId].children = merge(currentChildren, nodeIds);
      }
    },
    prependSectionChildren: (state, { payload: { spaceId, sectionId, nodeIds } }: Payload<AddChildrenParams>) => {
      const currentChildren = state.sectionStatesInSpace[spaceId][sectionId].children || [];
      const appliedNodeIds = nodeIds.filter(nodeId => !currentChildren.includes(nodeId));
      state.sectionStatesInSpace[spaceId][sectionId].children = appliedNodeIds.concat(currentChildren);
    },
    addSectionChildrenInMiddle: (state, { payload }: Payload<AddChildrenInMiddleParams>) => {
      const { spaceId, sectionId, nodeIds, anchorId, addOrderType } = payload;
      const currentChildren = state.sectionStatesInSpace[spaceId][sectionId]?.children || [];
      let anchorIndexPosition = currentChildren.findIndex(nodeId => nodeId === anchorId);
      if (anchorId && addOrderType === "PREVIOUS_NODE_ID") {
        anchorIndexPosition += 1;
      }

      const appliedAnchorIndex = Math.max(anchorIndexPosition, 0);
      for (let i = 0; i < nodeIds.length; i++) {
        const nodeId = nodeIds[i];
        if (!currentChildren.includes(nodeId)) {
          currentChildren.splice(appliedAnchorIndex, 0, nodeId);
        }
      }

      state.sectionStatesInSpace[spaceId][sectionId].children = currentChildren;
    },
    reorderSectionChildren: (state, { payload }: Payload<ReorderChildParams>) => {
      const { spaceId, movedNodeId, anchorId, reorderType, sourceParentId, targetParentId } = payload;
      const sourceSectionState = state.sectionStatesInSpace[spaceId][sourceParentId];
      const targetSectionState = state.sectionStatesInSpace[spaceId][targetParentId];

      // (1) Remove child from source parent section
      if (sourceSectionState) {
        const sourceSectionChildren = sourceSectionState.children;
        const sourceIndexPosition = sourceSectionChildren.findIndex((id: string) => id === movedNodeId);
        sourceSectionChildren.splice(sourceIndexPosition, 1);
        state.sectionStatesInSpace[spaceId][sourceParentId].children = sourceSectionChildren;
      }

      // (2) Add child to target parent section, can be the same as source parent section
      if (targetSectionState) {
        const targetSectionChildren = targetSectionState.children || [];
        const targetAnchorPositionIndex = targetSectionChildren.findIndex((id: string) => id === anchorId);
        const reorderDelta = reorderType === "NEXT_NODE_ID" ? 0 : 1;
        const movedPositionIndex = targetAnchorPositionIndex + reorderDelta;
        targetSectionChildren.splice(movedPositionIndex, 0, movedNodeId);
        state.sectionStatesInSpace[spaceId][targetParentId].children = targetSectionChildren;
      }
    },
    pubsubReorderSectionChildren: (state, { payload }: Payload<PubsubReorderChildParams>) => {
      const { spaceId, movedNodeId, nextNodeId, prevNodeId, sectionId } = payload;
      const existingParentState = state.sectionStatesInSpace[spaceId][sectionId];

      if (!existingParentState) {
        return;
      }

      // (1) Remove child from source parent section
      let sectionIdToRemoveChild = existingParentState.nodeId;
      const isPreviouslyOnThisSection = existingParentState.children?.findIndex(nodeId => nodeId === movedNodeId) >= 0;
      if (!isPreviouslyOnThisSection && existingParentState.children) {
        const previousSection = Object.values(state.sectionStatesInSpace[spaceId]).find(
          (sectionState: SectionState) => {
            if (sectionState.children) {
              return sectionState.children.includes(movedNodeId);
            }
            return false;
          },
        );
        if (previousSection) {
          sectionIdToRemoveChild = previousSection.nodeId;
        }
      }

      const sectionChildren = state.sectionStatesInSpace[spaceId][sectionIdToRemoveChild].children || [];
      const nodeIndexPosition = sectionChildren.findIndex(nodeId => nodeId === movedNodeId);
      const isPrevNodeChanged = sectionChildren[nodeIndexPosition - 1] !== prevNodeId;
      const isNextNodeChanged = sectionChildren[nodeIndexPosition + 1] !== nextNodeId;

      if (isPrevNodeChanged && isNextNodeChanged) {
        const updatedChildren = state.sectionStatesInSpace[spaceId][sectionIdToRemoveChild].children?.filter(
          nodeId => nodeId !== movedNodeId,
        );
        state.sectionStatesInSpace[spaceId][sectionIdToRemoveChild].children = updatedChildren;
      } else {
        return;
      }

      // (2) Add child to target parent section, can be the same as source parent section based on prevNodeId
      const sectionToAddChild = state.sectionStatesInSpace[spaceId][sectionId];
      const targetSectionChildren = sectionToAddChild.children || [];
      let anchorIndex = targetSectionChildren.findIndex((nodeId: string) => nodeId === prevNodeId);

      //// (2a) if prevNodeId is not found (maybe not yet inserted), use nextNodeId as anchor
      if (anchorIndex < 0) {
        anchorIndex = targetSectionChildren.findIndex((nodeId: string) => nodeId === nextNodeId);
      } else {
        //// (2b) if prevNodeId found, increment by one to reorder nodeId after the anchor
        anchorIndex += 1;
      }

      // put the reordered node id to its place
      targetSectionChildren.splice(Math.max(anchorIndex, 0), 0, movedNodeId);
      state.sectionStatesInSpace[spaceId][sectionId].children = targetSectionChildren;
    },
    deleteSectionStateInSpace: (state, { payload: { spaceId } }) => {
      delete state.sectionStatesInSpace[spaceId];
    },
  },
});
