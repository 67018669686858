import { createSlice } from "@reduxjs/toolkit";

import type { UserCookiePreferenceValues } from "@/src/domains/user/components/UserSettings/constant/UserSettingsConstant";

export const sliceName = "userSettings";

interface UserSettingState {
  cookiePreferences: UserCookiePreferenceValues;
  hasSyncWithUserSettingsBackend: boolean;
}

const initialState: UserSettingState = {
  cookiePreferences: "",
  hasSyncWithUserSettingsBackend: false,
};

const userSettingsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCookiePreferences: (state, { payload }: { payload: UserCookiePreferenceValues }) => {
      state.cookiePreferences = payload;
    },
    // Separate action since user might update cookie when not logged in
    setHasSyncWithUserSettingsBackend: (state, { payload }: { payload: boolean }) => {
      state.hasSyncWithUserSettingsBackend = payload;
    },
    setManual: (state, { payload }: { payload: UserSettingState }) => {
      state.cookiePreferences = payload.cookiePreferences;
      state.hasSyncWithUserSettingsBackend = payload.hasSyncWithUserSettingsBackend;
    },
  },
});

export const userCookiePreferencesSelector = (state: { userSettings: UserSettingState }) =>
  state.userSettings.cookiePreferences;

export default userSettingsSlice;
