import { createSlice } from "@reduxjs/toolkit";

import type { CleanNodeId } from "@/src/domains/content/types/Node";

type SpaceId = number;

interface AuthoredFeedState {
  oldUpdateSequences: CleanNodeId[];
  newUpdateSequences: CleanNodeId[];
  nextPageToken?: string;
  isNoMoreData: boolean;
}

type ReducerState = Record<SpaceId, AuthoredFeedState>;

export interface SelectorState {
  spaceAuthoredFeeds: ReducerState;
}

const initialState = {} as ReducerState;

const INITIAL_AUTHORED_FEED: AuthoredFeedState = {
  oldUpdateSequences: [],
  newUpdateSequences: [],
  isNoMoreData: false,
};

interface StoreAuthoredFeedsParam extends Omit<AuthoredFeedState, "isNoMoreData"> {
  spaceId: number;
  isFirstPage: boolean;
}

interface ToggleNoMoreDataParams {
  spaceId: number;
  isNoMoreData: boolean;
}

export const spaceAuthoredFeeds = createSlice({
  name: "spaceAuthoredFeeds",
  initialState,
  reducers: {
    storeAuthoredFeeds: (state, { payload }: { payload: StoreAuthoredFeedsParam }) => {
      const { spaceId, newUpdateSequences, oldUpdateSequences, isFirstPage, nextPageToken } = payload;

      const updatedFeedState = { ...(state[spaceId] ?? INITIAL_AUTHORED_FEED) };

      if (isFirstPage) {
        updatedFeedState.newUpdateSequences = newUpdateSequences;
        updatedFeedState.oldUpdateSequences = oldUpdateSequences;
      } else {
        updatedFeedState.newUpdateSequences = state[spaceId].newUpdateSequences.concat(newUpdateSequences);
        updatedFeedState.oldUpdateSequences = state[spaceId].oldUpdateSequences.concat(oldUpdateSequences);
      }

      updatedFeedState.nextPageToken = nextPageToken;

      const payloadSequences = [...newUpdateSequences, ...oldUpdateSequences];
      const payloadLength = payloadSequences.length;
      updatedFeedState.isNoMoreData = payloadLength < 1 || !nextPageToken;

      state[spaceId] = updatedFeedState;
    },
    toggleNoMoreData: (state, { payload }: { payload: ToggleNoMoreDataParams }) => {
      const { spaceId, isNoMoreData } = payload;

      const updatedFeedState = { ...(state[spaceId] ?? INITIAL_AUTHORED_FEED) };
      updatedFeedState.isNoMoreData = isNoMoreData;
      state[spaceId] = updatedFeedState;
    },
    resetAuthoredFeed: (state, { payload }) => {
      const { spaceId } = payload;
      state[spaceId] = INITIAL_AUTHORED_FEED;
    },
  },
});
