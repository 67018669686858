export default {
  nonLoggedInUserBanner: {
    isShown: false,
    spaceName: undefined,
  },
  nonSpaceMemberBanner: {
    isShown: false,
    spaceName: undefined,
  },
};
