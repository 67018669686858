export enum TocHighlightTarget {
  DEFAULT = "DEFAULT",
  NODE = "NODE",
}

interface TocHighlightSpaceState {
  sectionId: number;
  target: TocHighlightTarget;
}

interface TocHighlightState {
  [spaceId: number]: TocHighlightSpaceState;
}

const TocHighlightInitialState: TocHighlightState = {};

export default TocHighlightInitialState;
