import Axios from "axios";
import Cookies from "js-cookie";

import { API_ENDPOINT_REFRESH_TOKEN, API_SERVICE_CODE_UNAUTHORIZED } from "@/src/constants/AppConstant";
import { COOKIE_SESSION_ID } from "@/src/constants/CookieConstants";
import { FETCH_ERROR } from "@/src/constants/ErrorConstant";
import { useLogout } from "@/src/hooks/useLogout";
import { slice as userSlice } from "@/src/stores/user";
import { getAPIBaseUrl } from "@/src/utils/APIUtil";

export interface GeneralAccessTokenErrorResponse {
  metadata?: {
    errorType?: string;
    invalidationReason?: string;
  };
}

type FiledQueueType = {
  resolve: (value: unknown) => void;
  reject: (value: unknown) => void;
};
let isRefreshing = false;
let failedQueue = [] as FiledQueueType[];

export const axios = Axios.create({
  baseURL: getAPIBaseUrl(),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processQueue = (error: any, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const extendSessionCookieExpiredTime = () => {
  if (typeof document !== "undefined") {
    const sessionId = Cookies.get(COOKIE_SESSION_ID) || "";
    if (sessionId) {
      // 30 days constant number for one months, similar to `middleware.ts`
      Cookies.set(COOKIE_SESSION_ID, sessionId, { expires: 30 });
    }
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAxiosInterceptor = (store: any) => {
  const logout = useLogout();
  axios.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config;

      if (error.response?.status === API_SERVICE_CODE_UNAUTHORIZED && !originalRequest._retry) {
        if (Axios.isAxiosError(error)) {
          const data = error.response?.data as GeneralAccessTokenErrorResponse | undefined;
          if (
            data?.metadata?.errorType === FETCH_ERROR.ACCESS_TOKEN_INVALID &&
            data?.metadata?.invalidationReason === FETCH_ERROR.USER_SUSPENDED
          ) {
            // User is suspended
            logout({ redirectDestination: "/" });
            return Promise.reject(error);
          }
        }
        const currentState = store.getState();
        const { refreshToken } = currentState.user;
        if (!refreshToken) return Promise.reject(error);

        if (isRefreshing) {
          // eslint-disable-next-line promise/no-promise-in-callback
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers["authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch(err => {
              // eslint-disable-next-line promise/no-return-wrap
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          // Refresh Token dont need authorization header
          axios.defaults.headers.common["authorization"] = "";
          // eslint-disable-next-line promise/catch-or-return, promise/no-promise-in-callback
          axios
            // Refresh token dont need header authorization
            .post(API_ENDPOINT_REFRESH_TOKEN, { token: refreshToken })
            .then(({ data }) => {
              store.dispatch(
                userSlice.actions.refreshToken({
                  accessToken: data.accessToken,
                  refreshToken: data.refreshToken,
                }),
              );

              axios.defaults.headers.common["authorization"] = "Bearer " + data.accessToken;
              originalRequest.headers["authorization"] = "Bearer " + data.accessToken;
              extendSessionCookieExpiredTime();
              processQueue(null, data.accessToken);
              resolve(axios(originalRequest));
            })
            .catch(err => {
              // Force logout when failed to fetch refresh token
              // if BE response 400 it means refresh token is invalid/expired
              // if not then it means BE is down / network error which we not force logout. User can try again in the next request
              if (err.response?.status === 400) {
                logout();
              }
              processQueue(err, null);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      return Promise.reject(error);
    },
  );
};

export default axios;
