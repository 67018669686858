import { createSlice } from "@reduxjs/toolkit";

import MyActivityInitialState from "@/src/domains/liquidity/components/MyActivityList/states/MyActivityInitialState";
import type { Activity } from "@/src/domains/liquidity/types/Activity";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface StoreParams {
  myActivities: Activity[];
}

export const myActivitySlice = createSlice({
  name: "myActivity",
  initialState: MyActivityInitialState,
  reducers: {
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { myActivities } = payload;
      return {
        ...state,
        myActivities,
        isFetched: true,
      };
    },
    reset: () => {
      return MyActivityInitialState;
    },
  },
});
