/* eslint-disable no-useless-escape */

// For validating email regex
export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const UrlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/;

export const URLRegexGlobal =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g;

export const UrlRegexWithoutProtocol =
  /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/;

// For parsing email regex
// Example: john.doe@example.com
export const GetEmailAddressRegex = /[\w\d.+-]+@[\w\d.-]+\.[\w\d.-]+/g;
// Example: <john.doe@example.com>
export const GetEmailWithBracketRegex = /<([\w\d.+-]+@[\w\d.-]+\.[\w\d.-]+)>/;
// Example: John Doe <john.doe@example.com>
export const GetCompleteEmailWithNameRegex = /(?:[\w\d.-]+(?:\s+[\w\d.-]+)*)?\s*<([\w\d.+-]+@[\w\d.-]+\.[\w\d.-]+)>/g;
