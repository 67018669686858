/* eslint-disable @typescript-eslint/no-explicit-any */
export function mergeTwoLevelDeep(obj1: Record<string, any>, obj2: Record<string, any>) {
  const merged: Record<string, any> = { ...obj1 }; // Shallow copy of obj1

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2 || {}, key)) {
      // If the property is an object and exists in both obj1 and obj2
      if (typeof obj2[key] === "object" && obj2[key] !== null && merged[key]) {
        // Perform a shallow merge on this property
        merged[key] = { ...merged[key], ...obj2[key] };
      } else {
        // Otherwise, just assign the value from obj2
        merged[key] = obj2[key];
      }
    }
  }

  return merged;
}
