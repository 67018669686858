import { createSlice } from "@reduxjs/toolkit";

import type { TocSettingsConfig } from "@/src/domains/content/components/Toc/props/TocSettingsApiProps";
import TocSettingsInitialState from "@/src/domains/content/states/TocSettings/TocSettingsInitialState";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface StoreParams {
  spaceId: number;
  settings: TocSettingsConfig;
}

export const tocSettingsSlice = createSlice({
  name: "tocSettings",
  initialState: TocSettingsInitialState,
  reducers: {
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { spaceId, settings } = payload;
      state[spaceId] = settings;
    },
    reset: () => {
      return TocSettingsInitialState;
    },
  },
});
