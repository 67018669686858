import {
  DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK,
  DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION,
  DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION,
  NODE_TYPE_NODE,
} from "@/src/constants/ContentConstants";
import type { DiscussionMapNodeRelations } from "@/src/domains/content/states/SpaceNodeRelations/DiscussionMapRelationsReducer";
import type { DiscussionMapNode, NodeChildrenInfoGroup, NodeObject } from "@/src/domains/content/types/Node";
import { getCleanNodeId } from "@/src/pages/SpaceLandingPage/utils/SpaceSectionListHelper";

export const INITIAL_DISCUSSION_VIEW_RELATION = {
  parentId: "",
  discussionMapParentId: "",
  childDivisions: [],
  childSubdivisions: [],
  childBlocks: [],
  structureType: "",
  rankingScore: 0,
  numOfDescendantBlocks: 0,
  contentGroupId: "",
};

export function constructDiscussionViewNodeRelationsFromNodes(nodes: Record<string, NodeObject>, rootNodeId?: string) {
  const nodeRelations: Record<string, DiscussionMapNodeRelations> = {};
  if (nodes && Object.keys(nodes).length > 0) {
    const nodeArray = Object.values(nodes);

    for (let i = 0; i < nodeArray.length; i++) {
      const node = nodeArray[i];

      if (node.isDeleted) continue;

      const { nodeComponentId, parentId, refParentId } = node;
      const { discussionMapStructureType, discussionMapRankingScore, contentGroupId } =
        node.contentDisplay || (node as DiscussionMapNode) || {};

      if (!nodeRelations[nodeComponentId]) {
        nodeRelations[nodeComponentId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
      }

      nodeRelations[nodeComponentId].parentId = parentId;

      if (!nodeRelations[parentId]) {
        nodeRelations[parentId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
      }

      if (node.type !== NODE_TYPE_NODE) continue;

      const discussionMapParentId = getCleanNodeId(refParentId).toString();

      nodeRelations[nodeComponentId].discussionMapParentId = discussionMapParentId;
      nodeRelations[nodeComponentId].structureType = discussionMapStructureType || "";
      nodeRelations[nodeComponentId].contentGroupId = contentGroupId || "";
      nodeRelations[nodeComponentId].rankingScore = discussionMapRankingScore || 0;

      if (discussionMapStructureType == "ROOT") {
        nodeRelations[nodeComponentId].discussionMapParentId = "0";
      }

      if (!nodeRelations[discussionMapParentId]) {
        nodeRelations[discussionMapParentId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
      }

      if (discussionMapStructureType == DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK) {
        nodeRelations[discussionMapParentId].childBlocks = [
          ...nodeRelations[discussionMapParentId].childBlocks,
          nodeComponentId,
        ];
      } else if (discussionMapStructureType == DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION) {
        nodeRelations[discussionMapParentId].childDivisions = [
          ...nodeRelations[discussionMapParentId].childDivisions,
          nodeComponentId,
        ];
      } else if (discussionMapStructureType == DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION) {
        nodeRelations[discussionMapParentId].childSubdivisions = [
          ...nodeRelations[discussionMapParentId].childSubdivisions,
          nodeComponentId,
        ];
      }
    }
  }

  !!rootNodeId && populateSubdivisions(rootNodeId, nodeRelations);

  return nodeRelations;
}

function getSubdivisionChildren(
  contentGroupId: NodeChildrenInfoGroup,
  node: DiscussionMapNodeRelations,
  nodeRelations: Record<string, DiscussionMapNodeRelations>,
) {
  if (!node) return [];

  const children = node.childBlocks.filter(child => nodeRelations[child].contentGroupId === contentGroupId);
  node.childBlocks = node.childBlocks.filter(child => nodeRelations[child].contentGroupId !== contentGroupId);
  children.forEach(child => {
    nodeRelations[child].structureType = DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION;
    nodeRelations[child].contentGroupId = contentGroupId;
  });
  return children;
}

// TODO: azmi
// Remove when this is moved to BE
function populateSubdivisions(nodeId: string, nodeRelations: Record<string, DiscussionMapNodeRelations>) {
  const node = nodeRelations[nodeId];
  if (node) {
    const openFeedbacks = getSubdivisionChildren("OPEN_FEEDBACK", node, nodeRelations);
    const closedFeedbacks = getSubdivisionChildren("CLOSED_FEEDBACK", node, nodeRelations);
    const replies = getSubdivisionChildren("REPLY", node, nodeRelations);
    node.childSubdivisions = [...openFeedbacks, ...closedFeedbacks, ...replies, ...node.childSubdivisions];
  }
}

export function findDeletedNodeIds(nodes: Record<string, NodeObject>) {
  return nodes
    ? Object.values(nodes)
        .filter(node => node && node.isDeleted)
        .map(node => node.nodeComponentId)
    : [];
}

export function constructInitialDiscussionMapNodeRelationsFromNodes(
  nodes: Record<string, DiscussionMapNode>,
  rootNodeId: string,
) {
  const nodeRelations: Record<string, DiscussionMapNodeRelations> = {};
  const nodeArray = Object.values(nodes);

  for (let i = 0; i < nodeArray.length; i++) {
    const node = nodeArray[i];
    const {
      nodeComponentId,
      childIdsByStructureType,
      discussionMapStructureType,
      discussionMapRankingScore,
      discussionMapParentId,
      numOfDescendantBlocks,
      contentGroupId,
    } = node;

    if (!nodeRelations[nodeComponentId]) {
      nodeRelations[nodeComponentId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
    }
    if (discussionMapStructureType == "ROOT") {
      nodeRelations[nodeComponentId].discussionMapParentId = "0";
    }
    nodeRelations[nodeComponentId].discussionMapParentId = discussionMapParentId || "";
    nodeRelations[nodeComponentId].structureType = discussionMapStructureType || "";
    nodeRelations[nodeComponentId].contentGroupId = contentGroupId || "";
    nodeRelations[nodeComponentId].rankingScore = discussionMapRankingScore || 0;
    nodeRelations[nodeComponentId].childDivisions =
      childIdsByStructureType?.[DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION] || [];
    nodeRelations[nodeComponentId].childSubdivisions =
      childIdsByStructureType?.[DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION] || [];
    nodeRelations[nodeComponentId].childBlocks = childIdsByStructureType?.[DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK] || [];
    nodeRelations[nodeComponentId].numOfDescendantBlocks = numOfDescendantBlocks || 0;
  }

  !!rootNodeId && populateSubdivisions(rootNodeId, nodeRelations);

  return nodeRelations;
}
