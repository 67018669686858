import { createSlice } from "@reduxjs/toolkit";

import { HEADER_X_CLIENT_ORIGIN, HEADER_X_TEST_MODE } from "@/src/constants/APIConstants";

interface DebugConfigState {
  consensus: "bypass-review" | "self-review" | undefined;
  apiOrigin: "prod" | "dev" | undefined;
  isOpen: boolean;
}

const initialState: DebugConfigState = {
  consensus: undefined,
  apiOrigin: undefined,
  isOpen: false,
};

const debugConfigSlice = createSlice({
  name: "debugConfig",
  initialState,
  reducers: {
    setConsensusState: (state, { payload }: { payload: DebugConfigState["consensus"] }) => {
      state.consensus = payload;
    },
    setOpenDebugCommandPalette: (state, { payload }: { payload: boolean }) => {
      state.isOpen = payload;
    },
    setApiOriginState: (state, { payload }: { payload: DebugConfigState["apiOrigin"] }) => {
      state.apiOrigin = payload;
    },
  },
});

export const { setConsensusState } = debugConfigSlice.actions;

export const getConsensusDebugConfig = () => (state: { debugConfig: DebugConfigState }) => {
  return state.debugConfig.consensus;
};

export const getDebugState = () => (state: { debugConfig: DebugConfigState }) => {
  return state.debugConfig;
};

export const getDebugCommandPalette = () => (state: { debugConfig: DebugConfigState }) => {
  return state.debugConfig.isOpen;
};

export const getDebugHeaderState = (debugConfig: DebugConfigState) => {
  const { apiOrigin, consensus } = debugConfig;

  const headers = {} as Record<string, string>;

  if (consensus) {
    headers[HEADER_X_TEST_MODE] = consensus;
  }
  if (apiOrigin) {
    const clientOrigin = apiOrigin === "prod" ? "sembly.com" : "dev.sembly.com";
    headers[HEADER_X_CLIENT_ORIGIN] = clientOrigin;
  }
  return headers;
};
export default debugConfigSlice;
