import { createSlice } from "@reduxjs/toolkit";
import mergeWith from "lodash.mergewith";

import {
  DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK,
  DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION,
  DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION,
} from "@/src/constants/ContentConstants";
import {
  INITIAL_DISCUSSION_VIEW_RELATION,
  constructDiscussionViewNodeRelationsFromNodes,
  constructInitialDiscussionMapNodeRelationsFromNodes,
  findDeletedNodeIds,
} from "@/src/domains/content/states/SpaceNodeRelations/NodeRelationsHelper";
import type { DiscussionMapNode, NodeObject } from "@/src/domains/content/types/Node";

type CleanNodeId = string;

export interface NodeRelations {
  parentId: CleanNodeId;
}

export interface DiscussionMapNodeRelations extends NodeRelations {
  discussionMapParentId: string;
  childDivisions: CleanNodeId[];
  childSubdivisions: CleanNodeId[];
  childBlocks: CleanNodeId[];
  structureType: string;
  rankingScore: number;
  numOfDescendantBlocks: number;
  contentGroupId: string;
}

type State = Record<CleanNodeId, DiscussionMapNodeRelations>;

export interface SelectorState {
  discussionMapRelations: State;
}

export interface StoreRelationDiscussionViewParams {
  nodes: Record<string, NodeObject>;
  discussionMapNodes?: Record<string, DiscussionMapNode>;
  overwrite?: boolean;
  rootNodeId?: string;
  isInitial?: boolean;
}
export interface StoreRelationDiscussionMapParams {
  nodes: Record<string, DiscussionMapNode>;
  overwrite?: boolean;
  rootNodeId: string;
}

export interface DeleteNodeFromRelationsParams {
  nodeIds: CleanNodeId[];
}

export interface FillDiscussionNodeRelations {
  relations: Record<CleanNodeId, NodeRelations>;
}

const initialState = {} as State;

export const discussionMapRelations = createSlice({
  name: "discussionMapRelations",
  initialState,
  reducers: {
    storeInitialDiscussionMapNodeRelations: (
      state,
      { payload: { nodes, rootNodeId } }: { payload: StoreRelationDiscussionMapParams },
    ) => {
      const relations = constructInitialDiscussionMapNodeRelationsFromNodes(nodes, rootNodeId);
      return mergeWith(state, relations, (oldValue: unknown, newValue: unknown) => {
        if (Array.isArray(oldValue) && Array.isArray(newValue)) {
          const mergedChildren = oldValue.concat(newValue);
          const uniqueChildren = [...new Set(mergedChildren)];
          const sortedChildren = uniqueChildren.sort((a, b) => {
            return (
              (relations[a]?.rankingScore || state[a]?.rankingScore || 1) -
              (relations[b]?.rankingScore || state[b]?.rankingScore || 0)
            );
          });
          return sortedChildren;
        }
        //prevent empty properties populated from initial values from overriding current values (in incomplete parent node relation updates)
        if (newValue == "" || newValue == 0) {
          return oldValue;
        }
      });
    },
    storeNodeRelations: (
      state,
      {
        payload: { nodes, overwrite, rootNodeId, isInitial, discussionMapNodes },
      }: { payload: StoreRelationDiscussionViewParams },
    ) => {
      if (isInitial && !!rootNodeId && !!discussionMapNodes) {
        const relations = constructInitialDiscussionMapNodeRelationsFromNodes(discussionMapNodes, rootNodeId);
        mergeWith(state, relations, (oldValue: unknown, newValue: unknown) => {
          if (Array.isArray(oldValue) && Array.isArray(newValue)) {
            const mergedChildren = oldValue.concat(newValue);
            const uniqueChildren = [...new Set(mergedChildren)];
            const sortedChildren = uniqueChildren.sort((a, b) => {
              return (
                (relations[a]?.rankingScore || state[a]?.rankingScore || 1) -
                (relations[b]?.rankingScore || state[b]?.rankingScore || 0)
              );
            });
            return sortedChildren;
          }
          //prevent empty properties populated from initial values from overriding current values (in incomplete parent node relation updates)
          if (newValue == "" || newValue == 0) {
            return oldValue;
          }
        });
      }

      const relations = constructDiscussionViewNodeRelationsFromNodes(nodes, rootNodeId);
      const deletedNodeIds = findDeletedNodeIds(nodes);

      for (const [nodeId, relation] of Object.entries(relations)) {
        //update numOfDescendantBlocks value up the tree for each new child block until it hits a division or subdivision
        for (const childId of relation.childBlocks) {
          if (!state[nodeId] || !state[nodeId].childBlocks.includes(childId)) {
            let ancestorId = relations[childId].discussionMapParentId || state[childId]?.discussionMapParentId;
            let ancestorStructureType = relations[ancestorId].structureType || state[ancestorId]?.structureType;
            while (
              ancestorId &&
              ancestorId !== "0" &&
              ancestorStructureType !== DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION &&
              ancestorStructureType !== DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION
            ) {
              if (!relations[ancestorId]) {
                relations[ancestorId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
              }
              relations[ancestorId].numOfDescendantBlocks = (relations[ancestorId]?.numOfDescendantBlocks || 0) + 1;
              ancestorId = relations[ancestorId]?.discussionMapParentId || state[ancestorId]?.discussionMapParentId;
              if (ancestorId !== "0")
                ancestorStructureType = relations[ancestorId]?.structureType || state[ancestorId]?.structureType;
            }
            if (ancestorId) {
              if (!relations[ancestorId]) {
                relations[ancestorId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
              }
              relations[ancestorId].numOfDescendantBlocks = (relations[ancestorId]?.numOfDescendantBlocks || 0) + 1;
            }
          }
        }
      }

      // delete relation and from its parent if node is deleted
      for (let i = 0; i < deletedNodeIds.length; i++) {
        const deletedNodeId = deletedNodeIds[i];
        const existingRelation = state[deletedNodeId];
        if (existingRelation) {
          const discussionMapParentId = existingRelation.discussionMapParentId;
          const structureType = existingRelation.structureType;
          if (state[discussionMapParentId]) {
            if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK) {
              state[discussionMapParentId].childBlocks = state[discussionMapParentId].childBlocks.filter(
                child => child !== deletedNodeId,
              );
              //update numOfDescendantBlocks value up the tree until it hits a division or subdivision
              let ancestorId = discussionMapParentId;
              let ancestorStructureType = state[ancestorId].structureType;
              while (
                ancestorId !== "0" &&
                ancestorStructureType !== DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION &&
                ancestorStructureType !== DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION
              ) {
                if (!state[ancestorId]) {
                  state[ancestorId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
                }
                state[ancestorId].numOfDescendantBlocks = state[ancestorId].numOfDescendantBlocks - 1;
                ancestorId = state[ancestorId].discussionMapParentId;
                if (ancestorId !== "0") ancestorStructureType = state[ancestorId].structureType;
              }
              if (!state[ancestorId]) {
                state[ancestorId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
              }
              state[ancestorId].numOfDescendantBlocks = state[ancestorId].numOfDescendantBlocks - 1;
            } else if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION) {
              state[discussionMapParentId].childDivisions = state[discussionMapParentId].childDivisions.filter(
                child => child !== deletedNodeId,
              );
            } else if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION) {
              state[discussionMapParentId].childSubdivisions = state[discussionMapParentId].childSubdivisions.filter(
                child => child !== deletedNodeId,
              );
            }
          }
          delete state[deletedNodeId];
        }
      }

      // if newly added relation have different parent than existing relation, remove from its parent
      const relationKeysArray = Object.keys(relations);
      for (let i = 0; i < relationKeysArray.length; i++) {
        const relationNodeId = relationKeysArray[i];
        const newRelation = relations[relationNodeId];
        const existingRelation = state[relationNodeId];
        if (existingRelation) {
          // if new relation entry had empty parentId, fill with existing
          if (!newRelation.parentId) {
            newRelation.parentId = existingRelation.parentId;
          }

          const isDiscussionMapParentChanged =
            existingRelation.discussionMapParentId !== newRelation.discussionMapParentId;
          if (isDiscussionMapParentChanged && newRelation.discussionMapParentId) {
            const oldParentId = existingRelation.discussionMapParentId;
            const previousParent = state[oldParentId];
            const structureType = existingRelation.structureType;

            if (previousParent) {
              if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK) {
                state[oldParentId].childBlocks = previousParent.childBlocks.filter(child => child !== relationNodeId);
                //update numOfDescendantBlocks value up the tree until it hits a division or subdivision
                let ancestorId = oldParentId;
                let ancestorStructureType = state[ancestorId].structureType;
                while (
                  ancestorId !== "0" &&
                  ancestorStructureType !== DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION &&
                  ancestorStructureType !== DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION
                ) {
                  if (!state[ancestorId]) {
                    state[ancestorId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
                  }
                  state[ancestorId].numOfDescendantBlocks = state[ancestorId].numOfDescendantBlocks - 1;
                  ancestorId = state[ancestorId].discussionMapParentId;
                  if (ancestorId !== "0") ancestorStructureType = state[ancestorId].structureType;
                }
                if (!state[ancestorId]) {
                  state[ancestorId] = { ...INITIAL_DISCUSSION_VIEW_RELATION };
                }
                state[ancestorId].numOfDescendantBlocks = state[ancestorId].numOfDescendantBlocks - 1;
              } else if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION) {
                state[oldParentId].childDivisions = previousParent.childDivisions.filter(
                  child => child !== relationNodeId,
                );
              } else if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION) {
                state[oldParentId].childSubdivisions = previousParent.childSubdivisions.filter(
                  child => child !== relationNodeId,
                );
              }
            }
          }
        }
      }

      // store the remaining node relations
      if (overwrite) {
        for (const nodeId of Object.keys(relations)) {
          relations[nodeId].childBlocks = relations[nodeId].childBlocks.sort((a, b) => {
            return (
              (relations[a]?.rankingScore || state[a]?.rankingScore || 1) -
              (relations[b]?.rankingScore || state[b]?.rankingScore || 0)
            );
          });
          relations[nodeId].childDivisions = relations[nodeId].childDivisions.sort((a, b) => {
            return (
              (relations[a]?.rankingScore || state[a]?.rankingScore || 1) -
              (relations[b]?.rankingScore || state[b]?.rankingScore || 0)
            );
          });
          relations[nodeId].childSubdivisions = relations[nodeId].childSubdivisions.sort((a, b) => {
            return (
              (relations[a]?.rankingScore || state[a]?.rankingScore || 1) -
              (relations[b]?.rankingScore || state[b]?.rankingScore || 0)
            );
          });
        }
        return { ...state, ...relations };
      } else {
        return mergeWith(state, relations, (oldValue: unknown, newValue: unknown, key: unknown) => {
          if (Array.isArray(oldValue) && Array.isArray(newValue)) {
            const mergedChildren = oldValue.concat(newValue);
            const uniqueChildren = [...new Set(mergedChildren)];
            const sortedChildren = uniqueChildren.sort((a, b) => {
              return (
                (relations[a]?.rankingScore || state[a]?.rankingScore || 1) -
                (relations[b]?.rankingScore || state[b]?.rankingScore || 0)
              );
            });
            return sortedChildren;
          }
          if (
            key == ("numOfDescendantBlocks" as keyof DiscussionMapNodeRelations) &&
            typeof oldValue === "number" &&
            typeof newValue === "number"
          ) {
            return oldValue + newValue;
          }
          //prevent empty properties populated from initial values from overriding current values (in incomplete parent node relation updates)
          if (newValue == "" || newValue == 0) {
            return oldValue;
          }
        });
      }
    },
    deleteNodeFromRelations: (state, { payload }: { payload: DeleteNodeFromRelationsParams }) => {
      const { nodeIds } = payload;
      const currentSpaceRelations = state || {};

      for (let i = 0; i < nodeIds.length; i++) {
        const nodeId = nodeIds[i];
        const nodeRelation = currentSpaceRelations[nodeId];

        const discussionMapParentIdOfThisNode = nodeRelation.discussionMapParentId;
        const structureType = nodeRelation.structureType;

        if (discussionMapParentIdOfThisNode && state[discussionMapParentIdOfThisNode]) {
          if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK) {
            state[discussionMapParentIdOfThisNode].childBlocks = state[
              discussionMapParentIdOfThisNode
            ].childBlocks.filter(child => child !== nodeId);
            //update numOfDescendantBlocks value up the tree
            let ancestorId = discussionMapParentIdOfThisNode;
            while (ancestorId !== "0") {
              state[ancestorId].numOfDescendantBlocks = state[ancestorId].numOfDescendantBlocks - 1;
              ancestorId = state[ancestorId].discussionMapParentId;
            }
            state[ancestorId].numOfDescendantBlocks = state[ancestorId].numOfDescendantBlocks - 1;
          } else if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION) {
            state[discussionMapParentIdOfThisNode].childDivisions = state[
              discussionMapParentIdOfThisNode
            ].childDivisions.filter(child => child !== nodeId);
          } else if (structureType === DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION) {
            state[discussionMapParentIdOfThisNode].childSubdivisions = state[
              discussionMapParentIdOfThisNode
            ].childSubdivisions.filter(child => child !== nodeId);
          }
        }

        // delete this nodeId relation entry
        delete state[nodeId];
      }
    },
  },
});
