import { createSlice } from "@reduxjs/toolkit";

export interface SpacePendingMetadata {
  guestContributionTaskCount?: number;
  memberScreeningTaskCount?: number;
  spaceEditTaskCount?: number;
  allTaskCount?: number;
}

export const PendingMetadataActionType = {
  EDIT_SPACE: "EDIT_SPACE",
};

export type SpacePendingMetadataState = {
  /**
   * GetSpaceByUrlResponse should always be a partial
   * since not all fields are available on some of the API endpoints
   */
  [spaceId: number]: Partial<SpacePendingMetadata>;
};

const initialState: SpacePendingMetadataState = {};

export type SpaceDataPayload = {
  spaceId: number;
  spacePendingMetadata: SpacePendingMetadata;
};

// TODO(Jaco): This is a temporary store while waiting for this PR to be merged: https://github.com/hiveos-team/hiveos-frontend/pull/5845
// When the PR merged, should make a new actions to not use `merge`
const spacePendingMetadataSlice = createSlice({
  name: "spacePendingMetadata",
  initialState,
  reducers: {
    setSpacePendingMetadata: (state, { payload }: { payload: SpaceDataPayload }) => {
      const data = {
        ...(state[payload.spaceId] || {}),
        ...payload.spacePendingMetadata,
      };
      state[payload.spaceId] = data;
    },
    setMultipleSpacePendingMetadata: (state, { payload }: { payload: SpaceDataPayload[] }) => {
      payload.forEach(({ spaceId, spacePendingMetadata }) => {
        const data = {
          ...(state[spaceId] || {}),
          ...spacePendingMetadata,
        };
        state[spaceId] = data;
      });
    },
  },
});

export const { setSpacePendingMetadata, setMultipleSpacePendingMetadata } = spacePendingMetadataSlice.actions;

export const createSpacePendingMetadataSelector = (spaceId?: number) => {
  return <T>(selector: (spacePendingMetadata: Partial<SpacePendingMetadata>) => T) =>
    ({ spacePendingMetadata }: { spacePendingMetadata: SpacePendingMetadataState }) => {
      if (!spaceId) return selector(spacePendingMetadata);
      return selector(spacePendingMetadata[spaceId]);
    };
};

export default spacePendingMetadataSlice;
