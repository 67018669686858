import HttpStatus from "http-status-codes";
import { REHYDRATE as rehydrateActionType } from "redux-persist";

import { slice as userSlice } from "@/src/stores/user";
import legacyAxios from "@/src/utils/legacyAxios";

const authenticateAxios = (axios, token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const unauthenticateAxios = axios => {
  delete axios.defaults.headers.common["Authorization"];
};

const authenticateActions = [userSlice.actions.loginSuccess.type, userSlice.actions.refreshToken.type];

export default ({ dispatch }) => {
  legacyAxios.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status !== HttpStatus.UNAUTHORIZED) {
        return Promise.reject(error);
      }
    },
  );

  return next => action => {
    if (action.type === rehydrateActionType) {
      if (action?.payload?.user?.accessToken) {
        const { accessToken } = action.payload.user;

        authenticateAxios(legacyAxios, accessToken);
      }
    }
    if (authenticateActions.includes(action.type)) {
      const { accessToken } = action.payload;

      authenticateAxios(legacyAxios, accessToken);
    }

    if (action.type === userSlice.actions.logout.type) {
      unauthenticateAxios(legacyAxios);
    }

    return next(action);
  };
};
