import storage from "redux-persist/lib/storage";

import { setBrowserTabNotificationEnabled } from "@/src/domains/liquidity/components/UserNotification/helpers/UserNotificationHelper";
import { slice as userSlice } from "@/src/stores/user";

const userLogoutMiddleWare = () => {
  return next => action => {
    if (action.type === userSlice.actions.logout.type) {
      // Clear user related data while logout
      storage.removeItem("persist:tasks");

      setTimeout(() => {
        // Add delay 500ms to make sure the redux state is cleared during logout,
        // otherwise it will appear again
        setBrowserTabNotificationEnabled(false);
      }, 500);
    }

    return next(action);
  };
};

export default userLogoutMiddleWare;
