import { createSlice } from "@reduxjs/toolkit";

import type { Activity } from "@/src/domains/liquidity/types/Activity";
import type { SpaceId } from "@/src/domains/space/models/types";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

type SpaceCompletedActivity = {
  activities: Activity[];
  nextPageToken?: string;
};
type SpaceCompletedActivityBySpace = Record<SpaceId, SpaceCompletedActivity>;

export interface SpaceCompletedActivitiesState {
  isFetching: boolean;
  activitiesBySpace: SpaceCompletedActivityBySpace;
}

const SpaceCompletedActivitiesInitialState: SpaceCompletedActivitiesState = {
  isFetching: false,
  activitiesBySpace: {},
};

interface AppendCompletedActivitiesParams {
  spaceId: SpaceId;
  activities: Activity[];
  nextPageToken?: string;
}

interface SetIsFetchingCompletedSpaceActivitiesParams {
  isFetching: boolean;
}

interface ResetCompletedSpaceActivitiesParams {
  spaceId: SpaceId;
}

export const spaceCompletedActivitiesSlice = createSlice({
  name: "spaceCompletedActivities",
  initialState: SpaceCompletedActivitiesInitialState,
  reducers: {
    // Replace the current state with new array of tasks.
    append: (state, { payload }: ReducerPayload<AppendCompletedActivitiesParams>) => {
      const { spaceId, activities, nextPageToken } = payload;

      const spaceActivityState = state.activitiesBySpace[spaceId];
      if (spaceActivityState) {
        spaceActivityState.activities.push(...activities);
        spaceActivityState.nextPageToken = nextPageToken;
        return;
      }

      state.activitiesBySpace[spaceId] = {
        activities: activities,
        nextPageToken: nextPageToken,
      };
    },
    setIsFetching: (state, { payload }: ReducerPayload<SetIsFetchingCompletedSpaceActivitiesParams>) => {
      const { isFetching } = payload;
      state.isFetching = isFetching;
    },
    reset: (state, { payload }: ReducerPayload<ResetCompletedSpaceActivitiesParams>) => {
      const { spaceId } = payload;
      delete state.activitiesBySpace[spaceId];
    },
    resetAll: () => {
      return SpaceCompletedActivitiesInitialState;
    },
  },
});

// ====
// Selectors
// ====

interface SelectorState {
  spaceCompletedActivities: SpaceCompletedActivitiesState;
}

function selectIsFetching() {
  return ({ spaceCompletedActivities }: SelectorState) => {
    return spaceCompletedActivities.isFetching;
  };
}

function selectSpaceActivities(spaceId: SpaceId) {
  return ({ spaceCompletedActivities }: SelectorState) => {
    return spaceCompletedActivities.activitiesBySpace?.[spaceId]?.activities || [];
  };
}

function selectNextPageToken(spaceId: SpaceId) {
  return ({ spaceCompletedActivities }: SelectorState) => {
    return spaceCompletedActivities.activitiesBySpace?.[spaceId]?.nextPageToken;
  };
}

function selectHasNextPage(spaceId: SpaceId) {
  return ({ spaceCompletedActivities }: SelectorState) => {
    // if the first page of spaceId is not fetched yet
    if (!spaceCompletedActivities.activitiesBySpace?.[spaceId]) return true;
    // check if the nextPageToken is empty or not
    const nextPageToken = spaceCompletedActivities.activitiesBySpace?.[spaceId]?.nextPageToken;
    return Boolean(nextPageToken);
  };
}

export const spaceCompletedActivitiesSelectors = {
  selectIsFetching,
  selectSpaceActivities,
  selectNextPageToken,
  selectHasNextPage,
} as const;
