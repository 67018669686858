import { setBrowserTabNotificationEnabled } from "@/src/domains/liquidity/components/UserNotification/helpers/UserNotificationHelper";
import * as notificationsSelectors from "@/src/stores/notifications/NotificationsSelector";
import { notificationsSlice } from "@/src/stores/notifications/NotificationsSlice";

const EVENT_UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export default ({ getState }) => {
  return next => action => {
    if (typeof window !== "undefined" && "serviceWorker" in navigator && navigator.serviceWorker.controller) {
      if (action.type === notificationsSlice.actions.clearActiveUserNotifications.type) {
        navigator.serviceWorker.controller.postMessage({
          type: EVENT_UPDATE_NOTIFICATION,
          message: { numOfActiveUserNotification: 0 },
        });
      } else if (action.type === notificationsSlice.actions.decreaseTaskCount.type) {
        const numOfActiveTask = notificationsSelectors.numOfActiveTaskSelector(getState());
        if (numOfActiveTask > 0) {
          navigator.serviceWorker.controller.postMessage({
            type: EVENT_UPDATE_NOTIFICATION,
            message: { numOfActiveTask: numOfActiveTask - 1 },
          });
        }
      }
    }

    const result = next(action);

    // Show or hide notification on browser tab
    // The red dot is displayed based on the number of my tasks, my activities, and user notifications
    if (
      action.type === notificationsSlice.actions.clearActiveUserNotifications.type ||
      action.type === notificationsSlice.actions.updateNotifications.type ||
      action.type === notificationsSlice.actions.decreaseTaskCount.type
    ) {
      const { notifications } = getState();
      const { numOfActiveTask, numOfActiveUserNotification } = notifications;
      setBrowserTabNotificationEnabled(numOfActiveTask > 0 || numOfActiveUserNotification > 0);
    }

    return result;
  };
};
