import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const APP_PATH = publicRuntimeConfig.APP_PATH;
const SERVER_PATH = publicRuntimeConfig.APP_SERVER_PATH;
const SEARCH_PATH = publicRuntimeConfig.APP_SEARCH_SERVER_PATH;
const APP_ENV = publicRuntimeConfig.APP_ENV;

export function getAPIBaseUrl() {
  // Localhost will always respect PublicRuntimeConfig / Env variable
  if (APP_ENV !== "local" && typeof window !== "undefined") {
    return `${window.location.origin}/api/app`;
  }
  return SERVER_PATH;
}

export function getSearchAPIBaseUrl() {
  // Localhost will always respect PublicRuntimeConfig / Env variable
  if (APP_ENV !== "local" && typeof window !== "undefined") {
    return `${window.location.origin}/api/search`;
  }
  return SEARCH_PATH;
}

export function getNextJsAPIBaseUrl() {
  if (typeof window !== "undefined") {
    return `${window.location.origin}/api`;
  }
  return `${APP_PATH}/api`;
}

export function getSWRCacheKey(keys: string[]): string {
  return `@${keys.map(key => `"${key}"`).join(",")},`;
}
