import { createSlice } from "@reduxjs/toolkit";

import type { DiscussionMapNode } from "@/src/domains/content/types/Node";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

type State = {
  [rootNodeId in string]: {
    [nodeId in string]: DiscussionMapNode;
  };
};

const initialState: State = {};

export const discussionMapNodesSlice = createSlice({
  name: "discussionMapNodes",
  initialState: initialState,
  reducers: {
    storeNodes: (
      state: State,
      {
        payload,
      }: ReducerPayload<{
        rootNodeId: string;
        nodes: { [nodeId in string]: DiscussionMapNode };
      }>,
    ) => {
      state[payload.rootNodeId] = payload.nodes;
    },
  },
});
