const PERSIST_KEY_PREFIX = "persist:";

export function getPersistLocalStorageDataFromList(list: string[]) {
  return list.map(key => {
    const data = window.localStorage.getItem(`${PERSIST_KEY_PREFIX}${key}`);
    return { key, data };
  });
}

export function setPersistLocalStorageDataFromList(list: { key: string; data: string | null }[]) {
  list.forEach(({ key, data }) => {
    if (data) {
      window.localStorage.setItem(`${PERSIST_KEY_PREFIX}${key}`, data);
    }
  });
}
