import type { UpdateIndicatorLocation } from "@/src/domains/update-indicator/types/UpdateIndicatorResponse";

export interface NodeState {
  nodeId: number;
  sectionId: number;
  locations: UpdateIndicatorLocation[];
  isImportant: boolean;
  unreadNodes: number[];
}

export interface SpaceUpdates {
  [key: number]: NodeState[];
}

export interface UpdateIndicatorState {
  updates: SpaceUpdates;
}

const UpdateIndicatorInitialState: UpdateIndicatorState = {
  updates: {},
};

export default UpdateIndicatorInitialState;
