import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/domains/space/components/JoinSpaceDialog/state/initialState";

export default createSlice({
  name: "joinSpace",
  initialState,
  reducers: {
    open: (state, { payload: spacePrivilegeType }) => {
      state.isOpened = true;
      state.data = { ...state.data, spacePrivilegeType };
    },
    close: state => {
      state.isOpened = false;
      state.data = null;
    },
    setData: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
    setIsLoadingButton: (state, { payload: isLoading }) => {
      state.isButtonLoading = isLoading;
    },
  },
});
