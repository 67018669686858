import type { ActivityStatus } from "@/src/components/sembly-ui/feature/ActivityFeed/types/ActivityStatus";
import type { Activity } from "@/src/domains/liquidity/types/Activity";
import type { TimestampInSeconds } from "@/src/utils/general/TimeUtil";

export interface VirtualizedActivity {
  actionId: number;
  createdAt: TimestampInSeconds;
  state: ActivityStatus;
}

interface MyActivitiesState {
  sequences: VirtualizedActivity[];
  activities: Record<number, Activity>;
  prevPageToken?: string;
  nextPageToken?: string;
  startReached: boolean;
  endReached: boolean;
}

const MyActivitiesInitialState: MyActivitiesState = {
  sequences: [],
  activities: {},
  startReached: false,
  endReached: false,
};

export default MyActivitiesInitialState;
