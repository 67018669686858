import type { Dispatch } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { calculateViewIndexes, toSpaceTasks } from "@/src/domains/liquidity/states/SpaceReviewTasks/utils";
import type { SpaceCanonicalId } from "@/src/domains/space/models/types";
import type { ConsensusTask, TaskType } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import type { RootState } from "@/src/stores/rootReducer";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

export type SpaceTask = Record<TaskType, ConsensusTask[]>;
export type SpaceTasks = Record<SpaceCanonicalId, SpaceTask>;
export type SpaceViewIndex = Record<TaskType, number>;
export type SpaceViewIndexes = Record<SpaceCanonicalId, SpaceViewIndex>;
export type SpaceTasksCount = Record<SpaceCanonicalId, Record<TaskType, number>>;
export type SpaceTasksNotificationTitles = Record<SpaceCanonicalId, string>;
export type SpaceTasksPaginationTokens = Record<SpaceCanonicalId, string>;

export interface SpaceReviewTasksState {
  isFetching: boolean;
  tasks: SpaceTasks;
  tasksCount: SpaceTasksCount;
  notificationTitles: SpaceTasksNotificationTitles;
  paginationTokens: SpaceTasksPaginationTokens;
  expiredTasks: { [spaceCanonicalId in string]: { [taskId in number]: boolean } };
  // The view indexes for the review dialog.
  // The view indexes are put in the redux slice for ease of maintenance, since
  // we might need to keep the `viewIndexes` in sync with the `tasks`.
  viewIndexes: SpaceViewIndexes;
  viewIndex: number;
}

const SpaceReviewTasksInitialState: SpaceReviewTasksState = {
  isFetching: false,
  tasksCount: {},
  notificationTitles: {},
  paginationTokens: {},
  tasks: {},
  viewIndexes: {},
  viewIndex: 0,
  expiredTasks: {},
};

interface StoreParams {
  tasks?: ConsensusTask[];
  isFetchingTasks?: boolean;
}

interface StoreV2Params {
  tasks?: SpaceTasks;
  tasksCount?: SpaceTasksCount;
  notificationTitles?: SpaceTasksNotificationTitles;
  paginationTokens?: SpaceTasksPaginationTokens;
  isFetchingTasks?: boolean;
}

interface StoreNextPageParams {
  spaceCanonicalId: SpaceCanonicalId;
  nextPageToken?: string;
  tasks: ConsensusTask[];
}

interface RemoveTaskParams {
  taskId: number;
  spaceCanonicalId: SpaceCanonicalId;
  taskType: TaskType;
}

interface SetViewIndexParams {
  viewIndex: number;
}

export const spaceReviewTasksSlice = createSlice({
  name: "spaceReviewTasks",
  initialState: SpaceReviewTasksInitialState,
  reducers: {
    // Replace the current state with new array of tasks.
    store: (state, { payload }: ReducerPayload<StoreParams>) => {
      const { tasks, isFetchingTasks } = payload;

      if (typeof isFetchingTasks !== "undefined") {
        state.isFetching = isFetchingTasks;
      }

      if (tasks) {
        const newTasks = toSpaceTasks(tasks);
        const viewIndexes = calculateViewIndexes(state, newTasks);

        state.tasks = newTasks;
        state.viewIndexes = viewIndexes;
      }
    },
    storeV2: (state, { payload }: ReducerPayload<StoreV2Params>) => {
      const { tasks, isFetchingTasks, tasksCount, notificationTitles, paginationTokens } = payload;

      if (typeof isFetchingTasks !== "undefined") {
        state.isFetching = isFetchingTasks;
      }

      if (tasks) {
        const viewIndexes = calculateViewIndexes(state, tasks);

        state.tasks = tasks;
        state.viewIndexes = viewIndexes;
      }

      if (tasksCount) {
        state.tasksCount = tasksCount;
      }

      if (notificationTitles) {
        state.notificationTitles = notificationTitles;
      }

      if (paginationTokens) {
        state.paginationTokens = paginationTokens;
      }
    },
    storeNextPage: (state, { payload }: ReducerPayload<StoreNextPageParams>) => {
      const { tasks, spaceCanonicalId, nextPageToken } = payload;
      state.paginationTokens[spaceCanonicalId] = nextPageToken ?? "";

      if (tasks) {
        state.tasks[spaceCanonicalId].NORMAL = state.tasks[spaceCanonicalId].NORMAL.concat(tasks);
      }
    },
    expireTask: (state, { payload }: ReducerPayload<{ spaceCanonicalId: string; taskId: number }>) => {
      const { spaceCanonicalId, taskId } = payload;
      if (!state.expiredTasks[spaceCanonicalId]) {
        state.expiredTasks[spaceCanonicalId] = {};
      }
      state.expiredTasks[spaceCanonicalId][taskId] = true;
    },
    removeTask: (state, { payload }: ReducerPayload<RemoveTaskParams>) => {
      const { spaceCanonicalId, taskType, taskId } = payload;
      const allTasks = Object.values(state.tasks).flatMap(spaceTasks => spaceTasks?.NORMAL || []);
      const indexToRemove = allTasks.findIndex(task => task.id === taskId);
      if (indexToRemove === -1) return;

      state.tasks[spaceCanonicalId][taskType] = state.tasks[spaceCanonicalId][taskType].filter(
        task => task.id !== taskId,
      );

      state.viewIndex = indexToRemove < state.viewIndex ? state.viewIndex - 1 : state.viewIndex;

      state.tasksCount[spaceCanonicalId][taskType] = Math.max(state.tasksCount[spaceCanonicalId][taskType] - 1, 0);
    },
    setViewIndex: (state, { payload }: ReducerPayload<SetViewIndexParams>) => {
      const { viewIndex } = payload;
      state.viewIndex = viewIndex;
    },
    /**
     * Reducer case to handle swiping left on a task.
     * Decrements the viewIndex to show the previous task.
     */
    swipeLeft: state => {
      if (state.viewIndex < 1) return;

      state.viewIndex = state.viewIndex - 1;
    },
    /**
     * Reducer case to handle swiping right on a task.
     * Increments the view index for the task type in the space
     * to show the next task.
     */
    swipeRight: state => {
      const currentTaskLength = Object.values(state.tasks).flatMap(spaceTasks => spaceTasks?.NORMAL || []).length;
      if (state.viewIndex >= currentTaskLength - 1) return;
      state.viewIndex = state.viewIndex + 1;
    },

    /**
     * Reducer case to handle completing a task.
     * Increments the viewIndex to show the next task.
     */
    swipeNext: state => {
      const currentTaskLength = Object.values(state.tasks).flatMap(spaceTasks => spaceTasks?.NORMAL || []).length;
      if (state.viewIndex >= currentTaskLength - 1) {
        state.viewIndex = state.viewIndex - 1;
        return;
      }

      state.viewIndex = state.viewIndex + 1;
    },
    reset: () => {
      return SpaceReviewTasksInitialState;
    },
  },
});

// ====
// Thunks
// ====

export function markTaskAsDone(taskId: number, spaceCanonicalId: SpaceCanonicalId, taskType: TaskType) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(spaceReviewTasksSlice.actions.swipeNext());
    // This timeout is necessary because we need to wait for the swipeNext animation when submitting a task.
    setTimeout(() => {
      dispatch(spaceReviewTasksSlice.actions.removeTask({ taskId, spaceCanonicalId, taskType }));
    }, 200);
  };
}

// ====
// Selectors
// ====

interface SelectorState {
  spaceReviewTasks: SpaceReviewTasksState;
}

function selectIsFetching() {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.isFetching;
  };
}

function selectReviewTasks() {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.tasks;
  };
}

function selectSpaceReviewTasks(spaceCanonicalId: SpaceCanonicalId, taskType: TaskType) {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.tasks?.[spaceCanonicalId]?.[taskType] || [];
  };
}

function selectGlobalViewIndex() {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.viewIndex;
  };
}

function selectNotificationTitle(spaceCanonicalId: SpaceCanonicalId) {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.notificationTitles[spaceCanonicalId];
  };
}

function selectPaginationToken(spaceCanonicalId: SpaceCanonicalId) {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.paginationTokens[spaceCanonicalId];
  };
}

function selectTasksCount(spaceCanonicalId: SpaceCanonicalId) {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.tasksCount[spaceCanonicalId];
  };
}

function selectAllTasksCount() {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.tasksCount;
  };
}

function selectExpiredTasks() {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.expiredTasks;
  };
}

function selectSpaceExpiredTasks(spaceCanonicalId: SpaceCanonicalId) {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.expiredTasks?.[spaceCanonicalId] ?? {};
  };
}

function selectExpiredTask(spaceCanonicalId: SpaceCanonicalId, taskId: number) {
  return ({ spaceReviewTasks }: SelectorState) => {
    return spaceReviewTasks.expiredTasks?.[spaceCanonicalId]?.[taskId];
  };
}

export const spaceReviewTasksSelectors = {
  selectIsFetching,
  selectReviewTasks,
  selectSpaceReviewTasks,
  selectNotificationTitle,
  selectPaginationToken,
  selectTasksCount,
  selectAllTasksCount,
  selectGlobalViewIndex,
  selectExpiredTasks,
  selectSpaceExpiredTasks,
  selectExpiredTask,
} as const;
