import { createSlice } from "@reduxjs/toolkit";

import type { DynamicFormConfig } from "@/src/domains/dynamic-form/types/DynamicForm";
import type { ActionType } from "@/src/domains/types/NodeActionType";
import { mergeTwoLevelDeep } from "@/src/utils/helpers/ObjectMerge";

export type BaseContentActionDisplay = {
  title?: string;
  score?: number;
  isEnabled?: boolean;
  isPending?: boolean;
  disabledKey?: string;
  disabledHelpText?: string;
  formTitle?: string;
  text?: string;
  form?: DynamicFormConfig;
  draftMetadata?: Object;
  pendingHelpText?: string;
  isPendingHighlighted?: boolean;
  defaultValues?: Record<string, object>;
};

export type ContentActionDisplay = {
  // Added by useActionDisplays
  actionType: string;
} & BaseContentActionDisplay;

export type ActionDisplays = Record<string, ContentActionDisplay>;
export type RootActionDisplays = Record<ActionType, ActionDisplays>;

const initialState = {} as RootActionDisplays;

interface StoreActionDisplaysPayload {
  rootActionDisplays: RootActionDisplays;
}

export const actionDisplays = createSlice({
  name: "actionDisplays",
  initialState,
  reducers: {
    storeActionDisplays: (
      state: RootActionDisplays,
      { payload: { rootActionDisplays } }: { payload: StoreActionDisplaysPayload },
    ) => {
      // should only do deep compare for level deep, value that more then 2 level deep will use spread value from 2nd object
      return mergeTwoLevelDeep(state, rootActionDisplays) as RootActionDisplays;
    },
  },
});
