import { shallowEqual } from "react-redux";

/**
 *
 * Check whether 2 or more objects has the same keys with each other
 * Taken from: https://stackoverflow.com/a/35047888
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function haveSameKeys(...objects: any[]) {
  const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
  const union = new Set(allKeys);
  return objects.every(object => union.size === Object.keys(object).length);
}

export function objectShallowEqual(
  oldState?: object,
  newState?: object,
  compareFn?: (key: string, oldValue?: object, newValue?: object) => boolean | undefined,
) {
  // shallowEqual is already enough for this case
  if (typeof compareFn === "undefined") {
    return shallowEqual(oldState, newState);
  }

  // Both have the same reference
  if (oldState === newState) return true;

  // One of them is undefined
  if (typeof oldState === "undefined" || typeof newState === "undefined") return false;

  const oldKeys = Object.keys(oldState);
  const newKeys = Object.keys(newState);

  // Both of them have different length
  if (oldKeys.length !== newKeys.length) return false;

  for (let i = 0; i < oldKeys.length; i++) {
    const key = oldKeys[i];

    if (!Object.prototype.hasOwnProperty.call(newState, key)) {
      return false;
    }

    // @ts-ignore
    const oldValue = oldState[key];
    // @ts-ignore
    const newValue = newState[key];

    const compareResult = compareFn(key, oldValue, newValue);

    if (typeof compareResult !== "undefined") {
      if (!compareResult) {
        return false;
      }
    } else {
      if (oldValue !== newValue) {
        return false;
      }
    }
  }

  return true;
}

export function arrayOfObjectShallowEqual(oldState?: unknown[], newState?: unknown[]) {
  // Both have the same reference
  if (oldState === newState) return true;

  // One of them is undefined
  if (typeof oldState === "undefined" || typeof newState === "undefined") return false;

  // Both of them have different length
  if (oldState.length !== newState.length) return false;

  for (let i = 0; i < oldState.length; i++) {
    if (!shallowEqual(oldState[i], newState[i])) {
      return false;
    }
  }

  return true;
}

export function mapOfObjectShallowEqual(oldState?: object, newState?: object) {
  // Both have the same reference
  if (oldState === newState) return true;

  // One of them is undefined
  if (typeof oldState === "undefined" || typeof newState === "undefined") return false;

  const oldKeys = Object.keys(oldState);
  const newKeys = Object.keys(newState);

  // Both of them have different length
  if (oldKeys.length !== newKeys.length) return false;

  for (let i = 0; i < oldKeys.length; i++) {
    const key = oldKeys[i];

    if (!Object.prototype.hasOwnProperty.call(newState, key)) {
      return false;
    }

    // @ts-ignore
    if (!shallowEqual(oldState[key], newState[key])) {
      return false;
    }
  }

  return true;
}

// Returns an array of keys from a record with number as its key type.
// It behaves like `Object.keys` but with additional type casting to a number.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRecordNumberKeys(record: Record<number, unknown>): number[] {
  return Object.keys(record).map(key => Number(key));
}

// Returns an array of key and value pair from a record with number as its key type.
// It behaves like `Object.keys` but with additional type casting to a number.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRecordNumberEntries<V>(record: Record<number, V>): [number, V][] {
  return Object.entries<V>(record).map(([k, v]) => [Number(k), v]);
}

// Returns the value of a record value given a key when the key exists.
// Otherwise, initialize the record with `initial` value.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getOrInitRecord<K extends keyof any, V>(record: Record<K, V>, key: K, initial: V): V {
  const val = record?.[key];
  if (val != null) {
    return val;
  }
  record[key] = initial;
  return record[key];
}
