import type { UpdateIndicatorLocation } from "@/src/domains/update-indicator/types/UpdateIndicatorResponse";

export type DiscussionCountInSpace = {
  [id in UpdateIndicatorLocation]?: number;
};

interface SpaceDiscussionCountState {
  [spaceId: number]: DiscussionCountInSpace;
}

const SpaceDiscussionCountInitialState: SpaceDiscussionCountState = {};

export default SpaceDiscussionCountInitialState;
