export interface NotificationsState {
  numOfActiveAction: number;
  numOfActiveTask: number;
  numOfActiveUserNotification: number;
  latestUserNotificationUpdatedAt: number;
}

const NotificationsInitialState: NotificationsState = {
  numOfActiveAction: 0,
  numOfActiveTask: 0,
  numOfActiveUserNotification: 0,
  latestUserNotificationUpdatedAt: 0,
};

export default NotificationsInitialState;
