import { createSlice } from "@reduxjs/toolkit";

import { PARENT_ISSUE_FLAG_OPTIONS } from "@/src/constants/AppConstant";
import type {
  IssueTagOption,
  PostTypeOption,
  PreviewPostButtonState,
  PreviewPostConfig,
  PreviewPostData,
  PreviewPostDetail,
  PreviewPostNudge,
  PreviewPostResult,
} from "@/src/domains/content/states/PreviewPost/PreviewPostInitialState";
import PreviewPostInitialState from "@/src/domains/content/states/PreviewPost/PreviewPostInitialState";
import type { IssueNudge } from "@/src/domains/content/types/ContentAPITypes";
import type { StanceKey } from "@/src/domains/content/types/Node";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface StoreParams {
  data: { nodeId: string; value: PreviewPostDetail };
}
interface UpdateFormConfigParams {
  data: { nodeId: string; formConfig: string };
}
interface RemovePreviewParams {
  data: { nodeId: string };
}

interface StoreResultParams {
  data: {
    nodeId: string;
    suggestedPostType: string;
    postTypeOptions: PostTypeOption[];
    issueTagOptions: IssueTagOption[];
    nudges: PreviewPostNudge[];
    issues: IssueNudge[];
    textDiff?: string;
    titleDiff?: string;
    stanceKey?: StanceKey;
  };
}

interface ChangeTypePreview {
  data: { nodeId: string; suggestedPostType: string };
}

interface ChangeStanceKeyPreview {
  data: { nodeId: string; stanceKey?: StanceKey };
}

interface ChangeIssueTagPreview {
  data: { nodeId: string; issueTagId: string };
}

interface DismissSuggestedIssueTagPreview {
  data: { nodeId: string; flagId: string };
}

interface SetFetchingPreview {
  data: { parentId: string; nodeId?: string; isFetching: boolean };
}

export const previewPostSlice = createSlice({
  name: "previewPost",
  initialState: PreviewPostInitialState,
  reducers: {
    store: (state: { previewPost: PreviewPostData }, { payload }: ReducerPayload<StoreParams>) => {
      const { data } = payload;
      if (data) {
        state.previewPost = { ...state.previewPost, [data.nodeId]: { ...data.value } };
      }
    },
    setPreviewButtonState: (
      state: { previewPostConfig: PreviewPostConfig },
      { payload }: ReducerPayload<{ buttonState: PreviewPostButtonState }>,
    ) => {
      const { buttonState } = payload;
      if (buttonState) {
        state.previewPostConfig = { buttonState };
      }
    },
    removePreview: (state: { previewPost: PreviewPostData }, { payload }: ReducerPayload<RemovePreviewParams>) => {
      const { data } = payload;
      if (data?.nodeId) {
        delete state.previewPost[data.nodeId];
      }
    },
    storePreviewResult: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<StoreResultParams>,
    ) => {
      const { data } = payload;
      if (data) {
        const { suggestedPostType, issueTagOptions, postTypeOptions, nudges, textDiff, titleDiff, stanceKey, issues } =
          data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.nodeId]: {
            ...state.previewPostResult?.[data.nodeId],
            suggestedPostType,
            issueTagOptions,
            issues,
            postTypeOptions,
            nudges,
            textDiff,
            titleDiff,
            stanceKey,
          },
        };
      }
    },
    removePreviewResult: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<RemovePreviewParams>,
    ) => {
      const { data } = payload;
      if (data?.nodeId) {
        delete state.previewPostResult[data.nodeId];
      }
    },
    changeTypePreview: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<ChangeTypePreview>,
    ) => {
      const { data } = payload;
      if (data) {
        const { suggestedPostType } = data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.nodeId]: { ...state.previewPostResult?.[data.nodeId], suggestedPostType },
        };
      }
    },
    changeStanceKeyPreview: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<ChangeStanceKeyPreview>,
    ) => {
      const { data } = payload;
      if (data) {
        const { stanceKey } = data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.nodeId]: { ...state.previewPostResult?.[data.nodeId], stanceKey: stanceKey },
        };
      }
    },
    dismissSuggestedIssueTag: (state, { payload }: ReducerPayload<DismissSuggestedIssueTagPreview>) => {
      if (payload.data) {
        const { nodeId, flagId } = payload.data;
        const currentIssues = state.previewPostResult?.[nodeId].issues || [];
        const updatedIssues = currentIssues.filter(issue => issue.flag_id !== flagId);
        state.previewPostResult = {
          ...state.previewPostResult,
          [nodeId]: { ...state.previewPostResult?.[nodeId], issues: updatedIssues },
        };
      }
    },
    changeIssueFlag: (state, { payload }: ReducerPayload<ChangeIssueTagPreview>) => {
      const { data } = payload;
      if (data) {
        const { issueTagId } = data;
        const formConfig = state.previewPost[data.nodeId].formConfig || "";

        // Updating issue tag in preview render
        const formConfigObj = JSON.parse(formConfig);
        if (formConfigObj?.cList) {
          const flagFieldIndex = formConfigObj.cList.findIndex(
            (field: { cid: string }) => field.cid === PARENT_ISSUE_FLAG_OPTIONS,
          );
          if (flagFieldIndex >= 0) {
            formConfigObj.cList[flagFieldIndex].defaultVal.value = issueTagId;
            state.previewPost[data.nodeId].formConfig = JSON.stringify(formConfigObj);
          }
        }

        // Updating issue tag in submitted payload
        const issueFlagField = state.previewPost[data.nodeId]?.temporaryValue?.values?.[PARENT_ISSUE_FLAG_OPTIONS];
        if (issueFlagField) {
          issueFlagField.data.value = issueTagId;
          state.previewPost[data.nodeId].temporaryValue!.values[PARENT_ISSUE_FLAG_OPTIONS] = issueFlagField;
        }
      }
    },
    setLoadingFetch: (
      state: { previewPostResult: PreviewPostResult },
      { payload }: ReducerPayload<SetFetchingPreview>,
    ) => {
      const { data } = payload;
      if (data) {
        const { isFetching } = data;
        state.previewPostResult = {
          ...state.previewPostResult,
          [data.parentId]: { ...state.previewPostResult?.[data.parentId], isFetching },
        };
      }
    },
    updateFormConfig: (
      state: { previewPost: PreviewPostData },
      { payload }: ReducerPayload<UpdateFormConfigParams>,
    ) => {
      const { data } = payload;
      if (data) {
        state.previewPost = {
          ...state.previewPost,
          [data.nodeId]: { ...state.previewPost[data.nodeId], formConfig: data.formConfig },
        };
      }
    },
  },
});
