import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/zendesk/initialState";

export default createSlice({
  name: "zendesk",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.zendeskToken = payload.token;
      state.created = new Date().toISOString();
      return state;
    },
    setIsZendeskScriptLoaded: (state, { payload }) => {
      state.isZendeskScriptLoaded = payload;
      return state;
    },
  },
});
