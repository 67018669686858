import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/spaceInformationBanner/initialState";

// change the version on rootReducers.ts
const oldPersistKey = "persist:spaceInformationBanner";

type PayloadObject = {
  payload: {
    spaceUrl: string;
    colors: [string, string, string];
    imageUrl: string;
  };
};

export default createSlice({
  name: "spaceInformationBanner",
  initialState,
  reducers: {
    setColor: (state, { payload }: PayloadObject) => {
      state[payload.spaceUrl] = {
        colors: payload.colors,
        imageUrl: payload.imageUrl,
      };
      // remove previous persist version
      if (typeof window !== undefined && window?.localStorage?.getItem(oldPersistKey)) {
        window.localStorage.removeItem(oldPersistKey);
      }
      return state;
    },
  },
});
