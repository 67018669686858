const TRACKING_EVENT = {
  PAGE_VISIT: "PAGE_VISIT",
  SECTION_VISIT: "SECTION_VISIT",
  POST_VISIT: "POST_VISIT",
  WATCH_BUTTON_CLICKED: "WATCH_BUTTON_CLICKED",
  BE_PIONEER_CLICKED: "BE_PIONEER_CLICKED",
  SHARE: "SHARE",
};

export default TRACKING_EVENT;

export const ONBOARDING_TRACKING_EVENTS = {
  OPEN_SIGNUP_FORM: "OPEN_SIGNUP_FORM",
  CLOSE_SIGNUP_FORM: "CLOSE_SIGNUP_FORM",
  SUBMIT_SIGNUP_FORM: "SUBMIT_SIGNUP_FORM",
  ONBOARDING_CARD_FINISHED: "ONBOARDING_CARD_FINISHED",
  COACHMARK_FINISHED: "COACHMARK_FINISHED",
};

export const ONBOARDING_TRACKING_FLOW_NAME = {
  ONBOARDING: "onboarding",
  GUEST_PREVIEW: "guest_preview",
};

/**
 * Share Tracking Spec
 */
interface SpaceShareTrackingSpec {
  spaceId: number;
  type: "SPACE";
}

interface ContentShareTrackingSpec {
  spaceId: number;
  type: "CONTENT";
  nodeId: string;
}

type BaseShareTrackingSpec = SpaceShareTrackingSpec | ContentShareTrackingSpec;

interface LinkShareTrackingSpec {
  method: "LINK";
}

interface SocialShareTrackingSpec {
  method: "SOCIAL";
  // Currently based on `translations.common.invite.media`
  via: "FACEBOOK" | "FACEBOOK_MESSENGER" | "WHATSAPP" | "LINKED_IN" | "TELEGRAM" | "TWITTER";
}

type BaseMethodShareTrackingSpec = LinkShareTrackingSpec | SocialShareTrackingSpec;

export type ShareTrackingSpec = BaseShareTrackingSpec & BaseMethodShareTrackingSpec;

/**
 * Watch Tracking Spec
 */
interface SpaceWatchTrackingSpec {
  type: "SPACE";
  spaceId: number;
}

interface ContentWatchTrackingSpec {
  spaceId: number;
  type: "CONTENT";
  nodeId: string;
}

export type WatchTrackingSpec = SpaceWatchTrackingSpec | ContentWatchTrackingSpec;

/**
 * Be a Pioneer Tracking Spec
 */

export interface PioneerOrJoinTrackingSpec {
  spaceId: number;
}

// ====================

export interface OnboardingTrackingSpec {
  card_name: string;
  is_skipped: boolean;
  read_cards: string[];
  skipped_cards: string[];
}

/**
 * Coachmark Tracking Spec
 */

interface CoachmarkCardSpec {
  flow_name: string;
  card_name: string;
}

export interface CoachmarkSeenTrackingSpec extends CoachmarkCardSpec {}

export interface CoachmarkFinishedTrackingSpec {
  seen_cards: CoachmarkCardSpec[];
  skipped_cards: CoachmarkCardSpec[];
}

export const TRACKING_COACHMARK_FLOW_NAME = {
  SPACE_VIEW_ONBOARDING: "First Time In Space",
  DISCUSSION_VIEW_ONBOARDING: "First Time In Discussion View",
};

export const TRACKING_COACHMARK_CARD_NAME = {
  SPACE_VIEW_ONBOARDING: {
    CONTENT_PANEL: "Let's show you around!",
    SAMPLE_CONTENT: "This is a sample discussion",
    SECTION_TOC: "Keep things organized",
    SPACE_GUIDE: "Need more guidance?",
    INVITE_BUTTON: "Invite others here",
  },
  DISCUSSION_VIEW_ONBOARDING: {
    DISCUSSION_MAP: "Discussion map",
  },
};

export type CoachmarkSpaceViewOnboardingType = keyof typeof TRACKING_COACHMARK_CARD_NAME.SPACE_VIEW_ONBOARDING;
export type CoachmarkDiscussionViewOnboardingType =
  keyof typeof TRACKING_COACHMARK_CARD_NAME.DISCUSSION_VIEW_ONBOARDING;

export const SIGNUP_WITHOUT_INVITES_TRACKING_EVENT = {
  OPEN_SIGNUP_TO_JOIN_SPACE_MODAL: "OPEN_SIGNUP_TO_JOIN_SPACE_MODAL",
  CLOSE_SIGNUP_TO_JOIN_SPACE_MODAL: "CLOSE_SIGNUP_TO_JOIN_SPACE_MODAL",
  CLICK_SIGNUP_WITH_EMAIL_CTA: "CLICK_SIGNUP_WITH_EMAIL_CTA",
  CLICK_CONTINUE_WITH_GOOGLE_CTA: "CLICK_CONTINUE_WITH_GOOGLE_CTA",
  OPEN_SIGNUP_FORM: "OPEN_SIGNUP_FORM",
  CLOSE_SIGNUP_FORM: "CLOSE_SIGNUP_FORM",
  SUBMIT_SIGNUP_FORM: "SUBMIT_SIGNUP_FORM",
  OPEN_EMAIL_VERIFICATION_MODAL: "OPEN_EMAIL_VERIFICATION_MODAL",
  CLOSE_EMAIL_VERIFICATION_MODAL: "CLOSE_EMAIL_VERIFICATION_MODAL",
  CLICK_VERIFY_EMAIL_IN_VERIFICATION_EMAIL: "CLICK_VERIFY_EMAIL_IN_VERIFICATION_EMAIL",
  CHANGE_EMAIL_WHEN_VERIFYING_EMAIL: "CHANGE_EMAIL_WHEN_VERIFYING_EMAIL",
  OPEN_NAME_SELECTION_MODAL: "OPEN_NAME_SELECTION_MODAL",
  CLOSE_NAME_SELECTION_MODAL: "CLOSE_NAME_SELECTION_MODAL",
  CLICK_SUGGESTED_DISPLAY_NAME_CHIP: "CLICK_SUGGESTED_DISPLAY_NAME_CHIP",
  OPEN_ONBOARDING_CARD: "OPEN_ONBOARDING_CARD",
  COACHMARK_SEEN: "COACHMARK_SEEN",
  COACHMARK_FINISHED: "COACHMARK_FINISHED",
};
