import { createSlice } from "@reduxjs/toolkit";

import type { CleanNodeId } from "@/src/domains/content/types/Node";

export type State = Record<number, CleanNodeId>;

export interface SelectorState {
  spaceNodeIds: State;
}

const initialState = {} as State;

interface StoreSpaceNodeIdParams {
  spaceId: number;
  spaceNodeId: CleanNodeId;
}

export const spaceNodeIds = createSlice({
  name: "spaceNodeIds",
  initialState,
  reducers: {
    storeSpaceNodeId: (state, { payload: { spaceId, spaceNodeId } }: { payload: StoreSpaceNodeIdParams }) => {
      const updatedState = { ...state };
      updatedState[spaceId] = spaceNodeId;
      return updatedState;
    },
  },
});
