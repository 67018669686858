import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/domains/user/components/PreviewBanner/state/initialState";

export default createSlice({
  name: "previewBanner",
  initialState,
  reducers: {
    showNonLoggedInUserBanner: (state, { payload: spaceName }) => {
      state.nonLoggedInUserBanner = { isShown: true, spaceName };
    },
    hideNonLoggedInUserBanner: state => {
      state.nonLoggedInUserBanner = { isShown: false };
    },
    showNonSpaceMemberBanner: (state, { payload: spaceName }) => {
      state.nonSpaceMemberBanner = { isShown: true, spaceName };
    },
    hideNonSpaceMemberBanner: state => {
      state.nonSpaceMemberBanner = { isShown: false };
    },
  },
});
