// Find the shortest path
export function calculateNodeDepth(node, parentComponentHash, visitHash = {}) {
  const nodeId = node.nodeComponentId;
  const parentNode = parentComponentHash[nodeId];

  if (parentNode) {
    let depth = Number.MAX_SAFE_INTEGER;

    // skip it if we have visited it previously (it's a cyclic)
    if (typeof visitHash[parentNode.nodeComponentId] === "undefined") {
      const parentDepth = calculateNodeDepth(parentNode, parentComponentHash, { ...visitHash, [nodeId]: true }) + 1;
      if (parentDepth < depth) {
        depth = parentDepth;
      }
    }

    if (depth !== Number.MAX_SAFE_INTEGER) return depth;
  }

  return 0;
}

export function generateReactionObject({ reactionMetadata, emoji, toAdd, userId, username }) {
  // construct reactionsCount prop
  const reactionsCount = [];
  let isExistingReaction = false;
  reactionMetadata?.reactionsCount?.forEach(reactionData => {
    const currentCount = Number(reactionData.count);
    if (reactionData.emoji === emoji) {
      if (toAdd) {
        if (reactionMetadata?.currentUserSelectedEmojis?.includes(emoji)) {
          // if adding something that is already added, ignore
          reactionsCount.push({
            emoji: emoji,
            count: currentCount,
            userIds: reactionData.userIds,
          });
        } else {
          const newUserIds = reactionData.userIds ? [...reactionData.userIds] : [];
          newUserIds.unshift(userId);
          reactionsCount.push({
            emoji: emoji,
            count: currentCount + 1,
            userIds: newUserIds,
          });
        }
      } else {
        if (currentCount > 1) {
          reactionsCount.push({
            emoji: emoji,
            count: currentCount - 1,
            userIds: reactionData.userIds.filter(curUserId => curUserId !== userId),
          });
        }
      }
      isExistingReaction = true;
    } else {
      if (currentCount > 0) {
        reactionsCount.push({
          emoji: reactionData.emoji,
          count: currentCount,
          userIds: reactionData.userIds,
        });
      }
    }
  });
  if (toAdd && !isExistingReaction) {
    reactionsCount.unshift({
      emoji: emoji,
      count: 1,
      userIds: [userId],
    });
  }

  // construct currentUserSelectedEmojis prop
  const currentUserSelectedEmojis = reactionMetadata?.currentUserSelectedEmojis
    ? [...reactionMetadata.currentUserSelectedEmojis]
    : [];
  if (toAdd) {
    if (!currentUserSelectedEmojis.includes(emoji)) {
      currentUserSelectedEmojis.unshift(emoji);
    }
  } else {
    const index = currentUserSelectedEmojis.indexOf(emoji);
    if (index > -1) {
      currentUserSelectedEmojis.splice(index, 1);
    }
  }

  // construct usersMetadata prop
  const usersMetadata = reactionMetadata?.usersMetadata ? { ...reactionMetadata?.usersMetadata } : {};
  if (toAdd) {
    if (userId && username && usersMetadata[userId] === undefined) {
      usersMetadata[userId] = {
        userId: userId,
        username: username,
      };
    }
  }

  return {
    currentUserSelectedEmojis: currentUserSelectedEmojis,
    reactionsCount: reactionsCount,
    usersMetadata: usersMetadata,
  };
}

export function generateEndorseMetadata({ endorserMetadata, addEndorse, userId, username, userPhoto, isAnonymous }) {
  // construct endorsementMetadata prop
  const endorsementMetadata = {
    endorsedByCurrentUser: addEndorse,
    endorsers: endorserMetadata?.endorsers ?? [],
  };

  if (addEndorse) {
    // Add current user to endorsers
    endorsementMetadata.endorsers = [
      {
        id: isAnonymous ? 0 : userId,
        name: username,
        photo: userPhoto,
        isMe: true,
        isOptimisticUpdate: true,
      },
      ...endorsementMetadata.endorsers,
    ];
  } else {
    endorsementMetadata.endorsers = endorsementMetadata.endorsers.filter(endorser => endorser.isMe === false);
  }

  return endorsementMetadata;
}
