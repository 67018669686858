import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/userData/initialState";

export default createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUserData: (state, { payload: { isDebugMode, debugModeFetched } }) => {
      state.isDebugMode = isDebugMode || false;
      state.debugModeFetched = debugModeFetched || false;
    },
    setIsDebugMode: (state, { payload: { isDebugMode } }) => {
      state.isDebugMode = isDebugMode;
    },
  },
});
