import type { ConsensusTask } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";

interface VoteableTaskReviewState {
  dialog: {
    isOpen: boolean;
    spaceId: number;
    step: string;
    page: number;
    nextPageToken?: string;
  };
  detailTask: {
    taskIndex: number;
  };
  consensusTask?: {
    [spaceId: string | number]: { consensusList: ConsensusTask[]; isNoMoreData: boolean };
  };
}

const initialState: VoteableTaskReviewState = {
  dialog: {
    isOpen: false,
    spaceId: 0,
    step: "LIST",
    page: 0,
    nextPageToken: undefined,
  },
  detailTask: {
    taskIndex: 0,
  },
  consensusTask: {},
};

export default initialState;
