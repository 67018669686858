import type { Method } from "axios";
import getConfig from "next/config";

import * as Constant from "@/src/constants/AppConstant";
import {
  API_ENDPOINT_GET_USER_PROFILE_V2,
  API_ENDPOINT_PUBSUB_WEBSOCKET,
  APP_VERSION,
  PUBSUB_SUBSCRIPTION_STATUS,
} from "@/src/constants/AppConstant";
import axios from "@/src/core/AxiosFetcher";
import { generateHeaders, getDeviceContext } from "@/src/hooks/helpers/apiHelpers";
import type { PUBSUB_BROADCAST_CHANNEL_ID_PREFIX } from "@/src/hooks/pubsub/PubSubConstant";
import {
  PUBSUB_BROADCAST_CHANNEL_MESSAGE_RECEIVED_EVENT,
  PUBSUB_CONNECTION_AUTHORIZED_EVENT,
  PUBSUB_CONNECTION_CLOSED_EVENT,
  PUBSUB_CONNECTION_OPENED_EVENT,
  PUBSUB_CONNECTION_UNAUTHORIZED_EVENT,
  PUBSUB_WAIT_DURATION,
} from "@/src/hooks/pubsub/PubSubConstant";
import { getAPIBaseUrl } from "@/src/utils/APIUtil";
import type { ValueOf } from "@/src/utils/helpers/TypeUtil";
import { captureException, captureMessage } from "@/src/utils/logging/SentryLogging";

/*
 * Service to handle interaction with backend PubSub over Websocket. This service will manage the lifetime of
 * Websocket connection and can reconnect in case of dropped connection. On the other hand, the service will
 * require periodic update of access token from the main app to ensure authentication not expiring. Backend will
 * expect periodic auth message containing valid auth token and will close the connection if there is no message
 * or expired token is received.
 */

// Temporary enable it until BE remove the MaxAuthTimer (10min) config that force FE to
// re-send the access token even though the access token is not expired yet
const AUTO_RECONNECT_ON_AUTHORIZATION_FAILED = true;

let socket: WebSocket | null = null;
let accessToken: string | null = null;
let isConnectionAuthorized: boolean = false;

const updateMessageHandlers: Record<string, UpdateMessageHandler<unknown>> = {};
const channelSubscriptions: Record<string, string> = {};

type UpdateMessageHandler<T> = (data: T) => void;

interface UpdateMessage<T> {
  Topic: string;
  Data: T;
}

interface BroadcastChannelMessage<T> {
  channelId: string;
  messageType: string;
  data: T;
}

interface BroadcastChannelSubscriptionMessage {
  channelId: string;
  status: string;
}

interface SendMessageProps<T> {
  header: string;
  data: T;
  autoRetry?: boolean;
  retryMs?: number;
}

export function connect(newAccessToken: string) {
  accessToken = newAccessToken;

  if (accessToken) {
    if (socket === null || socket.readyState === WebSocket.CLOSING || socket.readyState === WebSocket.CLOSED) {
      initConnection();
    } else if (socket.readyState === WebSocket.OPEN) {
      sendAuth();
    }
  }
}

export function disconnect() {
  unsubscribeAllBroadcastChannels();
  accessToken = null;
  socket?.close();
}

function initConnection() {
  // Reset all current subscription state to UNKNOWN
  for (const channelId of Object.keys(channelSubscriptions)) {
    channelSubscriptions[channelId] = PUBSUB_SUBSCRIPTION_STATUS.UNKNOWN;
  }

  const { publicRuntimeConfig } = getConfig();
  const pubsubUrl = publicRuntimeConfig.APP_SERVER_PATH + API_ENDPOINT_PUBSUB_WEBSOCKET;
  const wsUrl = pubsubUrl.replace(/^http:\/\//i, "ws://").replace(/^https:\/\//i, "wss://");

  socket = new WebSocket(wsUrl);
  socket.addEventListener("message", onMessage);
  socket.addEventListener("open", onOpen);
  socket.addEventListener("close", onClose);
  socket.addEventListener("error", onError);
}

function onMessage(e: MessageEvent) {
  const data = e.data;
  if (data) {
    handleMessage(data);
  }
}

function onOpen() {
  // eslint-disable-next-line no-console
  console.log("[PubSub] Connection opened");

  const event = new CustomEvent(PUBSUB_CONNECTION_OPENED_EVENT);
  document.dispatchEvent(event);

  sendAuth();
}

function onClose() {
  // eslint-disable-next-line no-console
  console.log("[PubSub] Connection closed");

  const event = new CustomEvent(PUBSUB_CONNECTION_CLOSED_EVENT);
  document.dispatchEvent(event);

  // Try to re-connect as long as it's not disconnected due to authorization issue
  if (AUTO_RECONNECT_ON_AUTHORIZATION_FAILED || isConnectionAuthorized) {
    setTimeout(() => {
      initConnection();
    }, Constant.PUBSUB_WEBSOCKET_BACKOFF_TIMER_MSEC);
  }
}

function onError() {
  // Close connection
  socket?.close();
}

function parseMessage(message: string) {
  try {
    return JSON.parse(message);
  } catch (e) {
    captureException(e as Error, {
      contexts: {
        data: {
          message: message,
        },
      },
    });

    return null;
  }
}

function handleMessage(message: string) {
  const wsData = parseMessage(message);
  if (wsData) {
    const { H: header, D: data } = wsData;

    switch (header) {
      case Constant.PUBSUB_HEADER.AUTH:
        handleAuthMessage(data);
        break;
      case Constant.PUBSUB_HEADER.TOPIC_UPDATE:
        handleUpdateMessage(data);
        break;
      case Constant.PUBSUB_HEADER.SUBSCRIBE_CHANNEL:
        handleBroadcastChannelSubscription(data);
        break;
      case Constant.PUBSUB_HEADER.BROADCAST_MESSAGE:
        handleBroadcastChannelMessage(data);
        break;
      case Constant.PUBSUB_HEADER.UNSUBSCRIBE_CHANNEL:
        handleBroadcastChannelUnsubscription(data);
        break;
      default:
        captureMessage("[PubSub] Unknown header", {
          contexts: {
            data: {
              message,
            },
          },
        });
    }
  }
}

function handleAuthMessage(message: string) {
  switch (message) {
    case Constant.PUBSUB_AUTH_BODY.OK: {
      // Basically the websocket will still be alive until access token expired
      // eslint-disable-next-line no-console
      console.log("[PubSub] Authorization success");

      const event = new CustomEvent(PUBSUB_CONNECTION_AUTHORIZED_EVENT);
      document.dispatchEvent(event);

      isConnectionAuthorized = true;

      // This is to survive intermittent connection or inaccessible server.
      resubscribeInactiveBroadcastChannels();
      break;
    }
    case Constant.PUBSUB_AUTH_BODY.UNAUTHORIZED: {
      // Server un-authorize the connection, it will be closed by the server anyway
      // eslint-disable-next-line no-console
      console.log("[PubSub] Authorization failed");

      const event = new CustomEvent(PUBSUB_CONNECTION_UNAUTHORIZED_EVENT);
      document.dispatchEvent(event);

      isConnectionAuthorized = false;
      invalidateAccessToken();
      break;
    }
    default: {
      captureMessage("[PubSub] Unknown auth message", {
        contexts: {
          data: {
            message: JSON.stringify(message),
          },
        },
      });
    }
  }
}

function handleUpdateMessage<T>(message: UpdateMessage<T>) {
  const { Topic: topic, Data: data } = message;

  const handler = updateMessageHandlers[topic];

  if (handler) {
    handler(data);
  } else {
    // eslint-disable-next-line no-console
    captureMessage("[PubSub] Unknown update message", {
      contexts: {
        data: {
          message: JSON.stringify(message),
        },
      },
    });
  }
}

function handleBroadcastChannelSubscription(message: BroadcastChannelSubscriptionMessage) {
  // eslint-disable-next-line no-console
  console.log(`[PubSub] Broadcast channel subscription response: ${JSON.stringify(message)}`);
  const { channelId, status } = message;

  if (Object.prototype.hasOwnProperty.call(channelSubscriptions, channelId)) {
    channelSubscriptions[channelId] = status;
  }
}

function handleBroadcastChannelMessage<T>(message: BroadcastChannelMessage<T>) {
  const { channelId, messageType, data } = message;

  if (PUBSUB_SUBSCRIPTION_STATUS.isActive(channelSubscriptions[channelId])) {
    // Jack - 23 Dec 2021
    // BE push the message too fast while the submit API response have been received yet
    // Temporary add 1s delay for now. Later should move this logic to BE side.
    setTimeout(() => {
      const event = new CustomEvent(PUBSUB_BROADCAST_CHANNEL_MESSAGE_RECEIVED_EVENT, {
        detail: {
          channelId,
          messageType,
          data,
        },
      });
      document.dispatchEvent(event);
    }, PUBSUB_WAIT_DURATION);
  }
}

function handleBroadcastChannelUnsubscription(message: BroadcastChannelSubscriptionMessage) {
  // eslint-disable-next-line no-console
  console.log(`[PubSub] Broadcast channel unsubscription response: ${JSON.stringify(message)}`);
  const { channelId, status } = message;

  if (Object.prototype.hasOwnProperty.call(channelSubscriptions, channelId)) {
    channelSubscriptions[channelId] = status;
  }
}

export function registerMessageHandler<T>(topic: string, handler: UpdateMessageHandler<T>) {
  updateMessageHandlers[topic] = handler as UpdateMessageHandler<unknown>;
}

export function unregisterMessageHandler(topic: string) {
  delete updateMessageHandlers[topic];
}

function sendAuth() {
  sendMessage({
    header: Constant.PUBSUB_HEADER.AUTH,
    data: `Bearer ${accessToken}`,
  });
}

export function sendMessage<T>(props: SendMessageProps<T>) {
  const { header, data, autoRetry = false, retryMs = 1000 } = props;

  // In some cases, the app may try to subscribe to a channel before the socket is ready
  if (socket?.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify({ H: header, D: data }));
  } else if (autoRetry) {
    setTimeout(() => {
      sendMessage(props);
    }, retryMs);
  }
}

export type BroadcastChannelId = `${ValueOf<typeof PUBSUB_BROADCAST_CHANNEL_ID_PREFIX>}${string}`;

export function subscribeBroadcastChannel(channelId: BroadcastChannelId) {
  channelSubscriptions[channelId] = PUBSUB_SUBSCRIPTION_STATUS.UNKNOWN;

  // eslint-disable-next-line no-console
  console.log(`[PubSub] Subscribing to broadcast channel: ${channelId}`);
  sendMessage({
    header: Constant.PUBSUB_HEADER.SUBSCRIBE_CHANNEL,
    data: channelId,
  });
}

function resubscribeInactiveBroadcastChannels() {
  for (const [channelId, status] of Object.entries(channelSubscriptions)) {
    if (!PUBSUB_SUBSCRIPTION_STATUS.isActive(status)) {
      // eslint-disable-next-line no-console
      console.log(
        `[PubSub] Resubscribing to broadcast channel with inactive status: ${channelId}, current status: ${status}`,
      );
      sendMessage({
        header: Constant.PUBSUB_HEADER.SUBSCRIBE_CHANNEL,
        data: channelId,
      });
    }
  }
}

export function unsubscribeBroadcastChannel(channelId: BroadcastChannelId) {
  if (Object.prototype.hasOwnProperty.call(channelSubscriptions, channelId)) {
    delete channelSubscriptions[channelId];

    // eslint-disable-next-line no-console
    console.log(`[PubSub] Unsubscribing from broadcast channel: ${channelId}`);
    sendMessage({
      header: Constant.PUBSUB_HEADER.UNSUBSCRIBE_CHANNEL,
      data: channelId,
    });
  }
}

export function unsubscribeAllBroadcastChannels() {
  for (const channelId of Object.keys(channelSubscriptions)) {
    unsubscribeBroadcastChannel(channelId as BroadcastChannelId);
  }
}

export function sendBroadcastChannelMessage<T>(data: BroadcastChannelMessage<T>) {
  sendMessage({
    header: Constant.PUBSUB_HEADER.PUBLISH_BROADCAST,
    data,
  });
}

async function invalidateAccessToken() {
  if (accessToken) {
    // Some workaround to trigger refresh auth flow by using a network call
    // See auth interceptor logic: src/core/AxiosFetcher.ts
    await axios({
      url: API_ENDPOINT_GET_USER_PROFILE_V2,
      method: "POST" as Method,
      headers: generateHeaders(accessToken),
      data: {
        deviceContext: getDeviceContext(),
        frontendVersion: APP_VERSION,
      },
      baseURL: getAPIBaseUrl(),
    });
  }
}
