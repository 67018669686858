import { createSlice } from "@reduxjs/toolkit";

import TocHighlightInitialState, {
  TocHighlightTarget,
} from "@/src/domains/content/states/TocHighlight/TocHighlightInitialState";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";

interface SetParams {
  spaceId: number;
  sectionId: number | null;
  target?: TocHighlightTarget;
}

export const tocHighlightSlice = createSlice({
  name: "tocHighlight",
  initialState: TocHighlightInitialState,
  reducers: {
    set: (state, { payload }: ReducerPayload<SetParams>) => {
      const { spaceId, sectionId, target = TocHighlightTarget.DEFAULT } = payload;
      if (sectionId) {
        const spaceState = state[spaceId];
        if (spaceState) {
          spaceState.sectionId = sectionId;
          spaceState.target = target;
        } else {
          state[spaceId] = {
            sectionId,
            target,
          };
        }
      } else {
        delete state[spaceId];
      }
    },
  },
});
