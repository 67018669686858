// Jack - 23 Dec 2021
// BE push the message too fast while the submit API response have been received yet
// Temporary add 1s delay for now. Later should move this logic to BE side.
export const PUBSUB_WAIT_DURATION = 1000;

export const PUBSUB_CONNECTION_OPENED_EVENT = "pubsubConnectionOpened";
export const PUBSUB_CONNECTION_AUTHORIZED_EVENT = "pubsubConnectionAuthorized";
export const PUBSUB_CONNECTION_UNAUTHORIZED_EVENT = "pubsubConnectionUnauthorized";
export const PUBSUB_CONNECTION_CLOSED_EVENT = "pubsubConnectionClosed";

export const PUBSUB_BROADCAST_CHANNEL_MESSAGE_RECEIVED_EVENT = "pubsubBroadcastChannelMessageReceived";

export const PUBSUB_BROADCAST_CHANNEL_ID_PREFIX = {
  SPACE_PROFILE: "S.P.",
  SPACE_UPDATE: "S.U.",
  SPACE_INTERNAL: "S.I.",
  SPACE_EXTERNAL: "S.E.",
} as const;
