import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/newNodeDialog/initialState";

export default createSlice({
  name: "newNodeDialog",
  initialState,
  reducers: {
    openDialog: (state, { payload: dialogId }) => {
      state.dialogId = dialogId;
      state.isOpened = true;
    },
    closeDialog: state => {
      state.dialogId = null;
      state.isOpened = false;
    },
  },
});
