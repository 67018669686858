import * as Constant from "@/src/constants/AppConstant";
import type { ValueOf } from "@/src/utils/helpers/TypeUtil";

export const dynamicFormComponentType = {
  BLOCKQUOTE: "BLOCKQUOTE",
  BUTTON_GROUP: "BUTTON_GROUP",
  CHECKBOX: "CHECKBOX",
  CHECKBOX_GROUP: "CHECKBOX_GROUP",
  CHECKBOX_GROUP_WITH_DATA_ENDPOINT: "CHECKBOX_GROUP_WITH_DATA_ENDPOINT",
  DISCUSSION: "DISCUSSION",
  DROPDOWN: "DROPDOWN",
  DROPDOWN_WITH_DATA_ENDPOINT: "DROPDOWN_WITH_DATA_ENDPOINT",
  HELP: "HELP",
  HIDDEN_FIELD: "HIDDEN_FIELD",
  LABEL: "LABEL",
  NESTED_OPTION: "NESTED_OPTION",
  OPTION: "OPTION",
  OPTION_WITH_POST: "OPTION_WITH_POST",
  POLL_ANSWERS: "POLL_ANSWERS",
  SECTION: "SECTION",
  TEXT_AREA: "TEXT_AREA",
  TEXT_AREA_WITH_TAB_OPTIONS: "TEXT_AREA_WITH_TAB_OPTIONS",
  TEXT_FIELD: "TEXT_FIELD",
  TITLE: "TITLE",
  VERTICAL_BAR: "VERTICAL_BAR",
  VERTICAL_PADDING_LARGE: "VERTICAL_PADDING_LARGE",
  VERTICAL_PADDING_MEDIUM: "VERTICAL_PADDING_MEDIUM",
  VERTICAL_PADDING_SMALL: "VERTICAL_PADDING_SMALL",
  CONTENT_SEARCH: "CONTENT_SEARCH",
  SECTION_SEARCH: "SECTION_SEARCH",
  ENTITY_SEARCH_TO_MENTION: "ENTITY_SEARCH_TO_MENTION",
  SUBTITLE: "SUBTITLE",
  DATE_TIME_PICKER: "DATE_TIME_PICKER",
  INPUT_EXTRA_POST: "INPUT_EXTRA_POST",
  BODY_TEXT: "BODY_TEXT",
  DUAL_PANEL: "DUAL_PANEL",
  SECTION_TAG: "SECTION_TAG",
  CONTENT_TAG_POST: "CONTENT_TAG_POST",
  POST_TYPE_PICKER: "POST_TYPE_PICKER",
  // This is a special component type that is used to render a react component and the type only used on Frontend
  // Example usage is rendering post as guest information see `src/domains/content/components/Node/NodeFormRenderer/NodeInlineFormRenderer.tsx`
  FE_REACT_COMPONENT: "FE_REACT_COMPONENT",
} as const;

export type DynamicFormType = ValueOf<typeof dynamicFormComponentType>;

// list all dynamicForm component types that allow user typing
// autoFocus will be enabled on this component
export const dynamicFormEditableComponentTypes = [
  dynamicFormComponentType.TEXT_AREA,
  dynamicFormComponentType.TEXT_AREA_WITH_TAB_OPTIONS,
  dynamicFormComponentType.TEXT_FIELD,
  dynamicFormComponentType.SECTION_SEARCH,
  dynamicFormComponentType.CONTENT_SEARCH,
  dynamicFormComponentType.ENTITY_SEARCH_TO_MENTION,
  dynamicFormComponentType.CHECKBOX_GROUP,
  dynamicFormComponentType.CHECKBOX_GROUP_WITH_DATA_ENDPOINT,
  dynamicFormComponentType.DISCUSSION,
];

export const validationSpecType = {
  MIN_LENGTH: "MIN_LENGTH",
  MAX_LENGTH: "MAX_LENGTH",
  REQUIRED: "REQUIRED",
  POLL_VOTE_MIN_NUM_ANSWER_OPTIONS: "POLL_VOTE_MIN_NUM_ANSWER_OPTIONS",
  CHECKBOX_GROUP_MIN_SELECTED_OPTIONS: "CHECKBOX_GROUP_MIN_SELECTED_OPTIONS",
};

export const implicationType = {
  SET_OPTIONS: "SET_OPTIONS",
  SET_PLACEHOLDERTEXT: "SET_PLACEHOLDERTEXT",
  SET_SOURCE_TYPE: "SET_SOURCE_TYPE",
  SET_TEXT: "SET_TEXT",
  SET_VALUE: "SET_VALUE",
  SET_VISIBILITY: "SET_VISIBILITY",
  SET_THEME: "SET_THEME",
  SET_ICON: "SET_ICON",
};

export const MAX_REUSABLE_FORMS = 5;

export const DYNAMIC_CONDITIONAL_PROPS_EVENT = "DYNAMIC_CONDITIONAL_PROPS_EVENT";

export const NODE_CARD_CHILD_THEME_TO_COLOR_MAPPING = {
  [Constant.NODE_ACTION_THEME_FEEDBACK]: "purple",
};

export const dynamicFormComponentIds = {
  RESOLVABLE_OPTIONS_CHECKBOX_GROUP: "RESOLVABLE_OPTIONS_CHECKBOX_GROUP",
};

export const HIGHLIGHT_TYPE = {
  NEW: "NEW",
  CURRENT: "CURRENT",
  PRIMARY: "primary",
  SECONDARY: "secondary",
};
