import { createSelector } from "reselect";

import * as Constant from "@/src/constants/AppConstant";
import type { RootState } from "@/src/stores/rootReducer";

export const userSelector = ({ user }: RootState) => user.user;
export const userIdSelector = ({ user }: RootState) => user.user?.id ?? 0;
export const userNameSelector = ({ user }: RootState) => user.user?.name;
export const userAvatarSelector = ({ user }: RootState) => user.user?.avatar;
export const emailSelector = ({ user }: RootState) => user.user?.email;
export const bioSelector = ({ user }: RootState) => user.user?.description;
export const isSemblyAdminSelector = ({ user }: RootState) =>
  user.user?.privilegeType === Constant.USER_PRIVILEGE_TYPE_ADMIN;
export const isInternalSemblySelector = ({ user }: RootState) =>
  user.user?.privilegeType === Constant.USER_PRIVILEGE_TYPE_INTERNAL_SEMBLY;
export const userFollowingList = ({ user }: RootState) => user.user?.following;
export const userFollowersList = ({ user }: RootState) => user.user?.followers;
export const isLoggedInSelector = ({ user }: RootState) => !!user.accessToken;
export const accessTokenSelector = ({ user }: RootState) => user.accessToken;
export const refreshTokenSelector = ({ user }: RootState) => user.refreshToken;
export const userAccessDataSelector = ({ user }: RootState) => ({
  accessToken: user.accessToken,
  refreshToken: user.refreshToken,
});

export const tokenSelectors = createSelector(
  [accessTokenSelector, refreshTokenSelector],
  (accessToken, refreshToken) => ({ accessToken, refreshToken }),
);
