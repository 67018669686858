import { NODE_ACTION_TYPE } from "@/src/domains/types/NodeActionType";

const RESOLUTION_ACTIONS_WITH_CONFIRMATION: string[] = [
  NODE_ACTION_TYPE.MARK_UNADDRESSED,
  NODE_ACTION_TYPE.RETRACT,
  NODE_ACTION_TYPE.RETRACT_FEEDBACK,
  NODE_ACTION_TYPE.REINSTATE_FEEDBACK,
  NODE_ACTION_TYPE.REINSTATE,
  NODE_ACTION_TYPE.REMOVE_LABEL,
  NODE_ACTION_TYPE.CONCLUDE_BY_VOTING,
];

export const RESOLUTION_ACTIONS_WITH_DIALOG: string[] = [
  NODE_ACTION_TYPE.ACCEPT,
  NODE_ACTION_TYPE.REOPEN_FEEDBACK,
  NODE_ACTION_TYPE.REOPEN,
  NODE_ACTION_TYPE.REJECT,
  NODE_ACTION_TYPE.CLOSE,
  NODE_ACTION_TYPE.DISMISS,
];

const RESOLUTION_ACTIONS_WITH_DUAL_SELECTION: string[] = [
  NODE_ACTION_TYPE.RESOLVE_FEEDBACK,
  NODE_ACTION_TYPE.MARK_ADDRESSED,
];

export function isResolutionAction(id: string) {
  return (
    RESOLUTION_ACTIONS_WITH_CONFIRMATION.indexOf(id) > -1 ||
    RESOLUTION_ACTIONS_WITH_DIALOG.indexOf(id) > -1 ||
    RESOLUTION_ACTIONS_WITH_DUAL_SELECTION.indexOf(id) > -1
  );
}

export function isResolutionActionWithConfirmation(id: string) {
  return RESOLUTION_ACTIONS_WITH_CONFIRMATION.indexOf(id) > -1;
}

export function isResolutionActionWithDualSelection(id: string) {
  return RESOLUTION_ACTIONS_WITH_DUAL_SELECTION.indexOf(id) > -1;
}

export function isResolutionActionWithDialog(id: string) {
  return RESOLUTION_ACTIONS_WITH_DIALOG.indexOf(id) > -1;
}
