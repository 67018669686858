export const SIGNUP_DIALOG_SOURCE = {
  JOIN_SPACE: "JOIN_SPACE",
  SIGNUP_CTA: "SIGNUP_CTA",
} as const;

export type SignupDialogSource = (typeof SIGNUP_DIALOG_SOURCE)[keyof typeof SIGNUP_DIALOG_SOURCE];

type SignupDialogState = {
  open: boolean;
  isFromJoinAction: boolean;
  selectedSpaceUrl?: string;
  source?: SignupDialogSource;
  spaceId?: number;
};

const initialState: SignupDialogState = {
  open: false,
  isFromJoinAction: false,
  selectedSpaceUrl: undefined,
  source: undefined,
  spaceId: undefined,
};

export default initialState;
