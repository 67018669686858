import { createSlice } from "@reduxjs/toolkit";

import TocConfigInitialState from "@/src/domains/content/states/TocConfig/TocConfigInitialState";

export const tocConfigSlice = createSlice({
  name: "tocConfig",
  initialState: TocConfigInitialState,
  reducers: {
    dismissEditModeNotes: state => {
      state.editModeNotesDismissed = true;
    },
    reset: () => {
      return TocConfigInitialState;
    },
  },
});
