import type { UserNotificationModel } from "@/src/domains/liquidity/components/UserNotification/types/UserNotificationApiTypes";

interface UserNotificationState {
  userNotifications: UserNotificationModel[];
  currentVersion?: string;
  nextPageToken?: string;
  endReached: boolean;
}

const UserNotificationInitialState: UserNotificationState = {
  userNotifications: [],
  endReached: false,
};

export default UserNotificationInitialState;
