// convert array of object to object
export function keyBy<T>(arr: T[], objectKey: string) {
  return arr.reduce((prev: Record<string, T>, cur: T) => {
    // @ts-ignore
    prev[cur[objectKey]] = cur;
    return prev;
  }, {});
}

export function merge<T>(arr1: T[], arr2: T[]): T[] {
  const set = new Set([...arr1, ...arr2]);
  return Array.from(set);
}

export function groupBy<T1, T2 extends string | number | symbol>(
  arr: T1[],
  groupSelector: (item: T1) => T2,
): Record<T2, T1[]> {
  return arr.reduce(
    (result, item) => {
      const key = groupSelector(item);
      result[key] = result[key] ?? [];
      result[key].push(item);
      return result;
    },
    {} as Record<T2, T1[]>,
  );
}
