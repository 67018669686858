import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/stores/draft/initialState";

export default createSlice({
  name: "draft",
  initialState,
  reducers: {
    updateDraftData: (state, { payload: { draftId, draftActionId, nodeId } }) => {
      state.draftId = draftId;
      state.draftActionId = draftActionId;
      state.nodeId = nodeId;
    },
    addDrafts: (state, { payload: { drafts, page, pageSize } }) => {
      state.draftsList.drafts.push(...drafts);
      state.draftsList.page = page;
      state.draftsList.pageSize = pageSize;
    },
    updateDraftEntry: (state, { payload: { draftIndex, newDraftData } }) => {
      state.draftsList.drafts[draftIndex] = { ...state.drafts[draftIndex], ...newDraftData };
    },
    deleteDraftEntry: (state, { payload: draftIds }) => {
      state.draftsList.drafts = state.draftsList.drafts.filter((_, index) => !draftIds.includes(index));
    },
    clearDraftsList: state => {
      state.draftsList = initialState.draftsList;
    },
    clearDraft: state => {
      state.draftId = initialState.draftId;
      state.draftActionId = initialState.draftActionId;
      state.nodeId = initialState.nodeId;
    },
  },
});
