import { createSlice } from "@reduxjs/toolkit";
import mergeWith from "lodash.mergewith";

import { NODE_TYPE_GROUP } from "@/src/constants/ContentConstants";
import type {
  CleanNodeId,
  NodeChildrenInfo,
  NodeChildrenInfoGroup,
  NodeObject,
} from "@/src/domains/content/types/Node";

type StoredRelation = {
  spaceId: number;
  parentId: CleanNodeId;
  groupId: NodeChildrenInfoGroup;
  children?: Record<NodeChildrenInfoGroup, NodeChildrenInfo>;
  rootNodeId?: string;
};

type State = Record<CleanNodeId, StoredRelation>;

export interface SelectorState {
  discussionViewChildrenInfo: State;
}

export interface StoreRelationParams {
  spaceId: number;
  nodes: Record<string, NodeObject>;
  rootNodeId?: string;
}

export interface DeleteNodeFromRelationsParams {
  spaceId: number;
  nodeIds: CleanNodeId[];
}

export interface DeleteNodeIdRelationParams {
  nodeId: CleanNodeId;
}

export interface DeleteSingleDiscussionRelations {
  rootNodeId: string;
}

export interface DeleteNodeRelationInSpaceParams {
  spaceId: number;
}

const initialState = {} as State;

export const discussionViewChildrenInfo = createSlice({
  name: "discussionViewChildrenInfo",
  initialState,
  reducers: {
    storeNodeRelations: (state, { payload: { spaceId, nodes, rootNodeId } }: { payload: StoreRelationParams }) => {
      const nodesArray = Object.values(nodes);
      for (let i = 0; i < nodesArray.length; i++) {
        const node = nodesArray[i];
        const nodeId = node.nodeComponentId as CleanNodeId;
        const { parentId, groupId } = node;
        const existingRelation = state[nodeId];
        if (node.type === NODE_TYPE_GROUP) continue;

        /** Handle deleted nodeId */
        if (node.isDeleted && existingRelation) {
          const existingRelationParentId = existingRelation.parentId;
          const existingRelationParentGroup = existingRelation.groupId;
          const existingParent = state[existingRelationParentId]?.children?.[existingRelationParentGroup];
          if (existingParent?.ids) {
            const updatedIds = existingParent.ids.filter(childId => childId !== nodeId);
            state[existingRelationParentId].children![existingRelationParentGroup].ids = updatedIds;
            state[existingRelationParentId].children![existingRelationParentGroup].count = updatedIds.length;
          }
          continue;
        }

        /** Handle updating already cached relation */
        if (existingRelation) {
          const { parentId: existingParentId, groupId: existingGroupId } = existingRelation;
          if (node.childrenInfo) {
            // Store updated fetched children info, but not overwriting the ids if it's null
            // Used for updating the ranking score, count, and title
            const storedChildrenInfo: Partial<Record<NodeChildrenInfoGroup, NodeChildrenInfo>> = {};
            for (let x = 0; x < Object.keys(node.childrenInfo).length; x++) {
              const groupKey = Object.keys(node.childrenInfo)[x] as NodeChildrenInfoGroup;
              const childrenGroup = node.childrenInfo[groupKey];
              storedChildrenInfo[groupKey] = { ...childrenGroup };
              if (!childrenGroup.isPruned) {
                // skip updating nextPageToken if the data is marked as pruned
                // because it usually didn't return nextPageToken and will overwrite stored token if not skipped
                storedChildrenInfo[groupKey]!.nextPageToken = childrenGroup.nextPageToken || "";
              }

              if (childrenGroup.isPruned) {
                delete storedChildrenInfo[groupKey]?.isPruned;
                storedChildrenInfo[groupKey]!.ids = [];
              }
            }
            state[nodeId].children = mergeWith(
              state[nodeId].children,
              storedChildrenInfo,
              (objValue: unknown, srcValue: unknown) => {
                if (Array.isArray(objValue)) {
                  const mergedArray = objValue.concat(srcValue);
                  return [...new Set(mergedArray)];
                }
              },
            );
          }

          /** Handle moved node between parent or group */
          const isNodeMoved = parentId !== existingParentId || groupId !== existingGroupId;
          if (isNodeMoved) {
            // remove from existing parent
            const existingParentRelationGroup = state[existingParentId]?.children?.[existingGroupId];
            if (existingParentRelationGroup?.ids) {
              state[existingParentId].children![existingGroupId].ids = existingParentRelationGroup.ids.filter(
                childId => childId !== nodeId,
              );
              state[existingParentId].children![existingGroupId].count =
                state[existingParentId].children![existingGroupId].ids!.length;
            }
            // update parent info of the moved node
            state[nodeId].groupId = node.groupId;
            state[nodeId].parentId = node.parentId;
            // add to new parent if needed
            if (
              state[parentId]?.children?.[groupId]?.ids &&
              !state[parentId]?.children?.[groupId]?.ids!.includes(nodeId)
            ) {
              state[parentId].children![groupId].ids!.push(nodeId);
            }
          }
          continue;
        }

        /** Store new nodeId to an existing parent children list */
        const existingParentGroup = state[parentId]?.children?.[groupId];
        if (existingParentGroup) {
          const existingIds = existingParentGroup.ids || [];
          if (!existingIds.includes(nodeId)) {
            const updatedChildren = {
              ...state[parentId].children,
              [groupId]: {
                ...state[parentId].children![groupId],
                ids: [...new Set([nodeId, ...existingIds])],
              },
            };
            state[parentId].children = updatedChildren as Record<NodeChildrenInfoGroup, NodeChildrenInfo>;
          }
        }

        /** Store new nodes without existing stored relation */
        state[nodeId] = {
          spaceId,
          parentId,
          groupId,
          rootNodeId,
          children: node.childrenInfo || ({} as StoredRelation["children"]),
        };
      }
    },
    deleteNodeIdFromRelation: (state, { payload }: { payload: DeleteNodeIdRelationParams }) => {
      const { nodeId } = payload;
      const relationEntry = state[nodeId];

      if (relationEntry) {
        // update parent data
        const { parentId, groupId } = relationEntry;
        const parentEntry = state[parentId]?.children?.[groupId];
        if (parentEntry && parentEntry.ids) {
          const updatedChildren = {
            ...state[parentId]?.children,
            [groupId]: {
              ...parentEntry,
              ids: parentEntry.ids.filter(childId => childId !== nodeId),
              count: parentEntry.count - 1,
            },
          };
          state[parentId].children = updatedChildren as Record<NodeChildrenInfoGroup, NodeChildrenInfo>;
        }
      }
    },
    deleteSingleDiscussionRelations: (state, { payload }: { payload: DeleteSingleDiscussionRelations }) => {
      const { rootNodeId } = payload;
      const entries = Object.entries(state) as [CleanNodeId, StoredRelation][];
      for (const [nodeId, relation] of entries) {
        if (relation.rootNodeId === rootNodeId) {
          delete state[nodeId];
        }
      }
    },
    deleteNodeRelationsInSpace: (state, { payload }: { payload: DeleteNodeRelationInSpaceParams }) => {
      const { spaceId } = payload;
      const entries = Object.entries(state) as [CleanNodeId, StoredRelation][];
      for (const [nodeId, relation] of entries) {
        if (relation.spaceId === spaceId) {
          delete state[nodeId];
        }
      }
    },
  },
});
