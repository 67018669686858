import { UrlRegex } from "@/src/constants/Regex";
import type { ClientError } from "@/src/utils/general/CustomError";
import { captureException } from "@/src/utils/logging/SentryLogging";

// Truncate string based on words
export function truncateWords(str: string, maxWords: number, wordSeparator = " ") {
  return str.split(wordSeparator).splice(0, maxWords).join(wordSeparator);
}

// Get a slug that is safe to use for URI
export function generateSlug(text = "") {
  const result = text
    .toLowerCase()
    .replace(/(<([^>]+)>)/gi, " ")
    .replace(/[\s\W-]+/g, " ")
    .trim()
    .replace(/\s+/g, "-");

  return truncateWords(result, 5, "-");
}

export const getDomainWithoutSubdomain = (url: string) => {
  try {
    const urlParts = new URL(url).hostname.split(".");

    return urlParts
      .slice(0)
      .slice(-(urlParts.length === 4 ? 3 : 2))
      .join(".");
  } catch (e) {
    return url;
  }
};

export const isValidUrl = (urlString = "") => {
  return UrlRegex.test(urlString);
};

export function getPathWithoutDomain(url: string) {
  try {
    if (typeof url !== "string" || url === "") {
      return "";
    }
    const urlObj = new URL(url);
    return `${urlObj.pathname}${urlObj.search}`;
  } catch (e) {
    return "";
  }
}

/**
 * Utility function to modify a relative URL path to include a presentation
 * segment of '/all' if the URL matches the following patterns:
 * - /s/:spaceId?param1=value1&param2=value2
 * - /s/:spaceId
 *
 * @param path - The relative URL path to modify
 * @param presentation - The presentation to add to the URL (default: 'all')
 * @returns The modified URL path
 */
export function addPresentationToSpaceRoute(path: string, presentation = "all"): string {
  const match = path.match(/^\/s\/(?<spaceId>[^/?]+)(?<queryParams>\?.*)?$/);

  if (!match?.groups) {
    // If the path does not match the pattern, return the original path unchanged
    return path;
  }

  const { spaceId, queryParams } = match.groups;

  if (queryParams) {
    // If queryParams is defined, append the presentation segment and properly encode the query parameters
    const params = new URLSearchParams(queryParams.slice(1));
    return `/s/${spaceId}/${presentation}?${params.toString()}`;
  } else {
    // If queryParams is undefined, just append the presentation segment
    return `/s/${spaceId}/${presentation}`;
  }
}

export function getPathWithoutSearchParam(url: string) {
  try {
    if (typeof url !== "string" || url === "") {
      return "";
    }
    const urlObj = new URL(url);
    return `${urlObj.origin}${urlObj.pathname}`;
  } catch (e) {
    captureException(e as ClientError, {
      contexts: {
        data: { url },
      },
    });
    return "";
  }
}

export function isSemblyHost(url: string) {
  const urlObj = new URL(url);
  if (urlObj.host.endsWith("sembly.com")) {
    return true;
  } else if (urlObj.host.startsWith("localhost:")) {
    return true;
  }
  return false;
}

export function isSameSiteOrigin(url1: string, url2: string) {
  try {
    const url1Obj = new URL(url1);
    const url2Obj = new URL(url2);
    return url1Obj.origin === url2Obj.origin;
  } catch (e) {
    return false;
  }
}

export function getUrlPathName(url: string) {
  try {
    const urlObj = new URL(url);
    return urlObj.pathname;
  } catch (e) {
    return "";
  }
}

export function addProtocolToString(inputString: string): string {
  const protocolRegex = /^(http|https):\/\//; // Regex to check if the protocol is already present
  const trimmedInput = inputString.trim(); // remove white spaces from start & end side of string;

  if (protocolRegex.test(trimmedInput)) {
    // If the protocol is already present, return the original string
    return trimmedInput;
  } else {
    // Always append protocol "http" to any input string
    const protocol = "http";
    const encodedInput = encodeURI(trimmedInput);
    return `${protocol}://${encodedInput}`;
  }
}

export function safeDecodeURI(encodeURI: string) {
  try {
    return decodeURI(encodeURI);
  } catch (e) {
    return "";
  }
}
