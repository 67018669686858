import type { Draft } from "@reduxjs/toolkit";

import { ADMIN_TASKS } from "@/src/domains/liquidity/constants/ActivityLogHistory";
import type {
  SpaceReviewTasksState,
  SpaceTask,
  SpaceTasks,
  SpaceViewIndexes,
} from "@/src/domains/liquidity/states/SpaceReviewTasks/SpaceReviewTasksSlice";
import type { ConsensusTask, TaskType } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import { TASK_TYPE } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import { getOrInitRecord } from "@/src/utils/general/ObjectUtil";

export function toSpaceTasks(tasks: ConsensusTask[]): SpaceTasks {
  const tasksBySpaceId: SpaceTasks = {};
  tasks.forEach(task => {
    const taskType: TaskType = ADMIN_TASKS.has(task.actionType) ? "ADMINISTRATIVE" : "NORMAL";

    if (tasksBySpaceId?.[task.spaceCanonicalId] == null) {
      tasksBySpaceId[task.spaceCanonicalId] = Object.values(TASK_TYPE).reduce((result, type) => {
        if (type !== taskType) result[type] = [];
        else result[type] = [task];
        return result;
      }, {} as SpaceTask);
      return;
    }

    const spaceTasks = tasksBySpaceId?.[task.spaceCanonicalId]?.[taskType];
    if (spaceTasks == null) {
      tasksBySpaceId[task.spaceCanonicalId][taskType] = [task];
      return;
    }
    spaceTasks.push(task);
  });
  return tasksBySpaceId;
}

export function calculateViewIndexes(state: Draft<SpaceReviewTasksState>, newTasks: SpaceTasks): SpaceViewIndexes {
  // calculate the correct view index
  const viewIndexes: SpaceViewIndexes = {};

  Object.keys(newTasks).forEach(spaceCanonicalId => {
    // initialize if it hasn't
    getOrInitRecord(viewIndexes, spaceCanonicalId, { [TASK_TYPE.NORMAL]: 0, [TASK_TYPE.ADMINISTRATIVE]: 0 });

    Object.values(TASK_TYPE).forEach(taskType => {
      const oldViewIndex = state.viewIndexes?.[spaceCanonicalId]?.[taskType];
      const oldTasks = state.tasks?.[spaceCanonicalId]?.[taskType];

      if (oldViewIndex == null) return;

      if (oldTasks?.[oldViewIndex]?.id === newTasks?.[spaceCanonicalId]?.[taskType]?.[oldViewIndex]?.id) {
        viewIndexes[spaceCanonicalId][taskType] = oldViewIndex;
      }
    });
  });

  return viewIndexes;
}
