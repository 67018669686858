import { createSlice } from "@reduxjs/toolkit";

import {
  VOTEABLE_CONSENSUS_DETAIL_TASK_DIALOG,
  VOTEABLE_CONSENSUS_LIST_TASK_DIALOG,
} from "@/src/constants/AppConstant";
import type { ConsensusTask } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";
import initialState from "@/src/stores/votableConsensusTask/initialState";

interface PayloadStoreTask {
  consensusList: ConsensusTask[];
  spaceId: number;
  isNoMoreData: boolean;
  nextPageToken?: string;
  isFirstFetch?: boolean;
}

interface PayloadSwipeTask {
  direction: string;
}

interface PayloadClearReviewTask {
  taskId: number;
  spaceId: number;
}

const votableConsensusTaskSlice = createSlice({
  name: "votableConsensusTask",
  initialState,
  reducers: {
    openDialog: (state, { payload: { spaceId } }) => {
      state.dialog.isOpen = true;
      state.dialog.spaceId = spaceId;
      state.dialog.nextPageToken = undefined;
      state.dialog.step = VOTEABLE_CONSENSUS_LIST_TASK_DIALOG;
      state.detailTask.taskIndex = 0;
    },
    closeDialog: state => {
      state.dialog.isOpen = false;
      state.dialog.spaceId = 0;
    },
    storeTask: (
      state,
      {
        payload: { spaceId, consensusList, nextPageToken, isFirstFetch, isNoMoreData },
      }: ReducerPayload<PayloadStoreTask>,
    ) => {
      if (nextPageToken) {
        state.dialog.nextPageToken = nextPageToken;
      } else {
        state.dialog.nextPageToken = undefined;
      }
      if (isFirstFetch) {
        // @ts-ignore
        state.consensusTask = {
          [spaceId]: { consensusList: [...consensusList], isNoMoreData },
        };
      } else {
        const currentReviewTask = state?.consensusTask?.[spaceId]?.consensusList;
        if (currentReviewTask) {
          // @ts-ignore
          const updatedReviewTask = currentReviewTask?.concat(consensusList);
          state.consensusTask = {
            [spaceId]: {
              consensusList: [...updatedReviewTask],
              isNoMoreData,
            },
          };
        }
      }
    },
    backToOverview: state => {
      state.dialog.step = VOTEABLE_CONSENSUS_LIST_TASK_DIALOG;
      state.detailTask.taskIndex = 0;
    },
    goToListDetailTask: (state, { payload: { taskIndex } }: ReducerPayload<{ taskIndex: number }>) => {
      state.dialog.step = VOTEABLE_CONSENSUS_DETAIL_TASK_DIALOG;
      state.detailTask.taskIndex = taskIndex;
    },
    handleSwipeTask: (state, { payload: { direction } }: ReducerPayload<PayloadSwipeTask>) => {
      if (direction === "RIGHT") {
        state.detailTask.taskIndex = state.detailTask.taskIndex + 1;
      } else {
        state.detailTask.taskIndex = state.detailTask.taskIndex - 1;
      }
    },
    setDetailTaskIndex: (state, { payload: { taskIndex } }: ReducerPayload<{ taskIndex: number }>) => {
      state.detailTask.taskIndex = taskIndex;
    },
    clearReviewTask: (state, { payload: { taskId, spaceId } }: ReducerPayload<PayloadClearReviewTask>) => {
      if (state?.consensusTask?.[spaceId]) {
        const indexToRemove = state.consensusTask[spaceId]?.consensusList?.findIndex(task => task.id === taskId);
        state.consensusTask = {
          [spaceId]: {
            ...state.consensusTask[spaceId],
            consensusList: [...state.consensusTask[spaceId]?.consensusList].filter(task => task.id !== taskId),
          },
        };
        state.detailTask.taskIndex =
          indexToRemove < state.detailTask.taskIndex ? state.detailTask.taskIndex - 1 : state.detailTask.taskIndex;
      }
    },
  },
});

export default votableConsensusTaskSlice;
