import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { COOKIES_ACCESS_TOKEN, COOKIES_REFRESH_TOKEN } from "@/src/constants/CookieConstants";
import type { User } from "@/src/hooks/types";
import { userFactory } from "@/src/models/user/User";
import type { ReducerPayload } from "@/src/stores/types/ReducerPayload";
import initialState from "@/src/stores/user/initialState";

export default createSlice({
  name: "users",
  initialState,
  reducers: {
    // TODO: Merge loginSuccess — setToken
    loginSuccess: (state, { payload: { accessToken, refreshToken, user } }) => {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    setToken: (state, { payload: { accessToken, refreshToken, user } }) => {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    logout: () => {
      return initialState;
    },
    updateShowHiddenContentLabels: (state, { payload }: ReducerPayload<{ showHiddenContentLabels: string[] }>) => {
      state.user = userFactory.fromObject({
        ...(state.user as User),
        showHiddenContentLabels: payload.showHiddenContentLabels,
      }) as User;
    },
    updateProfile: (state, { payload }) => {
      state.user = userFactory.fromObject({ ...state.user, ...payload }) as User;
    },
    refreshToken: (state, { payload: { accessToken, refreshToken } }) => {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      setUserCookies(accessToken, refreshToken);
    },
  },
});

export function setUserCookies(accessToken: string, refreshToken: string) {
  // in days
  const expired = { expires: 365 };
  if (typeof document !== "undefined") {
    Cookies.set(COOKIES_ACCESS_TOKEN, accessToken, expired);
    Cookies.set(COOKIES_REFRESH_TOKEN, refreshToken, expired);
  }
}

export function removeUserCookies() {
  if (typeof document !== "undefined") {
    Cookies.remove(COOKIES_ACCESS_TOKEN);
    Cookies.remove(COOKIES_REFRESH_TOKEN);
  }
}
