import Cookies from "js-cookie";

import { APP_VERSION } from "@/src/constants/AppConstant";
import sliceToPersist from "@/src/stores/persist/registerPersist";
import type { RootState } from "@/src/stores/rootReducer";
import noop from "@/src/utils/helpers/noop";

const COOKIE_LOG_KEY = "log-persist";

/**
 * Create persist logger to log persist state
 * @returns {Pick<Console, "log" | "warn" | "error">} console
 */
export const createPersistLogger = (): Pick<Console, "log" | "warn" | "error"> => {
  const isDebugMode = Boolean(Cookies.get(COOKIE_LOG_KEY));

  if (!isDebugMode) {
    return {
      log: noop,
      warn: noop,
      error: noop,
    };
  }

  return console;
};

/**
 * Generate hash from object
 * @param obj object to generate hash
 * @returns {Promise<string>} hash
 */
export const generateHash = async (obj: object): Promise<string> => {
  const str = JSON.stringify(obj);
  const msgUint8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  return hashHex;
};

/**
 * Get IndexedDB state key
 * @returns {string} IndexedDB state key
 */
export const getIndexedDBStateKey = (): string => {
  const appVersion = APP_VERSION;
  return `sembly-${appVersion}`;
};

/**
 * To convert readonly type to mutable type
 */
export type Mutable<T extends { [x: string]: unknown }, K extends string> = {
  [P in K]: T[P];
};

const keyToPersist = Object.keys(sliceToPersist);

/**
 * Check if an action is part of the slice that registered in registerPersist.ts
 * @param actionType action.type
 * @returns {boolean} true if action is part of the slice that registered in registerPersist.ts
 */
export const isActionTypeToPersist = (actionType: string): boolean => {
  // Action.type can be spaceNodeActions:spaceNodeActions/sectionStates:sectionStates
  // We need to check if the action.type includes any of the keyToPersist
  const isActionTypeToPersist = keyToPersist.some(key => actionType.includes(key));
  return isActionTypeToPersist;
};

/**
 * Map state to persisted state
 * @param {Partial<RootState>} state store.getState() object
 * @param {string[]} whitelists list of keys to persist
 * @returns {Partial<RootState>} state to persist
 */
export const mapStateToPersistedState = (state: Partial<RootState>, whitelists: string[]): Partial<RootState> => {
  const stateToPersist = whitelists.reduce<Mutable<Partial<RootState>, string>>((acc, key) => {
    acc[key] = (state as { [key: string]: unknown })[key];
    return acc;
  }, {}) as Partial<RootState>;

  return stateToPersist;
};

/**
 * Timeout a promise after a given time for Promise.race usage.
 * @param ms {number} milliseconds
 * @returns {Promise<[null, boolean]>} [null, true] if timeout, [null, false] if not timeout
 */
export const timeout = (ms: number) =>
  new Promise<[null, boolean]>(resolve => setTimeout(() => resolve([null, true]), ms));
