import { createSlice } from "@reduxjs/toolkit";

import type { ConsensusTask } from "@/src/pages/TasksPage/TaskList/types/ConsensusTask";

export type State = {
  tasks: {
    [taskId in number]: ConsensusTask;
  };
};

const initialState: State = {
  tasks: {},
};

export const pendingTaskSlice = createSlice({
  name: "pendingTask",
  initialState: initialState,
  reducers: {
    storeTasks: (state, { payload }: { payload: { tasks: ConsensusTask[] } }) => {
      payload.tasks.forEach(task => {
        state.tasks[task.id] = task;
      });
    },
    storeTask: (state, { payload }: { payload: { task: ConsensusTask } }) => {
      state.tasks[payload.task.id] = payload.task;
    },
    deleteTask: (state, { payload }: { payload: { taskId: number } }) => {
      delete state.tasks[payload.taskId];
    },
    reset: () => {
      return initialState;
    },
  },
});
