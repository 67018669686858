export const SPACE_ACTION_STATUS = {
  NEED_REVISION: "NEED_REVISION",
  REJECTED: "REJECTED",
  NEW: "NEW",
  NEED_REVIEW: "NEED_REVIEW",
  APPROVED: "APPROVED",
  REDIRECTED_TO_EXISTING_SPACE: "REDIRECTED_TO_EXISTING_SPACE",
  REDIRECTED_TO_NEW_SPACE: "REDIRECTED_TO_NEW_SPACE",
  REVISED: "REVISED",
};

export const SPACE_ACTION_TYPE = {
  SPACE_PROPOSAL: "proposal",
  REQUEST_TO_JOIN: "join-request",
};
