import type { RootState } from "@/src/stores/rootReducer";

export interface LatestUpdatedAt {
  numOfActiveAction: number; // TODO: Temporary
  numOfActiveTask: number; // TODO: Temporary
  latestUserNotificationUpdatedAt: number;
}

export function numOfActiveActionSelector({ notifications }: RootState) {
  return notifications.numOfActiveAction;
}

export function numOfActiveUserNotificationSelector({ notifications }: RootState) {
  return notifications.numOfActiveUserNotification;
}

export function numOfActiveTaskSelector({ notifications }: RootState) {
  return notifications.numOfActiveTask;
}

export function latestUpdatedAtSelector({ notifications }: RootState) {
  return {
    numOfActiveAction: notifications.numOfActiveAction,
    numOfActiveTask: notifications.numOfActiveTask,
    latestUserNotificationUpdatedAt: notifications.latestUserNotificationUpdatedAt,
  };
}
