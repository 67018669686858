import { GetCompleteEmailWithNameRegex, GetEmailAddressRegex, GetEmailWithBracketRegex } from "@/src/constants/Regex";
import { captureException } from "@/src/utils/logging/SentryLogging";

export function insert(str: string, index: number, value: string) {
  return str.substr(0, index) + value + str.substr(index);
}

export function htmlStripper(htmlText: string = "", replaceWithWhiteSpace: boolean = false) {
  const regex = /(<([^>]+)>)/gi;
  return htmlText.replace(regex, replaceWithWhiteSpace ? " " : "").trim();
}

export function htmlDecode(input: string = "") {
  let text = input;
  try {
    const doc = new DOMParser().parseFromString(input, "text/html");
    text = doc.documentElement.textContent || "";
  } catch (e) {
    captureException(e as Error);
  }
  return text;
}

export function stringToHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash;
  }
  return hash;
}

export const parseEmailListFromText = (inputStr: string) => {
  const emailsWithNames = inputStr.match(GetCompleteEmailWithNameRegex);
  const emailsOnly = inputStr.match(GetEmailAddressRegex);

  if (emailsWithNames || emailsOnly) {
    const extractedEmailsWithNames = emailsWithNames
      ? emailsWithNames.map(email => email.match(GetEmailWithBracketRegex)?.[1])
      : [];
    const extractedStandaloneEmails = emailsOnly || [];
    const uniqueEmails = [...new Set([...extractedEmailsWithNames, ...extractedStandaloneEmails])]; // remove duplicate email

    const removedEmailsFromText = inputStr.replace(GetCompleteEmailWithNameRegex, ""); // remove email addresses with names from the text
    const remainingText = removedEmailsFromText.replace(GetEmailAddressRegex, "").replace(/,/g, ""); // remove email only addresses and commas from the text

    return {
      parsedEmails: uniqueEmails,
      text: remainingText.trim(), // remaining text
    };
  } else {
    return { parsedEmails: undefined, text: inputStr.trim() }; // return complete input text if pasted text doesn't include any email
  }
};

// Captures two types of <mark> elements: those with the class removed and those with the class added. It captures the content inside these elements while ignoring the tags themselves.
const markRegex = /<mark class=['"]removed['"]>(.*?)<\/mark>|<mark class=['"]added['"]>(.*?)<\/mark>/g;

export const removeMarkTag = (text: string) => {
  return text.replace(markRegex, (_, removedText, addedText) => {
    if (removedText) {
      return ""; // Remove text between <mark class="removed"> and </mark>
    } else if (addedText) {
      return addedText; // Keep text between <mark class="added"> and </mark>
    }
  });
};
