import { createSlice } from "@reduxjs/toolkit";

import initialState from "@/src/domains/user/components/Invite/state/initialState";

export default createSlice({
  name: "invite",
  initialState,
  reducers: {
    setEmailPrefill(state, { payload: email }) {
      state.emailPrefill = email;
    },
    setInvitationToken(state, { payload }) {
      state.invitationToken = payload || initialState.invitationToken;
    },
    setConfirmationToken: (state, { payload: confirmationToken }) => {
      state.confirmationToken = confirmationToken;
    },
    clearInvitationToken: state => {
      state.invitationToken = initialState.invitationToken;
    },
    clearConfirmationToken: state => {
      state.confirmationToken = null;
    },
  },
});
