import type { NodeObject } from "@/src/domains/content/types/Node";

type UpdateType = "PREPEND_NODE" | "APPEND_NODE" | "OTHER";
type UpdatePosition = "ABOVE_VIEW" | "CURRENT_VIEW" | "BELOW_VIEW";

export interface ListState {
  sectionId: string;
  nodeIds: string[];
}

export interface ScrollState {
  sectionId: string;
  top: number;
  height: number;
}

/**
 * Transform nodeId with versioning to clean numeric nodeId
 * e.g.: "100056-1" to 100056
 */
export function getCleanNodeId(nodeIdWithVersioning: string): number {
  // there still exists legacy nodeId of "0" for uncategorized section
  const isNumber = !isNaN(nodeIdWithVersioning as unknown as number);
  if (isNumber) {
    return Number(nodeIdWithVersioning);
  }

  // these are the supposedly the only neccesssary codes:
  const splittedNodeId = (nodeIdWithVersioning || "").split("-");

  return Number(splittedNodeId[0]);
}

/**
 * Get revision id of specific node
 * e.g.: "100056-3" -> 3
 */
export function getRevisionId(nodeIdWithVersioning: string): number {
  const splittedNodeId = nodeIdWithVersioning.split("-");
  if (splittedNodeId.length > 1) {
    return Number(splittedNodeId[1]);
  }

  return 1;
}

/**
 * Append nodeId with revision number if doesn't have one yet
 * e.g.: "100056" -> "100056-1"
 */
export function appendNodeIdWithRevision(nodeIdWithoutRevisionId: string): string {
  // if already has revision, no need to do anything
  if (nodeIdWithoutRevisionId.toString().indexOf("-") > -1) {
    return nodeIdWithoutRevisionId.toString();
  }
  return `${nodeIdWithoutRevisionId}-1`;
}

export function findNode(nodeId: string, nodes: Record<string, NodeObject>): NodeObject | undefined {
  const node = nodes[nodeId];
  if (node) {
    return node;
  }

  // There is a case that revisionId is not matched, or BE return a nodeId without versioning
  const cleanNodeId = getCleanNodeId(nodeId);

  const found = Object.keys(nodes).find(nodeId => getCleanNodeId(nodeId) === cleanNodeId);
  if (found) {
    return nodes[found];
  }

  return undefined;
}

function isArrayEqual(arr1: string[], arr2: string[]): boolean {
  // Check if it has the same length
  if (arr1.length !== arr2.length) {
    return false;
  } else {
    // Comparing each element of array
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }
}

export function checkUpdateType(lastListState: ListState[], currentListState: ListState[]): UpdateType {
  const length = Math.min(lastListState.length, currentListState.length);
  if (length > 0) {
    for (let i = 0; i < length; i++) {
      const { sectionId: lastSectionId, nodeIds: lastNodeIds } = lastListState[i];
      const { sectionId: currentSectionId, nodeIds: currentNodeIds } = currentListState[i];

      // The section id is different, then it means a new section is added
      if (lastSectionId !== currentSectionId) break;

      // Find section with the updated nodes
      if (!isArrayEqual(lastNodeIds, currentNodeIds)) {
        const nodeLength = Math.min(lastNodeIds.length, currentNodeIds.length);

        // If the first node is different, then we can assume it's a prepend action
        if (nodeLength > 0 && lastNodeIds[0] !== currentNodeIds[0]) {
          return "PREPEND_NODE";
        } else {
          return "APPEND_NODE";
        }
      }
    }
  }

  return "OTHER";
}

export function getCurrentScrollState(offsetTop: number): ScrollState[] {
  const arr: ScrollState[] = [];

  const renderedSections = document.querySelectorAll("div[data-section-id]") ?? [];
  for (const renderedSection of renderedSections) {
    const section = renderedSection as HTMLDivElement;
    const rect = section.getBoundingClientRect();
    const sectionId = section.dataset.sectionId;

    if (sectionId) {
      arr.push({
        sectionId,
        top: rect.top - offsetTop,
        height: rect.height,
      });
    }
  }

  return arr;
}

export function getUpdatePosition(
  lastScrollState: ScrollState[],
  currentScrollState: ScrollState[],
  updateType: UpdateType,
  containerHeight: number,
): UpdatePosition {
  const length = Math.min(lastScrollState.length, currentScrollState.length);

  if (length > 0) {
    for (let i = 0; i < length; i++) {
      const { sectionId: lastId, top: lastTop, height: lastHeight } = lastScrollState[i];
      const { sectionId: currentId, top: currentTop, height: currentHeight } = currentScrollState[i];

      if (lastId === currentId) {
        if (lastTop !== currentTop || lastHeight !== currentHeight) {
          if (updateType === "PREPEND_NODE") {
            if (Number(lastTop) >= containerHeight) {
              return "BELOW_VIEW";
            } else if (Number(lastTop) >= 0) {
              return "CURRENT_VIEW";
            } else {
              return "ABOVE_VIEW";
            }
          } else {
            if (Number(lastTop) + Number(lastHeight) < 0) {
              return "ABOVE_VIEW";
            } else if (Number(lastTop) + Number(lastHeight) < containerHeight) {
              return "CURRENT_VIEW";
            } else {
              return "BELOW_VIEW";
            }
          }
        }
      } else {
        // if the first visible section Id is different,
        // then we can safely assume the new item is added before the visible node
        return "ABOVE_VIEW";
      }
    }
  }

  // We can assume that the page has no section
  return "CURRENT_VIEW";
}

export function shouldUpdateScrollState(lastScrollState: ScrollState[], currentScrollState: ScrollState[]): boolean {
  // Ideally only the "top" field that will be changed while user perform scrolling
  // If the "height" field is also changed, then we can assume the scroll action is triggered from code
  // Don't update the scroll state if that's the case

  const lookup: { [key: string]: number } = {};

  for (let i = 0; i < lastScrollState.length; i++) {
    const { sectionId: lastId, height: lastHeight } = lastScrollState[i];
    lookup[lastId] = lastHeight;
  }

  for (let i = 0; i < currentScrollState.length; i++) {
    const { sectionId: currentId, height: currentHeight } = currentScrollState[i];
    if (lookup[currentId] && lookup[currentId] !== currentHeight) {
      return false;
    }
  }

  return true;
}

export function shouldAdjustScrollTop(
  lastScrollState: ScrollState[],
  currentScrollState: ScrollState[],
  updateType: UpdateType,
): boolean {
  const length = Math.min(lastScrollState.length, currentScrollState.length);

  if (length > 0) {
    for (let i = 0; i < length; i++) {
      const { sectionId: lastId, top: lastTop, height: lastHeight } = lastScrollState[i];
      const { sectionId: currentId, top: currentTop, height: currentHeight } = currentScrollState[i];

      if (lastId === currentId) {
        if (lastTop >= 0) {
          // new item is added after the visible node
          return false;
        }

        if (lastTop + lastHeight === currentTop + currentHeight) {
          // sometimes it can automatically adjust it's position, not sure why
          // skip it, assume it's not changed for now, need to revisit it later
          continue;
        }

        if (lastTop !== currentTop) {
          // new item is added to previous section
          return true;
        }

        if (lastHeight !== currentHeight) {
          // new item is added to this section
          return updateType === "PREPEND_NODE";
        }
      } else {
        // if the first visible section Id is different,
        // then we can safely assume the new item is added before or after the visible node
        return updateType === "PREPEND_NODE";
      }
    }
  }

  return false;
}

export function findUpdatedSectionId(lastListState: ListState[], currentListState: ListState[]): string | undefined {
  const length = Math.min(lastListState.length, currentListState.length);
  if (length > 0) {
    for (let i = 0; i < length; i++) {
      const { sectionId: lastSectionId, nodeIds: lastNodeIds } = lastListState[i];
      const { sectionId: currentSectionId, nodeIds: currentNodeIds } = currentListState[i];

      // Find section with the updated nodes
      if (lastSectionId !== currentSectionId || !isArrayEqual(lastNodeIds, currentNodeIds)) {
        return currentSectionId;
      }
    }
  }

  return undefined;
}

export function shouldStoreUpdatedSectionId(
  listState: ListState[],
  lastUpdatedSectionId: string | undefined,
  currentUpdatedSectionId: string | undefined,
): boolean {
  for (let i = 0; i < listState.length; i++) {
    const { sectionId } = listState[i];

    if (lastUpdatedSectionId === sectionId) return false;
    if (currentUpdatedSectionId === sectionId) return true;
  }

  return false;
}

export function compareUpdatedSectionId(
  listState: ListState[],
  firstSectionId: string | undefined,
  secondSectionId: string | undefined,
): string | undefined {
  for (let i = 0; i < listState.length; i++) {
    const { sectionId } = listState[i];

    if (firstSectionId && firstSectionId === sectionId) return firstSectionId;
    if (secondSectionId && secondSectionId === sectionId) return secondSectionId;
  }

  return undefined;
}

export function isUpdateViewed(
  scrollState: ScrollState[],
  containerHeight: number,
  sectionId: string,
  offset: number,
): boolean {
  for (let i = 0; i < scrollState.length; i++) {
    const { sectionId: id, top } = scrollState[i];
    if (id === sectionId && top + offset >= 0 && top + offset <= containerHeight) {
      return true;
    }
  }

  return false;
}

export function isOutsideView(scrollState: ScrollState[], sectionId: string): boolean {
  for (let i = 0; i < scrollState.length; i++) {
    const { sectionId: id, top, height } = scrollState[i];
    if (id === sectionId) {
      // still don't have a proper way to detect this
      // but somehow if the view is not rendered yet, the top and the height will have the same value
      return top === height;
    }
  }

  return true;
}
